import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  IconButton,
  styled,
  FormControl,
  FormLabel,
  TextField
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SecurityIcon from "@mui/icons-material/Security";
import CallIcon from "@mui/icons-material/Call";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "25.56px",
  gap: '20px',
  '@media (max-width: 680px)': {
    width: '80%',
    padding: 2,
  },
};

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { accountholderpng, carddetails, coin, expiryicon, imgVisbility, imgVisbilityOff, plusicon, visa, warning } from "./assets";




export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  homeAddress = () => {
    return (
      <FormContainer>
        <FormControl fullWidth>
          <MyFormLabel>{configJSON.address}</MyFormLabel>
          <Input fullWidth variant="outlined"
            placeholder="Address"
            value={this.state.userdetails?.rider_detail?.home_address || this.state.userdetails?.rider_detail?.home_address}
            type="text"
            InputProps={{
              readOnly: true,
            }}
          />

        </FormControl>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.contactname}</MyFormLabel>
          <Input fullWidth variant="outlined"
            placeholder="Name"
            value={`${this.state.userdetails?.first_name} ${this.state.userdetails?.last_name}`}
            type="text"
            InputProps={{
              readOnly: true,
            }}
          />

        </FormControl>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.Phonenumber}</MyFormLabel>
          <Input fullWidth variant="outlined"
            placeholder="Phone number"
            value={this.state.userdetails?.rider_detail?.mobile_number || this.state.userdetails?.rider_detail?.mobile_number}
            type="Text"
            InputProps={{
              readOnly: true,
            }}
          />

        </FormControl>
        <Cardbackbutton >
          <Nexteditbutton onClick={() => this.setState({ pagetoshow: "" })} data-test-id='backfromhomeaddressid'>
            <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
          </Nexteditbutton>
        </Cardbackbutton>
      </FormContainer>
    )
  }

  settingMainpage = () => {
    return (
      <ListRight>
        {this.state.usertype == "driver" ? <>
          <ListItemTextRight onClick={this.props.redirectedit} data-test-id="redirectsidemenuid">
            <ListItemIconRightText>
              <CommonIcon as={EditIcon} />
            </ListItemIconRightText>
            <Typography style={webstyle.settingsOptionstext}>{configJSON.personalinfo}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>

          <ListItemTextRight onClick={() => this.setState({ pagetoshow: "changepassword",prevpass:"",confirmPassword:"",password:"" })} data-test-id="changepassowrdpageid">
            <ListItemIconRightText >
              <CommonIcon as={VisibilityOffOutlinedIcon} />
            </ListItemIconRightText>
            <Typography style={webstyle.settingsOptionstext}>{configJSON.changePassword}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight></> : <>  <ListItemTextRight onClick={this.openCardlistpage} data-test-id="cardetailspageid">
            <ListItemIconRightText>
              <img src={carddetails.default} />
            </ListItemIconRightText>
            <Typography style={webstyle.settingsOptionstext}>{configJSON.Cardetails}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>

          <ListItemTextRight onClick={() => this.setState({ pagetoshow: 'Address' })} data-test-id="homeadressid">
            <ListItemIconRightText>
              <CommonIcon as={LocationOnOutlinedIcon} />
            </ListItemIconRightText>
            <Typography style={webstyle.settingsOptionstext}>{configJSON.homeAddress}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight></>}


        <ListItemTextRight onClick={() => this.redirectUser("TermsConditions")} data-test-id="terms-condition-redirectid">
          <ListItemIconRightText>
            <CommonIcon as={SecurityIcon} />
          </ListItemIconRightText>
          <Typography style={webstyle.settingsOptionstext}>{configJSON.privacyAndPolicy}</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>

        <ListItemTextRight onClick={() => this.redirectUser("Contactus")} data-test-id="contactus-redirect-id">
          <ListItemIconRightText>
            <CommonIcon as={CallIcon} />
          </ListItemIconRightText>
          <Typography style={webstyle.settingsOptionstext}>{configJSON.contactUs}</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>
        {this.state.usertype == "rider" && <ListItemTextRight onClick={() => this.setState({ pagetoshow: "changepassword" ,prevpass:"",confirmPassword:"",password:"" })} >
          <ListItemIconRightText >
            <CommonIcon as={VisibilityOffOutlinedIcon} />
          </ListItemIconRightText>
          <Typography style={webstyle.settingsOptionstext}>{configJSON.changePassword}</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>}
        <ListItemTextRight onClick={this.handleLogoutmodal} data-test-id="logoutpoupid">
          <ListItemIconRightText >
            <CommonIcon as={ExitToAppIcon} />
          </ListItemIconRightText>
          <Typography style={webstyle.settingsOptionstext} data-testId={"logoutID"} >{configJSON.logOut}</Typography>
          <IconButtonRight>
            <ArrowForwardIcon />
          </IconButtonRight>
        </ListItemTextRight>
      </ListRight>
    )
  }

  changePasswordscreen = () => {
    return (
      <FormContainer>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.prevpass}</MyFormLabel>
          <Input fullWidth variant="outlined"
            onChange={this.Handleprevpass}
            placeholder="**********"
            type={this.state.prevpassvisible ? "text" : "password"}
            value={this.state.prevpass}

          />
          {this.state.oldpassunmatch && <PasswordMatcherror>
            {configJSON.prevpasserror}
          </PasswordMatcherror>}
          <img src={this.state.prevpassvisible ? imgVisbility.default : imgVisbilityOff.default}
            style={webstyle.password} onClick={() => this.setState({ prevpassvisible: !this.state.prevpassvisible })} data-test-id="prevpassvisible" />
        </FormControl>
        <FormControl fullWidth >
          <MyFormLabel>{configJSON.newpass}</MyFormLabel>
          <Input fullWidth variant="outlined"
            type={this.state.newpassvisibile ? "text" : "password"}
            data-test-id='handle-password-validate'
            onChange={this.handleDriverPasswordChange}
            placeholder="**********"
            value={this.state.password}
          />
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasUpperCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasUpperCase}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasLowerCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasLowerCase}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasNumber ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasNumber}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.hasSpecialChar ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.hasSpecialChar}
          </PasswordcriteriaValidationText>
          <PasswordcriteriaValidationText>
            {this.state.passwordCriteria.isValidLength ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
            {configJSON.minimumChar}
          </PasswordcriteriaValidationText>

          <img data-test-id="newpasswordvisible" src={this.state.newpassvisibile ? imgVisbility.default : imgVisbilityOff.default} style={webstyle.password} onClick={() => this.setState({ newpassvisibile: !this.state.newpassvisibile })} />
        </FormControl>
        <FormControl fullWidth style={{ paddingTop: "19px" }}>
          <MyFormLabel>{configJSON.comfirmpass}</MyFormLabel>
          <Input fullWidth variant="outlined"
            onChange={this.handleConfirmpassowrd}
            data-test-id="handle-confirm-passowrd"
            placeholder="**********"
            type={this.state.confirmpassvisibile ? "text" : "password"}
            value={this.state.confirmPassword}
            InputProps={{
              style: {
                borderRadius: '8px',
                background: "white",
              },
              inputProps: {
                style: {
                  margin: "0px"
                },
              }
            }}
          />
          {this.state.passwordMatcherror && <PasswordMatcherror>
            {configJSON.unmatchpass}
          </PasswordMatcherror>}
          <img data-test-id="confirmpasswordvisible" src={this.state.confirmpassvisibile ? imgVisbility.default : imgVisbilityOff.default} style={webstyle.confirmpassimg} onClick={() => this.setState({ confirmpassvisibile: !this.state.confirmpassvisibile })} />
        </FormControl>
        <Box style={webstyle.drivereditbuttonboxcontainer}>
          <Passowrdbuttobox >
            <SaveeditPasswordbutton onClick={this.HandleSavepassword} data-test-id='handle-save-password'>
              <Button style={webstyle.savetextcolor} >{configJSON.save}</Button>
            </SaveeditPasswordbutton>
            <Nexteditbutton onClick={() => this.setState({ pagetoshow: "" })} data-test-id="backtomainpageid">
              <Button style={webstyle.nexttextcolor} >{configJSON.cancel}</Button>
            </Nexteditbutton>
          </Passowrdbuttobox>

        </Box>
      </FormContainer>
    )
  }

  cardDetails = () => {

    return (
      <>
        <Carddetailswrapper>
          {this.state.cardList?.map((item: any, index: any) => <Accordion style={webstyle.carddropdown}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              key={index}
            >
              <VisaType >
                <Box style={webstyle.onecarddetail}>
                  <img src={visa.default} />
                </Box>
                <Cardexpirened >
                  <Typography style={webstyle.endingvisatext}>{configJSON.visaend}</Typography>
                  <Typography style={webstyle.expirestext}>{configJSON.expiredate + item.expiry_date}</Typography>
                </Cardexpirened>
              </VisaType>
            </AccordionSummary>
            <AccordionDetails sx={webstyle.dropdetails}>
              <CardFormContainer>
                <FormControl fullWidth >
                  <MyFormLabel>{configJSON.creditnumber}</MyFormLabel>
                  <Input fullWidth variant="outlined"
                    placeholder="Credit card number"
                    type="text"
                    value={item.card_number}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth >
                  <MyFormLabel>{configJSON.expiry}</MyFormLabel>
                  <Input fullWidth variant="outlined"
                    placeholder="Expiry date"
                    type="text"
                    value={item.expiry_date}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth >
                  <MyFormLabel>{configJSON.cardname}</MyFormLabel>
                  <Input fullWidth variant="outlined"
                    placeholder="Card holder name"
                    type="Text"
                    value={item.cardholder_name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </CardFormContainer>
            </AccordionDetails>

          </Accordion>)}

          <Box onClick={() => this.setState({ pagetoshow: 'payment' })} data-test-id="openaddpaymentmethodid" style={{ ...webstyle.prefilledcardbox, marginTop: "20px" }}>
            <Box style={webstyle.prefilledcarddetails}>
              <img src={plusicon.default} />
              <Typography style={webstyle.addpaymenttext}>{configJSON.addmethod}</Typography>
            </Box>

          </Box>
        </Carddetailswrapper>
        <Cardbackbutton style={{ paddingTop: "20px" }}>
          <Cardpreviousbutton onClick={() => this.setState({ pagetoshow: "" })} data-test-id="backfromcardtomain">
            <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
          </Cardpreviousbutton>
        </Cardbackbutton>
      </>


    )
  }


  addPaymentmethod = () => {
    return (
      <Box style={webstyle.addcarddetailswrapper}>
        <Box style={webstyle.addpaymentfirstbox}>
          <Box style={{
            paddingLeft: "16px",
          }}>
            <Typography style={webstyle.addpaymenttext}>{configJSON.addmethod}</Typography>
          </Box>
        </Box>
        <AddcardWrapperdetails>
          <Addcardalldetailsbox>
            <Box style={webstyle.cardnumberbox}>
              <Box style={webstyle.paypalbox}>
                {!this.state.isInputFocused && <img src={plusicon.default} data-test-id="ispaypalfocused" onClick={() => this.setState({ isInputFocused: true })}/>}
                <PaymentInput
                  type="text"
                  placeholder="PayPal Id / Apple pay"
                  style={webstyle.paypalidtext}
                  value={this.state.paypalid}
                  name="paypalid"
                  data-test-id="paypalidcheck"
                  onChange={this.handleChange}
                  onFocus={() => this.setState({ isInputFocused: true })}
                  onBlur={() => this.setState({ isInputFocused: false })}
                />
                <div style={{ position: "absolute", top: "60px", left: '2px' }}>
                  {this.state.paypalidError && <Iderror>{this.state.paypalidError}</Iderror>}
                </div>
              </Box>
            </Box>
            <Box style={webstyle.cardnumberbox}>
              <Box style={webstyle.cardnumberinnexbox}>
              {!this.state.isCardinputfocused && <img src={plusicon.default} data-test-id="iscardfocused" onClick={() => this.setState({ isCardinputfocused: true })} /> }
                <PaymentInput
                  type="text"
                  placeholder="Card number"
                  style={webstyle.paypalidtext}
                  value={this.state.cardnumber}
                  data-test-id='cardcheckid'
                  onFocus={() => this.setState({ isCardinputfocused: true })}
                  onBlur={() => this.setState({ isCardinputfocused: false })}
                  onChange={this.handleCardNumberChange}
                />
                <div style={{ position: "absolute", top: "92px", left: '2px' }}>
                  {this.state.isValid === null ? null : this.state.isValid ? (
                    <p style={{ color: 'green', }}>Valid Card Number</p>
                  ) : (
                    <p style={{ color: 'red' }}>Invalid Card Number</p>
                  )}
                </div>
              </Box>

            </Box>
            <Expirycardnamebox >
              <Paymentinnerchildbox >
                <img src={expiryicon.default} />
                <PaymentInput
                  type="text"
                  placeholder="Expiry date"
                  style={webstyle.paypalidtext}
                  value={this.state.expirrydate}
                  name="expirrydate"
                  onChange={this.handleChange}
                  data-test-id="handleexpirydateid"
                />
                <div>
                  {this.state.expirrydateError && <p style={{ color: 'red', position: "absolute", left: "2%", top: "95px" }}>{this.state.expirrydateError}</p>}
                </div>
              </Paymentinnerchildbox>
              <Paymentinnerchildbox >
                <img src={accountholderpng.default} />
                <PaymentInput
                  type="text"
                  placeholder="Card holder name"
                  style={webstyle.paypalidtext}
                  value={this.state.cardHolderName}
                  name="cardHolderName"
                  onChange={this.handleChange}
                />
                <div>
                  {this.state.cardHolderNameError && <p style={{ color: 'red', position: "absolute", left: "2%", top: "95px" }}>{this.state.cardHolderNameError}</p>}
                </div>
              </Paymentinnerchildbox>
            </Expirycardnamebox>
          </Addcardalldetailsbox>
          <Box style={webstyle.coinimage}>
            <img src={coin.default} />
          </Box>
        </AddcardWrapperdetails>

        <Box style={{ ...webstyle.drivereditbuttonboxcontainer, width: "85%" }}>
          <Passowrdbuttobox >
            <Saveeditbutton onClick={this.handleSubmit} data-test-id="addnewcardid">
              <Button style={webstyle.savetextcolor} >{configJSON.save}</Button>
            </Saveeditbutton>
            <Cardetailsprev onClick={this.backtoCarddetails} data-test-id="backtocarddeatilsid">
              <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
            </Cardetailsprev>
          </Passowrdbuttobox>
        </Box>
      </Box >
    )
  }

  riderContactuspage = () => {
    return (
      <Box>
        <Box style={webstyle.riderContactusMainBox}>
          <Box style={webstyle.riderContsctusinside}>
            <ListItemIconRightText style={{ paddingBottom: "41px" }}>
              <CommonIcon as={CallIcon} />
            </ListItemIconRightText>
            <Box style={{ ...webstyle.riderContactusMainBox, gap: "15px" }}>
              <Typography style={webstyle.contacttext}>{configJSON.contactUs}</Typography>
              <Typography style={webstyle.emailtext}>{this.state.userdetails?.rider_detail?.mobile_number || this.state.userdetails?.rider_detail?.mobile_number || "+2309843924"}</Typography>
            </Box>
          </Box>
          <Box style={webstyle.riderContsctusinside}>
            <ListItemIconRightText style={{ paddingBottom: "41px" }}>
              <CommonIcon as={AlternateEmailOutlinedIcon} />
            </ListItemIconRightText>
            <Box style={{ ...webstyle.riderContactusMainBox, gap: "15px" }}>
              <Typography style={webstyle.contacttext}>Email ID</Typography>
              <Typography style={webstyle.emailtext}>{this.state.userdetails?.email}</Typography>
            </Box>

          </Box>
        </Box>

        <Cardbackbutton style={{ paddingTop: "20px" }}>
          <Cardpreviousbutton onClick={() => this.setState({ pagetoshow: "" })} >
            <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
          </Cardpreviousbutton>
        </Cardbackbutton>
      </Box>
    )

  }

  componenttoshow = () => {
    switch (this.state.pagetoshow) {
      case "":
        return this.settingMainpage()
      case "changepassword":
        return this.changePasswordscreen()
      case "Address":
        return this.homeAddress()
      case "carddetails":
        return this.cardDetails()
      case "payment":
        return this.addPaymentmethod()
      case "contactus":
        return this.riderContactuspage()
      default:
        break;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMain>
        {this.componenttoshow()}
        <div>
          <Modal
            open={this.state.openmodal}
            onClose={this.handleClosemodal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box>
                <img src={warning.default} />
              </Box>
              <Typography style={webstyle.logouttext}>
                {configJSON.logOut}
              </Typography>
              <SurityText >
                {configJSON.sureity}
              </SurityText>
              <Box style={webstyle.buttonbox}>
                <Box style={webstyle.cancelButtonbox} onClick={this.handleClosemodal}>
                  <Typography style={webstyle.canceltext}>{configJSON.cancel}</Typography>
                </Box>
                <Box style={webstyle.logoutbuttonbox} onClick={this.handleLogout} data-test-id="handlelogoutid">
                  <CanceltextBox >{configJSON.logOut}</CanceltextBox>
                </Box>
              </Box>
            </Box>
          </Modal>
        </div>
        <Snackbar
          open={this.state.isToastOpen}
          autoHideDuration={2000}
          onClose={this.handletoastclose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert severity="success" onClose={this.handletoastclose}
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            {this.state.succesMessage}
          </MuiAlert>
        </Snackbar>
      </BoxMain>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ArrowForwardIcon = styled(ArrowForwardIosIcon)({
  color: "#0F172A",
  height: "15px",
});

const PaymentInput = styled("input")({
  padding: "0px",
  "::placeholder": {
    color: "#0F172A",
    opacity: 0.5,
  },
});

const Circle = styled("span")({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "15px",
  height: "15px",
  borderRadius: "50%",
  fontSize: "12px",
  color: "white",
  fontWeight: "bold",
  marginRight: "8px",
});

const Addcardalldetailsbox = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  gap: "35px",
  width: "60%",
  "@media (max-width: 960px)": {
    width: "auto"
  }
});

const AddcardWrapperdetails = styled(Typography)({
  width: "95%",
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 960px)": {
    flexDirection: "column"
  }
});


const Cardexpirened = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  gap: "10px"
});

const VisaType = styled(Typography)({
  display: "flex",
  gap: "10px"

});

const CircleGreen = styled(Circle)({
  backgroundColor: "#34D399",
  textAlign: "center"
});

const CircleRed = styled(Circle)({
  backgroundColor: "#DC2626",
  textAlign: "center"
});

const PasswordcriteriaValidationText = styled(Typography)({
  fontSize: "12px",
  color: "#000000",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
  fontFamily: "Inter",
  fontWeight: 400
});

const PasswordMatcherror = styled(Typography)({
  fontSize: "12px",
  color: "#DC2626",
  display: "flex",
  alignItems: "center",
  marginTop: "-6px"
});

const Carddetailswrapper = styled(Typography)({
  display: "flex",
  flexDirection: "column",
});

const Passowrdbuttobox = styled(Box)({
  width: "70%",
  display: "flex",
  gap: '10px',
  flexDirection: "column" as "column",
  alignItems: "center",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const Cardbackbutton = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Saveeditbutton = styled(Box)({
  background: "#1D6FB8",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  height: "56px",
  color: "black",
  width: "50%",
  display: "flex",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const SaveeditPasswordbutton = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  height: "56px",
  background: "#1D6FB8",
  color: "black",
  width: "35%",
  display: "flex",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const Nexteditbutton = styled(Box)({
  background: "",
  color: "black",
  width: "35%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  height: "56px",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const Cardpreviousbutton = styled(Box)({
  color: "black",
  width: "35%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  height: "56px",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const Cardetailsprev = styled(Box)({
  color: "black",
  width: "50%",
  display: "flex",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  height: "56px",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const FormContainer = styled(Box)({
  "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
    border: "none"
  },
  margin: "0",
  width: "95%",
  padding: "10px",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const CardFormContainer = styled(Box)({
  "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
    border: "none"
  },
  margin: "0",
  padding: "10px 0px 0px 0px",
  width: "100%",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const Input = styled(TextField)({
  marginBottom: '16px',
  position: "relative",
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: "#ffffff",
  },
});

const MyFormLabel = styled(FormLabel)({
  color: '#334155',
  fontWeight: 700,
  fontSize: "14px",
  marginBottom: "5px",
  fontFamily: "Inter",
  lineHeight: "22px"
});

const BoxMain = styled(Box)({
  minHeight: "100vh",
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    minHeight: "auto",
  },
});

const IconButtonRight = styled(IconButton)({
  marginLeft: "auto",
});

const CommonIcon = styled("svg")({
  color: "#0F172A",
});

const ListRight = styled(List)({
  width: "95%",
  background: "#FFFFFF",
  color: "#94A3B8",
  borderRadius: "1rem",
  padding: "10px",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const ListItemIconRightText = styled(ListItemIcon)({
  minWidth: "37px",
  cursor: 'pointer !important'

});

const Iderror = styled("p")({
  color: 'red',
  whiteSpace: "nowrap",
  "@media (max-width: 414px)": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "200px"
  },
})

const ListItemTextRight = styled(ListItem)({
  padding: "27px 10px",
  cursor: 'pointer !important',
  pointerEvents: 'auto',
});

const SurityText = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "25.56px",
  lineHeight: "38.33px",
  color: "#0F172A",
  "@media (max-width: 680px)": {
    fontSize: "20px"
  },
  "@media (max-width:550px)": {
    fontSize: "18px"
  }
});

const CanceltextBox = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "25.56px",
  lineHeight: "38.33px",
  cursor: "pointer",
  color: "#FFFFFF",
  "@media (max-width: 371px)": {
    fontSize: "22px"
  },

});

const Expirycardnamebox = styled(Typography)({
  height: "87px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  justifyContent: "space-between",
  "@media (max-width: 600px)": {
    fontSize: "22px",
    flexDirection: "column" as "column",
    height: "auto",
    gap: "40px"
  },
});

const Paymentinnerchildbox = styled(Typography)({
  paddingLeft: "16px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  background: "white",
  borderRadius: "16px",
  height: "100%",
  position: "relative" as "relative",
  "@media (max-width: 600px)": {
    width: "100%",
    height: "87px"
  },
});



const webstyle = {
  logouttext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "25.56px",
    lineHeight: "38.33px",
    color: "#0F172A"
  },

  settingsOptionstext: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#94A3B8"
  },


  carddropdown: {
    width: "95%",
    borderRadius: "16px",
    boxShadow: 'none',
    border: 'none'
  },

  riderContsctusinside: {
    height: "90px",
    width: "70%",
    background: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px"
  },

  addcarddetailswrapper: {
    display: "flex",
    flexDirection: "column" as 'column',
    gap: "20px"
  },

  addpaymentfirstbox: {
    width: "95%",
    background: "white",
    borderRadius: "16px",
    height: "78px",
    display: "flex",
    alignItems: "center",
  },

  coinimage: {
    display: "flex",
    justifyContent: "center"
  },

  onecarddetail: {
    width: "66.67px",
    height: "48px",
    borderRadius: "10px",
    border: "1px solid #6C7278",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  riderContactusMainBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "25px"
  },

  prefilledcarddetails: {
    paddingLeft: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"
  },

  paypalbox: {
    paddingLeft: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    position: "relative" as "relative"
  },

  cardnumberbox: {
    background: "white",
    borderRadius: "16px",
    height: "87px",
    display: "flex",
    alignItems: "center",
  },

  paymentinnerchildbox: {
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    background: "white",
    borderRadius: "16px",
    height: "100%",
    position: "relative" as "relative"
  },

  cardnumberinnexbox: {
    paddingLeft: "16px",
    display: "flex",
    borderRadius: "16px",
    height: "100%",
    position: "relative" as "relative",
    alignItems: "center",
    gap: "10px",
    background: "white",
  },

  dropdetails: {
    backgroundColor: '#ECFDF5',
    padding: "0px"
  },

  addpaymenttext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#0F172A"
  },

  prefilledcardbox: {
    width: "95%",
    background: "white",
    borderRadius: "16px",
    height: "78px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },

  expirycardnamebox: {
    height: "87px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "space-between"
  },

  contacttext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A",
  },

  paypalidtext: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#334155",
    flex: 1,
    height: "100%",
    borderRadius: "8px",
    border: "none",
    outline: "none",
    width: "20%"
  },

  emailtext: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#334155",
  },

  sureitytext: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "25.56px",
    lineHeight: "38.33px",
    color: "#0F172A"
  },

  cancelButtonbox: {
    width: "45%",
    height: "72px",
    background: "#E7E5E4",
    borderRadius: "25.56px",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },

  logoutbuttonbox: {
    width: "45%",
    background: "#DC2626",
    height: "72px",
    borderRadius: "25.56px",
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },

  buttonbox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },

  canceltext: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "25.56px",
    lineHeight: "38.33px",
    color: "#64748B",
    cursor: "pointer"
  },

  drivereditbuttonboxcontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "15px",
    paddingTop: "55px"
  },

  savetextcolor: {
    color: "#ECFDF5",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    cursor: "pointer",
    textTransform: "capitalize" as "capitalize"
  },

  endingvisatext: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "19.36px",
  },

  expirestext: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.36px",
  },

  nexttextcolor: {
    color: "#1D6FB8",
    textTransform: "capitalize" as "capitalize",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    cursor: "pointer"
  },

  password: {
    position: "absolute" as 'absolute',
    right: "19",
    top: "40px",
    height: "30px",
    width: "30px"
  },

  confirmpassimg: {
    position: "absolute" as 'absolute',
    right: "19",
    top: "54px",
    height: "30px",
    width: "30px"
  }
}

// Customizable Area End
