import React from "react";

// Customizable Area Start
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WalletScreenController, {
  Props
} from "./WalletScreenController.web";
import { Box, styled, Button,Paper,Typography,Grid, IconButton, List,Collapse, ListItemButton,
   ListItemText ,Switch,FormControlLabel,FormGroup ,SwitchProps,Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select  } from "@mui/material";
import { dollar,plus,userImage,coin,back_Icon } from "./assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { borderRadius } from "react-select/src/theme";
// Customizable Area End

// Customizable Area Start
const allTransactions = [
  { date: '15 November 2024', type: 'Incoming Transfer', amount: 1000, userName: 'John Doe' },
  { date: '10 November 2024', type: 'Outgoing Transfer', amount: 500, userName: 'Jane Smith' },
  { date: '01 November 2024', type: 'Incoming Transfer', amount: 1500, userName: 'Alice Brown' },
  { date: '15 October 2024', type: 'Outgoing Transfer', amount: 300, userName: 'Bob Johnson' },
  { date: '15 September 2024', type: 'Incoming Transfer', amount: 800, userName: 'Emily Davis' },
  { date: '24 November 2024', type: 'Incoming Transfer', amount: 1200, userName: 'Chris Martin' },
];
// Customizable Area End

export default class WalletScreen extends WalletScreenController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
  applyFilter = () => {
    const { selectedFilter } = this.state;
    const now = new Date();
    let filtered = allTransactions;

    if (selectedFilter === "last 7 days") {
      const sevenDaysAgo = new Date(now);
      sevenDaysAgo.setDate(now.getDate() - 7);
      filtered = allTransactions.filter((tx) => new Date(tx.date) >= sevenDaysAgo);
    } else if (selectedFilter === "last 30 days") {
      const thirtyDaysAgo = new Date(now);
      thirtyDaysAgo.setDate(now.getDate() - 30);
      filtered = allTransactions.filter((tx) => new Date(tx.date) >= thirtyDaysAgo);
    } else if (selectedFilter === "last 90 days") {
      const ninetyDaysAgo = new Date(now);
      ninetyDaysAgo.setDate(now.getDate() - 90);
      filtered = allTransactions.filter((tx) => new Date(tx.date) >= ninetyDaysAgo);
    } else if (selectedFilter === "Up coming transactions") {
      filtered = allTransactions.filter((tx) => new Date(tx.date) > now);
    }

    this.setState({ filteredTransactions: filtered, openFilterPopup: false });
  };
  handleFilterSelect = (option: string) => {
    this.setState({
      selectedFilter: option,
      openFilterPopup: false,  // Close the dialog after selecting an option
    }, () => {
      // Optionally apply the filter logic here (if needed)
      this.applyFilter();
    });
  };
  buttonTabs = () => {
    const options = ['All', 'last 7 days', 'last 30 days', 'last 90 days', 'Up coming transactions'];
    return (
      <>
        <Tabbox >
          <Buttonbox >
            <Singlebuttonbox >
              <Button style={this.state.activeTab == '1' ? webStyle.activeButoon : webStyle.notactiveButton} onClick={() => this.setactiveTab("1")} data-test-id="Button1">
                All  </Button>
            </Singlebuttonbox>

            <Singlebuttonbox >
              <Button style={this.state.activeTab == '2' ? webStyle.activeButoon : webStyle.notactiveButton} onClick={() => this.setactiveTab("2")} data-test-id="Button2">
                In  </Button>
            </Singlebuttonbox>
            <Singlebuttonbox >
              <Button style={this.state.activeTab == '3' ? webStyle.activeButoon : webStyle.notactiveButton} onClick={() => this.setactiveTab("3")} data-test-id="Button3">
                Out  </Button>
            </Singlebuttonbox>
          </Buttonbox>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '72px' }}>      < ArrowDropDownIcon onClick={this.openFilterPopup} />
          </div>

        </Tabbox>
        <StyledDialog open={this.state.openFilterPopup} onClose={this.handleClose}>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap="10px">
              <div style={{ paddingLeft: '20px' }}>Select a Date Range</div>
              {options.map((option) => (
                <Option
                  key={option}
                  selected={this.state.selectedFilter === option}
                  onClick={() => this.handleFilterSelect(option)} // Select and close popup
                >
                  {option}
                </Option>
              ))}
            </Box>
          </DialogContent>
        </StyledDialog>
      </>

    )
  }
  renderTransactions = (transactions: any) => {
    const { selectedFilter } = this.state;

    // Apply additional filters before rendering
    const now = new Date();
    let filtered = transactions;

    if (selectedFilter === 'last 7 days') {
      const sevenDaysAgo = new Date(now);
      sevenDaysAgo.setDate(now.getDate() - 7);
      filtered = transactions.filter((tx: any) => new Date(tx.date) >= sevenDaysAgo);
    } else if (selectedFilter === 'last 30 days') {
      const thirtyDaysAgo = new Date(now);
      thirtyDaysAgo.setDate(now.getDate() - 30);
      filtered = transactions.filter((tx: any) => new Date(tx.date) >= thirtyDaysAgo);
    } else if (selectedFilter === 'last 90 days') {
      const ninetyDaysAgo = new Date(now);
      ninetyDaysAgo.setDate(now.getDate() - 90);
      filtered = transactions.filter((tx: any) => new Date(tx.date) >= ninetyDaysAgo);
    } else if (selectedFilter === 'Up coming transactions') {
      filtered = transactions.filter((tx: any) => new Date(tx.date) > now);
    } return (
      <div style={{ paddingTop: '20px' }}>
        {filtered.map((transaction: any, index: any) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            {/* Date Box with Dividers */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '10px',
                position: 'relative',
              }}
            >
              <div
                style={{
                  flex: 1,
                  height: '1px',
                  backgroundColor: '#87C122',
                }}
              ></div>
              <div
                style={{
                  textAlign: 'center',
                  padding: '10px 20px',
                  border: '1px solid #87C122',
                  borderRadius: '20px',
                  backgroundColor: '#F1F5F9',
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                }}
              >
                {transaction.date}
              </div>
              <div
                style={{
                  flex: 1,
                  height: '1px',
                  backgroundColor: '#87C122',
                }}
              ></div>
            </div>
            <div
              style={{
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '12px',
                backgroundColor: '#fff',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: '14px'
                }}
              >
                <span style={{ fontWeight: 700 }}>{transaction.type}</span>
                <span style={{ fontWeight: 500 }}>${transaction.amount}</span>
              </div>
              <div
                style={{
                  textAlign: 'left',
                  color: '#334155',
                  fontWeight: '400',
                  marginTop: '5px',
                }}
              >
                {transaction.userName}
              </div>
            </div>
          </div>
        ))}

      </div>
    );
  };
  screentab = () => {

    const incomingTransactions = allTransactions.filter(t => t.type === 'Incoming Transfer');
    const outgoingTransactions = allTransactions.filter(t => t.type === 'Outgoing Transfer');
    switch (this.state.activeTab) {
      case '1':
        return this.renderTransactions(allTransactions);

      case '2':
        return this.renderTransactions(incomingTransactions);
      case '3':
        return this.renderTransactions(outgoingTransactions);
    }
  }
  renderUserDetails = (users: any) => {
    return (
      <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
        {users.map((user: any, index: any) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              boxShadow: 'rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px',
              position: 'relative',
              borderRadius: '8px',
              marginBottom: index !== users.length - 1 ? '10px' : '0', // Add margin between user cards
              backgroundColor: '#fff',
            }}
          >
            {/* User Details */}
            <div style={{ flex: 1, marginRight: '10px' }}>
              <div style={{ fontWeight: 700, fontSize: '14px', color: '#333' }}>
                {user.name}
              </div>
              <div style={{ fontSize: '14px', color: '#475569', margin: '5px 0', fontWeight: 700 }}>
                ${user.price}
              </div>
              <div style={{ fontSize: '14px', color: '#94A3B8', fontWeight: 700 }}>
                {user.date}
              </div>
            </div>

            {/* User Image */}
            <div>
              <img
                src={userImage.default}
                alt={user.name}
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '8px',
                  objectFit: 'cover',
                  border: '1px solid #ddd',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };   
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Container container  >
        <Grid item xs={12} sm={5.75} sx={{ backgroundColor: '#ECFDF5', padding: '0px', }}>
          <StyledPaper>
            <StyledText>

              <IconButton style={webStyle.IconButtonStyle} onClick={this.handleBackToMenu} data-test-id="backButton">
                <img
                  src={back_Icon.default}
                  alt="Back"
                  style={webStyle.marginRight}
                />
              </IconButton>
              Payment Methods</StyledText>
          </StyledPaper>
          <StyledPaper onClick={this.props.onPaymentDetailsClick}>
            <StyledText >
              <img
                src={dollar.default}
                alt="Star"
                style={{ width: '13px', height: '18px', marginRight: '10px' }}
              />
              **** **** **** 9843</StyledText>
          </StyledPaper>
          <StyledPaper>
            <Box sx={{ display: 'flex', alignItems: 'center', }} onClick={this.props.onDetailsClick}>
              <StyledText>
                <img
                  src={plus.default}
                  alt="Star"
                  style={{ width: '15px', height: '15px', marginRight: '10px' }}
                />Add Payment Methods</StyledText>
            </Box>
          </StyledPaper>
          <StyledPaper>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <StyledText>Transactions</StyledText>
              <KeyboardArrowDownIcon />
            </Box>
          </StyledPaper>
          <Box sx={{
            width: {
              xs: '100%',
              sm: '50%',
            },
          }}>
            {this.buttonTabs()}

            {this.screentab()}

          </Box>
        </Grid>
        <Grid item xs={0} sm={0.5} sx={{ backgroundColor: '#ECFDF5', padding: '0px' }}>
        </Grid>
        <Grid item xs={12} sm={5.75} sx={{ backgroundColor: '#ECFDF5', padding: '0px' }}>

          <div style={{ height: '50%' }}>
            <StyledPaper>
              <StyledText>Trip History</StyledText>
            </StyledPaper>
            <div style={{ height: '90%', overflowY: 'auto' }}> {this.renderUserDetails(users)} </div>
          </div>
          <ResponsiveContainer>
            <ResponsiveImage src={coin.default} alt="Example" />
          </ResponsiveContainer>
        </Grid>
      </Container>
    );
    // Customizable Area End
  }
}
  // Customizable Area Start
  const Option = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'selected',
  })<{ selected?: boolean }>(({ theme, selected }) => ({
    padding: '10px 20px',
    cursor: 'pointer',
    fontWeight: selected ? 700 : 400,
    backgroundColor: selected ? '#f0f8f5' : 'transparent',
    borderLeft: selected ? '4px solid #87C122' : 'none',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    '&:hover': {
      backgroundColor: '#e6f4f1',
    },
  }));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '20%',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '16px',
    },
  },
}));

const ResponsiveContainer = styled('div')(({ theme }) => ({
  height: '50%',
  width: '100%',
  marginTop: '20px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: '50%',
    marginTop: '10px',
    display: 'none'
  },
}));

const ResponsiveImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: '0%',
  right: '35px',
  height:'50%',
  [theme.breakpoints.down('sm')]: {
    position: 'static',
    width: '100%',
    height: 'auto',
  },
}));
const users = [
  {
    name: 'John Doe',
    price: 120,
    date: '15 May 2024 5:00pm',
    image: 'https://via.placeholder.com/60',
  },
  {
    name: 'Jane Smith',
    price: 95,
    date: '16 May 2024 2:30pm',
    image: 'https://via.placeholder.com/60',
  },
  {
    name: 'Alice Johnson',
    price: 110,
    date: '17 May 2024 11:15am',
    image: 'https://via.placeholder.com/60',
  },
  {
    name: 'Bob Brown',
    price: 75,
    date: '18 May 2024 6:45pm',
    image: 'https://via.placeholder.com/60',
  },
];
const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between', // Ensure grids are aligned properly
  padding: '35px',
  width: '100vw',
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
    padding: '0px',
  },
}));
const StyledPaper = styled(Paper)({
  padding: '15px',
  borderRadius: '8px',
  marginBottom: '15px',
});
const StyledText = styled(Typography)({

  fontSize: '16px',
  fontWeight: 700,
});

const Buttonbox = styled(Box)({
  width: "100%",
  height: "72px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  border: '1px solid #87C122',
  "@media (max-width: 800px)": {
    width: "80%",
  },
  "@media (max-width: 700px)": {
    width: "100%",
  },
  "@media (max-width: 575px)": {
    width: "100%",
    flexDirection: "column",
    height: "150px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});

const Tabbox = styled(Box)({
  width: "100%",
  display: "flex",
  height: "100px",
  "@media (max-width: 575px)": {
    height: "auto",
    width: "100%"
  },
});
const Singlebuttonbox = styled(Box)({
  width: "45%",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
  "@media (max-width: 430px)": {
    width: "100%",
  },
  "@media (max-width: 380px)": {
    width: "100%",
  },
});

const webStyle = {
  IconButtonStyle: { minWidth: 'auto', padding: 0 },
  marginRight: { marginRight: '20px' },
  activeButoon: {
    width: "100%",
    background: "#87C122",
    borderRadius: "50px",
    color: "#0F172A",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },
  activeButton: {
    backgroundColor: '#4caf50', // Example active color
    color: '#fff',
  },
  notactiveButton: {
    // backgroundColor: '#e0e0e0', // Example inactive color
    color: '#475569',
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    whiteSpace: "nowrap"
  },

};
  // Customizable Area End
