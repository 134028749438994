import React from "react";

// Customizable Area Start
import { Box, Button, TextField, Checkbox, FormControlLabel, styled, Typography, IconButton, InputAdornment, FormHelperText, } from "@mui/material";
import { Logo } from "./assets";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box>
        <StyledBox>
          <LeftHalf>

            <FormContainer>
              <Heading1>Login </Heading1>
              <Heading data-testid="signup-heading">
                {this.state.loginError ? (
                  <ErrorBox>{this.state.loginError}</ErrorBox>
                ) : (
                  "Welcome back! Please login to your account"
                )}
              </Heading>
              <InputWrapper>
                <CustomLabel>Email address</CustomLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  style={{ borderRadius: "8px" ,marginTop:'10px'}}
                  placeholder="@ Email Address"
                  data-testid="input-email"
                  onChange={this.handleEmailChange}
                  name="email"
                  value={this.state.email}
                  error={this.state.emailError}
                  InputProps={{
                    style: {
                      borderRadius: "8px", // Apply borderRadius to input
                    },
                  }}
                />
                {this.state.emailError && (
                  <FormHelperText error>
                    Invalid email
                  </FormHelperText>
                )}
              </InputWrapper>

              <InputWrapper style={{ marginBottom: "16px" }}>
                <CustomLabel>Password</CustomLabel>
                <TextField
                  type={this.state.showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  style={{ borderRadius: "8px",marginTop:'10px' }}
                  placeholder="Password"
                  data-testid="input-password"
                  onChange={this.handlePasswordChange}
                  name="password"
                  error={this.state.passwordError}  // Show error state
                  value={this.state.password}
                  InputProps={{
                    style: {
                      borderRadius: "8px", // Apply borderRadius to input
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.handlePasswordVisibility}
                          style={{ paddingRight: '10px'}}
                          data-testid="togglePasswordVisibility"
                          aria-label="input-change"
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                />
                {this.state.passwordError && (
                  <FormHelperText error>
                   Invalid password
                  </FormHelperText>
                )}
              </InputWrapper>
              <CheckboxContainer style={{ marginTop: "-8px", }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember Me"
                  style={{ fontWeight: 400, fontSize: "16px", color: "#1D6FB8", }}
                  data-testid="checkbox-terms"
                />
                <ForgotPasswordLink
                 onClick={this.handleForgot}
                 data-testid="forgot-password-link">
                  Forgot Password?
                </ForgotPasswordLink>
              </CheckboxContainer>

              <ButtonContainer>
                <Button
                  variant="contained"
                  
                  fullWidth
                  data-testid="continue-button"
                  onClick={this.handleSubmit}
                  style={{
                    borderRadius: "8px",
                    padding: "10px 16px 10px 16px",
                    textTransform: "none",
                    fontSize:'20px',
                    fontWeight:400,
                    backgroundColor:'#1D6FB8',
                  }}

                >
                  Sign In
                </Button>
              </ButtonContainer>
              <BottomText data-testid="login-link">
                New User? <LoginLink  data-testid="signup-link" onClick={this.navigateToRiderSignUP}>Sign Up</LoginLink>
              </BottomText>
            </FormContainer>
          </LeftHalf>

         
          <RightHalf>
          <MainImgage src={Logo.default} alt="right-main" />

          <TextBox>
            <TypographyImgTextOne>Your Safe and smooth ride awaits.</TypographyImgTextOne>
            <TypographyImgTextTwo>Non-Medical Transport</TypographyImgTextTwo>
          </TextBox>
        </RightHalf>
        </StyledBox>
        <Snackbar
          open={this.state.isToastOpen}
          autoHideDuration={2000}
          onClose={this.handleToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastClose} severity="success"
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            Logged in successfully
          </MuiAlert>
        </Snackbar>
      </Box>

      // Customizable Area End
    );
  }

}

// Customizable Area Start

const StyledBox = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
 
  '@media (max-width: 960px)': {
    flexDirection: "column",
    height: "auto",
  }
});

const LeftHalf = styled("div")({
  width: "50%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",

  }
});
const FormContainer = styled("div")({
  width: "500px",
  height: "100%",
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  // alignItems:'center',
  overflow: "scroll",
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: "none",
  "@media (max-width: 960px)": {
    width: "80%",
    marginTop: "20px"
  }
});

const InputWrapper = styled(Box)({
  position: "relative",
  marginBottom: "32px",
  width: "100%",
  zIndex: 2,
});

const CustomLabel = styled(Typography)({
  position: "absolute",
  top: "-22px",
  left: "1px",
  backgroundColor: "white",
  padding: "0 4px",
  fontSize: "14px",
  fontWeight: "bold",
  color: "#334155",
  zIndex: 1,
  marginBottom: "40px",
});

const ButtonContainer = styled("div")({
  marginTop: "60px",
  marginBottom: "24px",
  "@media (max-width: 960px)": {
    marginTop: "20px",
  marginBottom: "24px",
  }
});

const BottomText = styled("div")({
  textAlign: "center",
  fontWeight: 400,
  fontSize: "16px",
  color: "#000000",
  marginBottom:'20px'
});

const LoginLink = styled("a")({
  color: "#1D6FB8",
  fontSize:'16px',
  fontWeight: "400",
  cursor:'pointer'
});


const Heading = styled(Typography)({
  fontWeight: "400px",
  fontSize: "18px",
  color: "#1D6FB8",
  width: "100%",
  marginBottom: "40px",
  "@media (max-width: 960px)": {
    fontSize: "16px",
    marginTop: "25px",
  }
});
const Heading1 = styled(Typography)({
  fontWeight: 700,
  fontSize: '30px',
  color: '#1D6FB8',
  marginBottom: '24px',
  "@media (max-width: 960px)": {
    fontSize: "25px",
    marginBottom: '10px',
  }
});

const CheckboxContainer = styled("div")({
  marginTop: "-8px",
  marginBottom: "24px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const ForgotPasswordLink = styled("a")({
  color: "#94A3B8",
  fontWeight: 400,
  fontSize: "14px",
  cursor: "pointer",
});

const ErrorBox = styled("span")({
  display: "block",
  width: "100%",
  padding: "12px",
  backgroundColor: "#FEE2E2",
  color: "DC2626",
  border: "1px solid #f5c6cb",
  borderRadius: "8px",
  marginBottom: "16px",
  textAlign: "initial",
  boxSizing: "border-box",
  fontSize: "12px",
  fontWeight: 100,
});

const RightHalf = styled(Box)({
  width: "50%",
  height: "100%",
  textAlign: 'center',
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",
  }
})
const MainImgage = styled('img')({
  width: '100%',
  height: '85%',
  "@media (max-width: 960px)": {
    width: '100%',
  }
});

const TextBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

const TypographyImgTextOne = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
});

const TypographyImgTextTwo = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
})

// Customizable Area End