import React from "react";

// Customizable Area Start

import DriverSucessScreenController, {
  Props
} from "./DriverSucessScreenController.web";
import { driverSucess, rightLogo} from "./assets";
const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class DriverSucess extends DriverSucessScreenController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div className="driver-app-container">
      <div className="driver-top-section">
        <img
          src={driverSucess.default}
          alt="Main Image"
          className="driver-main-image"
        />
        <img
          src={rightLogo.default}
          alt="Logo"
          className="driver-logo-image"
        />
      </div>

      <div className="driver-bottom-section">
        <h1 className="driver-main-title">{configJSON.BigTitle}</h1>
        <h2 className="driver-sub-title1">{configJSON.SmallTitle1}</h2>
        <h2 className="driver-sub-title2">{configJSON.SmallTitle2}</h2>
        <button className="driver-action-button" data-testid="navigateToDashboard" onClick={this.navigateToDashboard}>{configJSON.continue}</button>
      </div>
    </div>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
 
   
  };
  // Customizable Area End
