
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    helpcenterQA?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    searchFaq: any
    token: String;
    helpCentreQA: any
    showerror: boolean
    hasTriggeredHelpCentre: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class HelpCentreFaqController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            searchFaq: "",
            token: "",
            helpCentreQA: [],
            showerror: false,
            hasTriggeredHelpCentre: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getHelpCentreQA();
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getHelpCentreApiCallId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ helpCentreQA: responseJson.data });
                    }
                }
            }
            if (apiRequestCallId === this.searchHelpcenterApiCallId) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ helpCentreQA: responseJson.data });
                    }
                    else {
                        this.setState({ showerror: true })
                    }
                }
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    searchHelpcenterApiCallId: any
    getHelpCentreQA = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getHelpCentreApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.helpcentreAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    searchFaqanswers = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.searchHelpcenterApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.helpcentreSearch}=${this.state.searchFaq}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevState.searchFaq !== this.state.searchFaq && this.state.searchFaq === "" && !this.state.hasTriggeredHelpCentre) {
            this.getHelpCentreQA();
            this.setState({ hasTriggeredHelpCentre: true });
        }
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            this.setState((prevState) => {
                if (prevState.searchFaq === "" && !prevState.hasTriggeredHelpCentre) {
                    return { hasTriggeredHelpCentre: true };
                }
                return null;
            });
        }
    };

    handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        this.setState({ searchFaq: value, showerror: false });
        if (value !== "" && this.state.hasTriggeredHelpCentre) {
            this.setState({ hasTriggeredHelpCentre: false });
        }
    }

    searchqueAns = () => {
        if (this.state.searchFaq) {
            this.searchFaqanswers()
        }
    }
    // Customizable Area End
}
