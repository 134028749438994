import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { mapp_Imag, backicon, greenlocation, redlocation } from "./assets";
import MapsdriverController, { Props, } from "./MapsdriverController";
const configJSON = require("./config");
// Customizable Area End
export default class Mapsdriver extends MapsdriverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div style={styles.container}>
        <NavigationMenu navigation={this.props} id={""} index={1} />
        <main style={{ width: "100%" }}>
          <div style={styles.mapContainer}>
            <button
              style={styles.backButton}
              onClick={this.navigateToAppointmentList}
            >
              <img src={backicon.default} style={styles.locationicon} />
            </button>
            <img src={mapp_Imag.default} alt="Map" style={styles.mapImage} />
          </div>
          <div style={styles.rideDetails}>
            <h2 style={styles.heading}>Ride in progress</h2>
            <p style={styles.paragraphone}>
              You’ll reach your destination by <span>03:30 pm</span>
            </p>
            <div>
              <h3 style={styles.heading}>Trip Details</h3>
              <div style={styles.containerone}>
                <img src={redlocation.default} style={styles.locationimg} />
                <div>
                  <p style={styles.paragraph}>
                    <strong>Drop Off At:</strong>
                  </p>
                  <p style={styles.paragraphdrop}>
                    Avenue, Lincoln Park, Chicago
                  </p>
                </div>
              </div>
              <div style={styles.containerone}>
                <img src={greenlocation.default} style={styles.greenlocationimg} />
                <div>
                  <p style={styles.paragraph}>
                    <strong>
                      Pick Up From:
                    </strong>{" "}
                  </p>
                  <p style={styles.paragraphdrop}>
                    Maple Street, Springfield</p>
                </div>
              </div>
              </div>
              <div style={styles.actionButtons}>
                <button
                  style={styles.continueButton}
                  onClick={this.navigateToReviews}

                >
                  End Ride
                </button>
                <button
                  style={styles.cancelButton}
                  onClick={this.navigateToDriverincomingjob}

                >
                  Cancel Ride 
                </button>
              </div>
            </div>
        </main>
      </div>);
  }
  // Customizable Area End
}
// Customizable Area Start
const styles = {
  container: {
    backgroundColor: "#f2fcf5",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    width: "100%",
  },
  mapContainer: {
    width: "95%",
    height: "auto",
    margin: "20px auto",
    position: "relative" as const,
    borderRadius: "10px",
    overflow: "hidden",
  },
  backButton: {
    position: "absolute" as const,
    top: "25px",
    left: "1px",
    color: "black",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
  },
  mapImage: {
    width: "100%",
    height: "468px",
    borderRadius: "10px",
  },
  rideDetails: {
    width: "90%",
    maxWidth: "100%",
    margin: "20px auto",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "700",
    marginBottom: "10px",
  },
  paragraphone: {
    fontSize: "16px",
    marginBottom: "10px",
    borderBottom: "1px solid #E2E8F0",
    display: "inline-block",
    paddingBottom: "10px",
    color: "#1E293B",
    fontweight: '400'
  },
  paragraph: {
    fontSize: "20px",
    fontWeight: "700",
    marginBottom: "10px",
    color: '#334155',
    lineheght: '29',
    paddingLeft: '20px',
  },
  paragraphdrop: {
    fontSize: "16.48px",
    fontWeight: "400",
    lineHeight: "29.59px",
    margin: "13.66px 0",
    color: '#64748B',
    paddingLeft: '20px',
  },
  actionButtons: {
    display: "flex",
    marginTop: "20px",
    gap: "20px",
  },
  continueButton: {
    backgroundColor: "#1D6FB8",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "18px",
    height: "42px",
    width: '185px',
  },
  locationicon: {
    width: "20px",
    height: '22px',
  },
  locationimg: {
    width: "18px",
    height: '22px',
  },
  greenlocationimg: {
    width: "22px",
    height: '24px',
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#DC2626",
    border: "1px solid red",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "18px",
    height: "42px",
    width: '185px',
    fontWeight: "400",
  },
  containerone: {
    display: 'flex',
    alignItems: 'center',
  },
};
// Customizable Area End

