import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  selectedSection: string;
  setSelectedSection: (section: string) => void;
   // Customizable Area End
}

export interface S {
  // Customizable Area Start
  openFirstRider:boolean;
  openSecondRider:boolean;
  openThirdRider:boolean;
  openFourthRider:boolean;
  notificationModalRider:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class NotificationUIRiderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      openFirstRider:false,
      openSecondRider:false,
      openThirdRider:false,
      openFourthRider:false,
      notificationModalRider:false,
    };    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleBackToMenu = () => {
    // Set the selectedSection back to the initial component
    this.props.setSelectedSection("appointment"); // Update this to your initial section
  };
  handleFirstClickRider = () => {
    this.setState({notificationModalRider:true})
  };

  handleSecondClickRider = () => {
    this.setState((prevState) => ({
      openSecondRider: !prevState.openSecondRider,
    }));
  };
  handleThirdClickRider = () => {
    this.setState((prevState) => ({
      openThirdRider: !prevState.openThirdRider,
    }));
  };

  handleFourthClickRider = () => {
    this.setState((prevState) => ({
      openFourthRider: !prevState.openFourthRider,
    }));
  };
  handleNotificationCloseRider = () => {
    this.setState({ notificationModalRider: false });
  };
  goToRatingRider=()=>{
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "Reviews");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToRatingRider=()=>{
    this.goToRatingRider();
    this.setState({ notificationModalRider: false });
  }
  // Customizable Area End
}