Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!";
exports.backButtonAltText = "Back";
exports.notificationsTitle = "Notifications";
exports.todayLabel = "Today";
exports.newRideAvailable = "New Ride Available";
exports.newRideDetails = "New ride! Tap to see details.";
exports.earlierLabel = "Earlier";
exports.updateLabel = "Update";
exports.updateProfilePicture = "Please, update your profile picture.";
exports.rateLastTrip = "Rate your last trip";
exports.feedbackPrompt = "Thanks for your riding, please give a rating and feedback.";
exports.enableEmailNotifications = "Enable Email Notifications";
exports.thanksText = "Thanks for riding with us! Rate your driver and let us know how we did.";
exports.rideCompleted = "Ride Completed";
exports.DriverMatched = "Driver Matched";
exports.GreatNews = "Great news! Your driver is on the way.";


// Customizable Area End