import React, { Component } from "react";
import { ToolbarProps, Views } from "react-big-calendar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type State = {
  selectedView: string;
};
type CustomToolbarProps = ToolbarProps<any>;
class CustomToolbar extends Component<CustomToolbarProps, State> {
  state: State = {
    selectedView: this.props.view || Views.MONTH,
  };

  handleButtonClick = (view: "month" | "week" | "day" | "agenda") => {
    this.setState({ selectedView: view });
    this.props.onView(view); // Ensure correct lowercase view type
  };

  buttonStyles = (view: string) => ({
    cursor: "pointer",
    margin: "0 5px",
    padding: "8px 16px",
    borderRadius: "20px",
    fontSize: "14px",
    fontWeight: 700,
    color: view === this.state.selectedView ? "#87C122" : "#64748B",
    backgroundColor: view === this.state.selectedView ? "#ECFDF5" : "transparent",
    border: "none",
    outline: "none",
  });

  render() {
    const { label, onNavigate } = this.props;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        {/* Date Range Label */}
        <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>{label}</span>

        {/* Navigation and View Buttons */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIosIcon
            style={{
              cursor: "pointer",
              marginRight: "10px",
              color: "#94A3B8",
              fontSize: "16px",
            }}
            onClick={() => onNavigate("PREV")}
          />
          <ArrowForwardIosIcon
            style={{
              cursor: "pointer",
              marginRight: "20px",
              color: "#94A3B8",
              fontSize: "16px",
            }}
            onClick={() => onNavigate("NEXT")}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#F8FAFC",
              borderRadius: "20px",
              padding: "4px",
            }}
          >
            <button
              onClick={() => this.handleButtonClick("day")}
              style={this.buttonStyles("day")}
            >
              Day
            </button>
            <button
              onClick={() => this.handleButtonClick("week")}
              style={this.buttonStyles("week")}
            >
              Week
            </button>
            <button
              onClick={() => this.handleButtonClick("month")}
              style={this.buttonStyles("month")}
            >
              Month
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomToolbar;
