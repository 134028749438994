
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any; 
    openModalIndex: number | undefined
    appointmentdata: any
    showeditconent:boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AppointmentRiderContoller extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            openModalIndex: undefined,
            showeditconent:false,
            appointmentdata: [
                {
                    appoitnmentserial: "Appointment 1", appointmentid: '#669059', status: "CONFIRMED"
                },
                {
                    appoitnmentserial: "Appointment 2", appointmentid: '#669059', status: "DECLINED"
                },
                {
                    appoitnmentserial: "Appointment 3", appointmentid: '#669059', status: "PENDING"
                },
            ]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));


        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
