// Customizable Area Start
import React from "react";
import {
  styled,
  Typography,
  Box,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  IconButton,
  InputAdornment,
  LinearProgress,
  FormLabel,
  Modal,
  CircularProgress
} from '@mui/material';
import SignUpRiderController, { configJSON } from "./SignUpRiderController.web";
import { Logo, error, retry } from "./assets";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class SignUpRider extends SignUpRiderController {
  // Customizable Area Start
  stepOne = () => {
    return (
      <Box data-testId={"continueStepOneId"} component={"form"} onSubmit={this.handleSubmitRiderAccount}>
        <RadioGroup
          row
          value={this.state.role}
          onChange={this.handleRoleChange}
          data-testId={"radioButtonId"}
        >
          <FormControlLabel
            value="rider"
            control={
              <RadioStepOne />
            }
            label="Rider"
          />
          <FormControlLabel
            value="driver"
            control={
              <RadioStepOne />
            }
            label="Driver"
          />
        </RadioGroup>

        <InputWrapper>
          <CustomLabel>{configJSON.fname}</CustomLabel>
          <TextFieldCustom
            variant="outlined"
            placeholder="Jane"
            fullWidth
            required
            name={"firstName"}
            value={this.state.firstName}
            onChange={this.handleChangeForFLName}
            data-testId={"firstNameId"}
            inputProps={{
              minLength: 3,
              maxLength: 50,
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.lname}</CustomLabel>
          <TextFieldCustom
            variant="outlined"
            placeholder="Doe"
            fullWidth
            required
            name={"lastName"}
            value={this.state.lastName}
            onChange={this.handleChangeForFLName}
            inputProps={{
              minLength: 3,
              maxLength: 50,
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.email}</CustomLabel>
          <TextFieldCustom
            data-testId={"emailId"}
            variant="outlined"
            fullWidth
            required
            placeholder="@ jane.doe@email.com"
            type="email"
            name={"email"}
            value={this.state.email}
            onChange={this.handleEmailChange}
            error={this.state.emailError}
          />

          {this.state.emailError && (
            <InvalidEmail>
              {configJSON.invalidEmail}
            </InvalidEmail>
          )}

        </InputWrapper>

        <InputWrapper>
          <CustomLabel>Password</CustomLabel>
          <TextFieldCustom
            data-testId={"passwordId"}
            type={this.state.showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            required
            placeholder="......"
            name={"password"}
            value={this.state.password}
            onChange={this.handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                      <Visibility /> 
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        <ValidationText>
          {this.state.passwordCriteria.hasUpperCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasUpperCase}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasLowerCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasLowerCase}
        </ValidationText>
        <ValidationText> 
          {this.state.passwordCriteria.hasNumber ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasNumber}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasSpecialChar ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasSpecialChar}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.isValidLength ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.minimumChar}
        </ValidationText>

        <FormControlLabelCustom
          data-testId={"checkBoxId"}
          control={
            <CheckboxStepOne
              checked={this.state.agreeToTerms}
              onChange={this.handleCheckboxChange}
            />
          }
          label={
            <TypographyIagress
              variant="body2"
              color={!this.state.agreeToTerms ? '#DC2626' : 'inherit'}
              onClick={() => this.navigateTermscondition("TermsConditions")}
              data-test-id="signupto-terms-condition"
            >
              {configJSON.iAgress}
            </TypographyIagress>
          }
        />

        <ButtonOne
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          disabled={!this.state.agreeToTerms}
          type="submit"
        >
          {this.state.btnLoader ? <CircularProgress /> : configJSON.continue}
        </ButtonOne>

        <BottomText>
          <TypographyBackTo>
            {configJSON.alreadyHavAccount}
          </TypographyBackTo>
          <ButtonBackto
            data-testId={"loginBtnId"} onClick={this.handleLogin}>{configJSON.login}</ButtonBackto>
        </BottomText>
      </Box>
    )
  }

  stepTwo = () => {
    const StyledTypography = styled(Typography)<StyledTypographyProps>(({ isSelected }) => ({
      padding: '12px',
      border: '1px solid #C0C0C0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color:this.getColor(isSelected), // Black if selected, gray otherwise
      '@media (max-width: 960px)': {
        fontSize: '12px',
      },
    }));
    return (
      <Box component={"form"} data-testId={"stepTwoContinueId"} onSubmit={this.handleSubmitTwo}>
        {this.state.riderMobNoError ? (
           <CheckboxValidationContainer>Mobile number is already taken</CheckboxValidationContainer>
        ) : (
          null
        )}
        <InputWrapper2>
          <CustomLabel>{configJSON.moNumber}</CustomLabel>
          <TextFieldCustom
            variant="outlined"
            fullWidth
            required
            placeholder={configJSON.moNumberInput}
            type="tel"
            name={"mobileNumber"}
            value={this.state.mobileNumber}
            onChange={this.handleChangeMobileNo}
            inputProps={{
              minLength: 10,
              maxLength: 12,
              pattern: "[0-9]*", 
              onInput: this.takeonlyNumber
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">US +1</InputAdornment>
              )
            }} 
          />
           {this.state.mobileNumberError && (
            <InvalidEmail data-test-id="mobileNo">
              Mobile number must be between 10 and 12 digits.
            </InvalidEmail>
          )}
        </InputWrapper2>

        <FormControl fullWidth margin="normal">
          <MyFormLabel>{configJSON.requirement}</MyFormLabel>

          <SelectDialog
            placeholder={configJSON.moNumberInput}
            onClick={this.handleOpenSelectModal}
            data-testId={'additonalBTNID'}
          >
            <StyledTypography  isSelected={this.state.requirement !== ""} data-testId={'noneSelectTextId'}>
              {this.state.requirement == "" ? configJSON.requirementWheel : this.state.requirement}
            </StyledTypography>
          </SelectDialog>
        </FormControl>

        <InputWrapper2>
          <CustomLabel>{configJSON.anythingElse}</CustomLabel>
          <TextFieldCustom
            variant="outlined"
            name={"anythingElse"}
            value={this.state.anythingElse}
            onChange={this.handleChangeInput}
            fullWidth
            placeholder={configJSON.iCanWalk}
          />
        </InputWrapper2>

        <ButtonOne
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 18 }}
          type="submit"
        >
          {this.state.btnLoader ? <CircularProgress /> : configJSON.continue}
        </ButtonOne>
      </Box>
    );
  };

  stepThree = () => {
    return (
      <>
        <InputWrapper>
          <CustomLabel>{configJSON.homeAdd}</CustomLabel>
          <TextFieldCustom
            data-testId={"addressID"}
            variant="outlined"
            name="homeAddress"
            placeholder="Enter your home address"
            value={this.state.homeAddress}
            onChange={this.handleGetPlace}
            fullWidth
            error={this.state.homeAddressError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.address1}</CustomLabel>
          <TextFieldCustom
            data-testId={"addressOne"}
            placeholder={`${configJSON.Enter} ${configJSON.address1}`}
            variant="outlined"
            name="addressOne"
            value={this.state.addressOne}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.addressOneError}
          />
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.address2}</CustomLabel>
          <TextFieldCustom
            data-testId={"addressTwo"}
            placeholder={`${configJSON.Enter} ${configJSON.address2}`}
            variant="outlined"
            name={"addressTwo"}
            value={this.state.addressTwo}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.addressTwoError}
          />
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.city}</CustomLabel>
          <TextFieldCustom
            data-testId={"cityId"}
            placeholder={configJSON.city}
            variant="outlined"
            name={"city"}
            value={this.state.city}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.cityError}
          />
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.country}</CustomLabel>
          <TextFieldCustom
            data-testId={"countryId"}
            placeholder={configJSON.country}
            variant="outlined"
            name={"country"}
            value={this.state.country}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.countryError}
          />
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.zipCode}</CustomLabel>
          <TextFieldCustom
            data-testId={"zipId"}
          placeholder={configJSON.zipCode}
            variant="outlined"
            inputProps={{
              pattern: "[0-9]*", 
              onInput: this.takeonlyNumber
            }}
            name={"zipCode"}
            value={this.state.zipCode}
            onChange={this.handleChangeInput}
            fullWidth
            error={this.state.zipCodeError}
          />
        </InputWrapper>

        <ButtonOne
          data-testId={"signUpButtonId"}
          variant="contained"
          color="primary"
          fullWidth
          onClick={this.handleFinalStep}
        >
          {this.state.btnLoader ? <CircularProgress /> : configJSON.continue}
        </ButtonOne>

        <ButtonBackButton data-testId={"backButtonId"} onClick={this.goToBackStep}>
          {configJSON.back}
        </ButtonBackButton>
      </>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <StyledBox>
        <LeftHalf>
          <FormContainer>
            <TypographyCreateYour>
              {configJSON.createYourEryde}
            </TypographyCreateYour>

            <ProgressBarContainer>
              <TypographyStepOne
                variant="body2"
                data-testId={"progress-step"}
              >
                Step {this.state.currentStep} of 3
              </TypographyStepOne>
              <CustomLinearProgress
                variant="determinate"
                value={this.state.currentStep === 1 ? 25 : this.state.currentStep === 2 ? 50 : 100}
                data-testId={"progress-bar"}
              />
            </ProgressBarContainer>

            {this.state.showApiError && (
              <CheckboxValidationContainer>
                <Typography data-testId={"terms-error"}>{this.state.apiErrorMessage}</Typography>
              </CheckboxValidationContainer>
            )}

            {this.state.currentStep === 1 && this.stepOne()}
            {this.state.currentStep === 2 && this.stepTwo()}
            {this.state.currentStep === 3 && this.stepThree()}

          </FormContainer>
        </LeftHalf>
        <RightHalf>
          <MainImgage src={Logo.default} alt="right-main" />

          <TextBox>
            <TypographyImgTextOne>{configJSON.yourSafe}</TypographyImgTextOne>
            <TypographyImgTextTwo>{configJSON.nonMedical}</TypographyImgTextTwo>
          </TextBox>
        </RightHalf>

        <Modal
  open={this.state.openSelectModal}
  onClose={this.handleCloseSelectModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  data-testId={'closeAdditionalRequirementModalID'}
>
  <BoxWheel boxShadow={24}>
    <SelectDriveType
      data-testId={'noneSelectId'}
      activeBTN={this.state.requirement === configJSON.none}
      onClick={() => this.handleSelectRequirement(configJSON.none)}
    >
      <CustomCheckboxWrapper>
      <CustomCheckbox>
        <input
          type="checkbox"
          checked={this.state.requirement === configJSON.none}
          readOnly
        />
        <span className="checkmark"></span>
      </CustomCheckbox>
      <SelectNameTypography data-testId={'noneTextID'}>
        {configJSON.none}
      </SelectNameTypography>
      </CustomCheckboxWrapper>
    </SelectDriveType>

    <SelectDriveType
    data-test-id="ButtonDriveType1"
      activeBTN={this.state.requirement === configJSON.wheelchair}
      onClick={() => this.handleSelectRequirement(configJSON.wheelchair)}
    >
      <CustomCheckboxWrapper>
      <CustomCheckbox>
        <input
          type="checkbox"
          checked={this.state.requirement === configJSON.wheelchair}
          readOnly
        />
        <span className="checkmark"></span>
      </CustomCheckbox>
      <SelectNameTypography>
        {configJSON.wheelchair}
      </SelectNameTypography>
      </CustomCheckboxWrapper>
    </SelectDriveType>

    <SelectDriveType
     data-test-id="ButtonDriveType2"
      activeBTN={this.state.requirement === configJSON.gurney}
      onClick={() => this.handleSelectRequirement(configJSON.gurney)}
    >
      <CustomCheckboxWrapper>
      <CustomCheckbox>
        <input
          type="checkbox"
          checked={this.state.requirement === configJSON.gurney}
          readOnly
        />
        <span className="checkmark"></span>
      </CustomCheckbox>
      <SelectNameTypography>
        {configJSON.gurney}
      </SelectNameTypography>
      </CustomCheckboxWrapper>
    </SelectDriveType>
  </BoxWheel>
</Modal>


        <Modal
          open={this.state.openErrorModal}
          onClose={this.handleErrorClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testId={'closeOopsModalID'}
        >
          <ErrorBox>
            <BoxCenter>
              <ErrorImg src={error.default} alt="error" />
              <TypographyOops data-testId={"emptyErrorId"}>{configJSON.oops}</TypographyOops>
              <TypographyDouble>{configJSON.double}</TypographyDouble>
              <ButtonReturn onClick={this.handleErrorClose}>
                <ImgRetry src={retry.default} alt="retry" />
                <TypographyRetry>{configJSON.retry}</TypographyRetry>
              </ButtonReturn>
            </BoxCenter>
          </ErrorBox>
        </Modal>
      </StyledBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
interface StyledTypographyProps {
  isSelected: boolean;
}
const ErrorBox2 = styled("span")({
  display: "block",
  width: "100%",
  padding: "12px",
  backgroundColor: "#FEE2E2",
  color: "DC2626",
  border: "1px solid #f5c6cb",
  borderRadius: "8px",
  marginBottom: "16px",
  textAlign: "initial",
  boxSizing: "border-box",
  fontSize: "12px",
  fontWeight: 100,
});
const CustomCheckboxWrapper = styled('div')`
  display: flex;
  align-items: center; /* Align checkbox and text vertically */
  gap: 8px; /* Space between checkbox and text */
`;
const CustomCheckbox = styled('label')`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border: 2px solid #c0c0c0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input:checked + .checkmark {
    background-color: #87C122; 
    border: none;
  }

  input:checked + .checkmark::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 12px;
    border: solid #ffffff; /* White checkmark color */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 4px; /* Adjust vertical alignment */
  }
`;

const StyledBox = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  '@media (max-width: 960px)': {
    flexDirection: "column",
    height: "auto",
  }
});

const LeftHalf = styled("div")({
  width: "50%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",
  }
});

const FormContainer = styled("div")({
  width: "500px",
  height: "100%",
  overflow: "scroll",
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: "none",
  "@media (max-width: 960px)": {
    width: "80%",
    marginTop: "20px"
  }
});

const RadioStepOne = styled(Radio)({
  '&.Mui-checked': {
    color: '#87C122',
  },
});

const TypographyCreateYour = styled(Typography)({
  color: '#1D6FB8',
  fontSize: "30px",
  marginTop: "10px",
  textAlign: "center",
  "@media (max-width: 960px)": {
    fontSize: "20px",
  }
});

const CheckboxStepOne = styled(Checkbox)({
  borderRadius: '50px'
});

const InvalidEmail = styled(Typography)({
  color: "#DC2626",
  marginTop: "30px"
})

const TypographyIagress = styled(Typography)({
  fontSize: '16px',
  width: '386px',
  "@media (max-width: 960px)": {
    width: '100%',
  }
});

const TypographyStepOne = styled(Typography)({
  fontWeight: 500,
  fontSize: '18px',
  color: '#1D6FB8',
  marginBottom: '8px',
});

const ProgressBarContainer = styled('div')({
  width: '100%',
  margin: '10px 0',
});

const CustomLinearProgress = styled(LinearProgress)({
  height: '10px',
  borderRadius: '5px',
  backgroundColor: "#E0E0E0",
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#87C122',
  },
});

const ButtonOne = styled(Button)(({ theme }) => ({
  marginTop: '24px',
  height: '60px',
  color: '#FFFFF',
  backgroundColor: '#1D6FB8',
  cursor: "pointer",
  textTransform: 'none',
  borderRadius: '10px',
  fontSize: "20px",
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const BottomText = styled("div")({
  textAlign: "center",
  fontWeight: 400,
  color: "#000000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10px",
  cursor: 'pointer',

});

const TypographyBackTo = styled(Typography)({
  color: "#000000",
});

const ButtonBackto = styled(Typography)({
  color: '#1D6FB8',
  marginLeft: "5px",
  textDecorationLine: 'underline'
});

const InputWrapper = styled(Box)({
  width: '100%',
  margin: '30px 0px',
});
const InputWrapper2 = styled(Box)({
  width: '100%',
  margin: '15px 0px',
});
const CustomLabel = styled(Typography)({
  backgroundColor: 'white',
  fontSize: '14px',
  color: '#334155',
  fontWeight: 700,
  marginBottom: '5px',
});

const TextFieldCustom = styled(TextField)({
  width: '100%',
  height: "40px",
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
});

const ValidationText = styled(Typography)({
  fontSize: "14px",
  color: "#000000",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
});

const Circle = styled("span")({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  fontSize: "12px",
  color: "white",
  fontWeight: "bold",
  marginRight: "8px",
});

const CircleGreen = styled(Circle)({
  backgroundColor: "#34D399",
  textAlign: "center",
  fontSize: "15px"
});

const CircleRed = styled(Circle)({
  backgroundColor: "#DC2626",
  textAlign: "center",
  fontSize: "15px"
});

const CheckboxValidationContainer = styled(Box)({
  marginBottom: "24px",
  padding: "10px",
  border: "1px solid #DC2626",
  borderRadius: "4px",
  backgroundColor: "#FEE2E2",
  color: "#DC2626",
  fontSize: "14px"
});

const MyFormLabel = styled(FormLabel)({
  color: '#334155',
  fontWeight: 600,
  letterSpacing: '0.5px',
  fontSize: '14px',
  marginBottom: '5px',
});

const SelectDialog = styled(Box)({
  background: '#FFFFFF',
  color: '#A0A0A0',
  '& .MuiTypography-root': {
    borderRadius: '10px',
  },
});



const IconButtonArrow = styled(IconButton)({
  marginLeft: '110px',
});

const ButtonBackButton = styled(Button)(({ theme }) => ({
  margin: '15px 0px',
  height: '60px',
  color: '#1D6FB8',
  border: '1px solid #1D6FB8',
  borderRadius: '10px',
  width: '100%',
  backgroundColor: '#FFFFFF',
  textTransform: 'none',
  fontSize: '20px',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: '#FFFFFF',
  },
}));

const FormControlLabelCustom = styled(FormControlLabel)({
  marginTop: '24px'
});

const BoxWheel = styled(Box)({
  boxSizing: 'border-box',
  overflow: 'hidden',
  padding: '0px',
  margin: '0px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '360px',
  backgroundColor: '#ffffff',
  borderRadius: '20.89px',
  "@media (max-width: 960px)": {
    width: "90%"
  }
});

const SelectDriveType = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'activeBTN',
})<{ activeBTN: boolean; }>(({ activeBTN }) => ({
  display: 'flex',
  padding: '20px',
  boxSizing: 'border-box',
  margin: '0px',
  backgroundColor: activeBTN ? '#ECFDF5' : '#ffffff',
}));

const SelectBoxModal = styled(Box)({
  border: '2px solid #64748B',
  borderRadius: '50%',
  width: '25px',
  height: '25px',
  marginRight: '18px',
  marginLeft: '13px',
});

const SelectNameTypography = styled(Typography)({
  fontSize: '24px',
  letterSpacing: '1px',
  color: "#3C3E49",
});

const ErrorBox = styled(Box)({
  width: '445px',
  height: '450px',
  overflow: 'hidden',
  backgroundColor: '#FEE2E2',
  padding: '25px',
  borderRadius: '24.89px',
  borderLeftStyle: 'solid',
  borderLeftColor: '#DC2626',
  borderLeftWidth: '5px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  margin: '0px',
  "@media (max-width: 960px)": {
    width: "90%",
    height: "80%",
    padding: '10px',
  }
});

const BoxCenter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ErrorImg = styled('img')({
  height: '195.93px',
  "@media (max-width: 960px)": {
    width: "90%",
    height: '180px',
  }
})

const TypographyOops = styled(Typography)({
  color: '#DC2626',
  fontSize: '20px',
  marginTop: '15px',
  "@media (max-width: 960px)": {
    fontSize: '16px',
    marginTop: '15px',
  }
})

const TypographyDouble = styled(Typography)({
  color: '#94A3B8',
  marginTop: '15px',
  fontSize: '20px',
  textAlign: 'center',
  "@media (max-width: 960px)": {
    fontSize: '16px',
    marginTop: '15px',
  }
})

const ButtonReturn = styled(Box)({
  background: '#DC2626',
  ':hover': {
    backgroundColor: '#DC2626',
    cursor:"pointer"
  },
  color: '#FFFFFF',
  width: '55%',
  height: '60px',
  borderRadius: '16px',
  marginTop: '15px',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 960px)": {
    width: '80%',
    height: '50px',
    marginTop: '20px',
  }
})

const TypographyRetry = styled(Typography)({
  fontSize: '25px',
  margin: '5px 5px 0px 0px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
    marginRight: '12px'
  }
})

const ImgRetry = styled('img')({
  width: '30px',
  height: '30px',
  margin: '0px 7px',
  "@media (max-width: 960px)": {
    width: '25px',
    height: '25px',
  }
})

const RightHalf = styled(Box)({
  width: "50%",
  height: "100%",
  textAlign: 'center',
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",
  }
})
const MainImgage = styled('img')({
  width: '100%',
  height: '85%',
  "@media (max-width: 960px)": {
    width: '100%',
  }
});

const TextBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

const TypographyImgTextOne = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
});

const TypographyImgTextTwo = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
})
// Customizable Area End
