// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
interface ApiCall {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {}
}

export interface ValidResponse {
  message: string;
}

export interface InValidResponse { error: string }

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  email: string,
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  openResetPassModal: boolean;
  responseChangePassword: boolean;
  responseError: boolean;
  passwordCriteria: {
    hasUpperCase: boolean,
    hasLowerCase: boolean,
    hasNumber: boolean,
    hasSpecialChar: boolean,
    isValidLength: boolean
  };
  showApiError: boolean;
  apiErrorMessage: string;
  btnLoader: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordConfirmApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      responseError: true,
      responseChangePassword: true,
      openResetPassModal: false,
      passwordCriteria: {
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        isValidLength: false
      },
      showApiError: false,
      apiErrorMessage: "",
      btnLoader: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.resetPasswordConfirmApiCallId) {
        this.callBackResponse(responseJson);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.handleGetEmail();
  }
  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;

        let passwordCriteria = {
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            isValidLength: password.length >= 8, 
        };
    
        this.setState({
            password,
            passwordCriteria,
        });
  };

  handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  apiCallFunction = async (data: ApiCall) => {
    const { contentType, method, endPoint, body } = data;

    const header = {
      'Content-Type': contentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  callBackResponse = (responseJSON: InValidResponse & ValidResponse) => {
    if (responseJSON.error) {
      this.handleFailure(responseJSON.error);
    } else {
      this.handleNavigateToLogin();
    }
  };

  handleSendEmail = async () => {
    this.setState({ btnLoader: true })
    let body = {
      data: {
        email:this.state.email,
        new_password: this.state.password,
        confirm_new_password: this.state.confirmPassword
      }
    };
    this.resetPasswordConfirmApiCallId = await this.apiCallFunction({
      contentType: configJSON.forgotPasswordAPiContentType,
      method: configJSON.httpPutMethod,
      body: body,
      endPoint: configJSON.reserPasswordUpdateEndpoint
    });
  };

  handleFailure = (message: string) => {
    this.setState({ showApiError: true, apiErrorMessage: message, btnLoader: false })
  }

  handleGetEmail = () => {
    const query = window.location.search;
    const email = query.substring(1);
    this.setState({ email })
  };

  handleNavigateToLogin = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
