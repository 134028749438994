// Customizable Area Start
import React from "react";
import PlanYourRideController, { Props } from "./PlanYourRideController.web";
import {map} from "../assets";
import Maps from "../../../maps/src/Maps.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class RideOptions extends PlanYourRideController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Maps navigation={undefined} id={""} />
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle={
    mapStyle:{
        height:'100%',
        width:'100%'

    }
}
// Customizable Area End