import React, { Component } from "react";
// Customizable Area Start
import { Box, Typography, Button, styled, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ridenolonger } from "./assets";
// Customizable Area End

export default class RideUnavailablePopup extends Component {
  render() {

    // Customizable Area Start
    return (
      <Dialog
        open={true}
        PaperProps={{
          style: { overflow: "hidden", borderRadius: "16px", position: "relative" },
        }}
      >
        <CloseButton >
          <CloseIcon />
        </CloseButton>
        <Container>
          <IconContainer>
            <img src={ridenolonger.default} alt="" style={{ width: "350px" }} />
          </IconContainer>
          <Title>Ride No Longer Available</Title>
          <Subtitle>
            Unfortunately, this ride has been taken by another driver.
          </Subtitle>
          <StyledButton variant="contained" >
            Ok
          </StyledButton>
          <NotificationText>
            We'll notify you when a new ride request.
          </NotificationText>
        </Container>
      </Dialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "16px",
  backgroundColor: "#FEE2E2",
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto 16px",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#D32F2F",
  marginBottom: "16px",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: "#334155",
  marginBottom: "24px",
  textAlign: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1D6FB8",
  color: "#FFF",
  padding: "8px 16px",
  width: "234px",
}));

const NotificationText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: "#F87171",
  marginTop: "12px",
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "8px",
  right: "8px",
  color: "#D32F2F",
}));
// Customizable Area End
