// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
interface ApiCall {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {}
}

export interface ValidResponse {
  message: string;
}

export interface InValidResponse { error: string }

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  showAddressForm: boolean;
  address: string;
  contactName: string;
  phoneNumber: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FavLocationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordConfirmApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      showAddressForm: false,
      address: '',
      contactName: '',
      phoneNumber: '',
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }

  // Customizable Area Start

  handleAddAddress = () => {
    this.setState({ showAddressForm: true });
  };

  handleChangeInput = (event: any) => {
    const target = event.currentTarget;
    const { name, value } = target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Customizable Area End
}
