
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    upcomingridesdata:any
    openModalIndex:number | undefined,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UpcomingRidesController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    getupcomingridesApiCallId:any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            openModalIndex:undefined,
            upcomingridesdata:[
            ],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const token = localStorage.getItem("token")
        if (token){
            this.getUpcomingRides(token)
        }
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            runEngine.debugLog("API Message Recived", message);
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (
              apiRequestCallId === this.getupcomingridesApiCallId
            ) {
              if (responseJson && responseJson.data) {
                const statuses = ["In Progress","Scheduled",];
                const updatedData = responseJson.data.map((item:any, index:number) => ({
                  ...item, 
                  attributes: {
                    ...item.attributes,
                    status: statuses[index % statuses.length]
                  },
                }));
                this.setState({ upcomingridesdata: updatedData })
              }
      
            }
           
      
          }
        // Customizable Area End
    }

    // Customizable Area Start

    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
    
        return formattedDate;
      }

      getStatusStyles = (status: any) => {
        switch (status) {
          case "In Progress":
            return {
              backgroundColor: "#FEF3C7",
              color: "#D97706"
            };
          case "Scheduled":
            return {
              backgroundColor: "#DBEAFE",
              color: "#2563EB"
            };
          default:
            return {
              backgroundColor: "#FFFFFF",  
              color: "#000000" 
            };
        }
      };
    
      formatTime = (timeString: string): string => {
        try {
          const [, timePart] = timeString.split('T');
          let [hours, minutes] = timePart.split(':');
          let numericHours = parseInt(hours, 10);
          let numericMinutes = parseInt(minutes, 10);
    
          const period = numericHours >= 12 ? 'PM' : 'AM';
          const adjustedHours = numericHours % 12 || 12;
          const adjustedMinutes = numericMinutes.toString().padStart(2, '0');
    
          return `${adjustedHours}:${adjustedMinutes} ${period}`;
        } catch (error) {
          return 'Invalid time';
        }
      };

    getUpcomingRides = (token: string) => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getupcomingridesApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getupcomingrideendpoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    // Customizable Area End
}
