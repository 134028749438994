Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Get Location";
exports.labelSorryText = "Sorry!";
exports.labelSorryBodyText = "There is no VR Centre in your city";
exports.cityListApiEndPoing = "bx_block_location/cities";
exports.updateProfileEndPoint = "bx_block_profile/profiles";
exports.validationApiMethodType = "GET";
exports.getApiMethodType = "GET";
exports.updateMethodType = "PUT";
exports.btnGetLocationTitle = "Get Location";
exports.btnDenyLocationTitle = "Do not Allow";
exports.btnSelectCenterTitle = "Select a Centre";
exports.errorTitle = "Error";

// Customizable Area Start
exports.fav = "Add a favorite Place";
exports.address = "Address";
exports.addressSmall = "address";
exports.coNumber = "Contact name";
exports.poNumber = "Phone number";
exports.save = "Save";
exports.youHaveNoAdd = "You have no saved addresses";
exports.addFavAddress = "Add favorite addresses so you can find them in just one tap";
exports.addAddress = "Add address";
exports.normal = "normal";
exports.outlined = "outlined";
exports.Optional = "Optional";
exports.contactName = "contactName";
exports.phoneNumber = "phoneNumber";
exports.contained = "contained";
exports.favAddress = "favAddress";
// Customizable Area End
