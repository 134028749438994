import React from "react";

// Customizable Area Start

import SignUpDriverStep4Controller, {
  Props
} from "./SignUpDriverStep4Controller.web";
import { driverReject, rightLogo,} from "./assets";
const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class SignUpDriverStep4 extends SignUpDriverStep4Controller {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div className="driver-app-container-reject">
      <div className="driver-top-section-reject">
        <img
          src={driverReject.default}
          alt="Main Image"
          className="driver-main-image-reject"
        />
        <img
          src={rightLogo.default}
          alt="Logo"
          className="driver-logo-image-reject"
        />
      </div>

      <div className="driver-bottom-section-reject" >
        <h1 className="driver-main-title1-reject">{configJSON.BigTitle1Reject}</h1>
        <h2 className="driver-sub-title1-reject">{configJSON.SmallTitle1Reject}</h2>
        <h2 className="driver-sub-title1-reject">{configJSON.SmallTitle2Reject}</h2>
        <h2 className="driver-sub-title2-reject">{configJSON.SmallTitle3Reject}</h2>
        <button className="driver-action-button-reject" data-testid="navigateToLandingPage2" onClick={this.navigateToLandingPage2}>{configJSON.continue} </button>
      </div>
    </div>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
 
   
  };
  // Customizable Area End
