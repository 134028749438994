import React from "react";

// Customizable Area Start

import SignUpDriverStep3Controller, {
  Props
} from "./SignUpDriverStep3Controller.web";
import { driverReview, rightLogo} from "./assets";
const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class SignUpDriverStep3 extends SignUpDriverStep3Controller {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div className="driver-app-container-review">
      <div className="driver-top-section-review">
        <img
          src={driverReview.default}
          alt="Main Image"
          className="driver-main-image-review"
        />
        <img
          src={rightLogo.default}
          alt="Logo"
          className="driver-logo-image-review"
        />
      </div>

      <div className="driver-bottom-section-review">
        <h1 className="driver-main-title-review">{configJSON.BigTitleReview}</h1>
        <h2 className="driver-sub-title1-review">{configJSON.SmallTitle1Review}</h2>
        <h2 className="driver-sub-title2-review">{configJSON.SmallTitle2Review}</h2>
        <button className="driver-action-button-review" data-testid="navigateToLandingPage1" onClick={this.navigateToLandingPage1}>{configJSON.OK}</button>
      </div> 
    </div>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
 
   
  };
  // Customizable Area End
