import React from "react";
// Customizable Area Start
import UserProfileBasicController, {
  configJSON
} from "./UserProfileBasicController";
import {
  Box,
  styled,
  Button,
  Typography,
  TextField,
  FormLabel,
  FormControl,
  Modal
} from '@mui/material';
import { deleteImgForm, warning, photoUploadIcon, photoDeleteIcon, edit } from "./assets";
import UserProfileDriver from "./UserProfileDriver.web";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const userloggedin = localStorage.getItem("role_id");
    return (
      <>
        {this.state.drivereditstep == 1 ? <Box>
          <MainContainer>
            <input
              type="file"
              ref={this.fileInputRef}
              style={{ display: "none" }}
              onChange={this.props.handleFileChange}
            />
            <FormContainer onSubmit={this.handleSubmit} data-testID={"submitID"}>
              <FormControl fullWidth >
                <MyFormLabel>{configJSON.fname}</MyFormLabel>
                <Input fullWidth variant="outlined"
                  data-testID={"inputID"}
                  name={"firstName"}
                  value={this.state.firstName}
                  onChange={this.handleChangeInput}
                  placeholder={`${configJSON.enter} ${configJSON.fname}`}
                  inputProps={{
                    minLength: 3,
                    maxLength: 50,
                  }}
                  required
                  InputProps={{
                    endAdornment: (
                      <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                    )
                  }}
                />
                {this.state.firstNameError && <PasswordMatcherror>
                  {this.state.firstNameError}
                </PasswordMatcherror>}
              </FormControl>

              <FormControl fullWidth >
                <MyFormLabel>{configJSON.lname}</MyFormLabel>
                <Input fullWidth variant="outlined" name={"lastName"}
                  value={this.state.lastName}
                  onChange={this.handleChangeInput}
                  placeholder={`${configJSON.enter} ${configJSON.lname}`}
                  inputProps={{
                    minLength: 3,
                    maxLength: 50,
                  }}
                  required
                  InputProps={{
                    endAdornment: (
                      <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                    )
                  }}
                />
                {this.state.lastNameError && <PasswordMatcherror>
                  {this.state.lastNameError}
                </PasswordMatcherror>}
              </FormControl>

              <FormControl fullWidth >
                <MyFormLabel>{configJSON.email}</MyFormLabel>
                <Input fullWidth variant="outlined" name={"email"}
                  value={this.state.email}
                  onChange={this.handleChangeInput}
                  placeholder={`${configJSON.enter} ${configJSON.email}`}
                  required
                  InputProps={{
                    readOnly:true,
                  }}
                />
                {this.state.emailError && <PasswordMatcherror>
                  {this.state.emailError}
                </PasswordMatcherror>}
              </FormControl>
              <FormControl fullWidth>
                <MyFormLabel>{configJSON.address}</MyFormLabel>
                <Input fullWidth variant="outlined"
                  value={this.state.address}
                  onChange={this.handleChangeInput}
                  name={"address"}
                  placeholder={`${configJSON.enter} ${configJSON.address}`}
                  required
                  InputProps={{
                    endAdornment: (
                      <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                    )
                  }}
                />
                {this.state.addressError && <PasswordMatcherror>
                  {this.state.addressError}
                </PasswordMatcherror>}
              </FormControl>

              <FormControl fullWidth>
                <MyFormLabel>{configJSON.phone}</MyFormLabel>
                <Input fullWidth variant="outlined"
                  name={"phoneNumber"}
                  value={this.state.phoneNumber}
                  onChange={this.handleChangeInput}
                  placeholder={`${configJSON.enter} ${configJSON.phone}`}
                  required
                  inputProps={{
                    minLength: 10,
                    maxLength: 12,
                  }}
                  InputProps={{
                    endAdornment: (
                      <img src={edit.default} style={{ width: "20px", height: "20px" }} alt="edit" />
                    )
                  }}
                />
                {this.state.phoneNumberError && <PasswordMatcherror>
                  {this.state.phoneNumberError}
                </PasswordMatcherror>}
              </FormControl>

              {userloggedin !== "driver" && <FormControl fullWidth >
                <MyFormLabel>{configJSON.requirement}</MyFormLabel>
                <Input
                  fullWidth
                  data-testID={"additonalBTNID"}
                  variant="outlined"
                  name={"requirement"}
                  onClick={this.handleOpenSelectModal}
                  placeholder={this.state.requirement}
                  aria-readonly
                  required
                  value={this.state.requirement}
                />
              </FormControl>}

              <BoxDelete>
                <StyledImage src={deleteImgForm.default} alt="Delete" />
                <DeleteTypography
                  data-testID={"deleteAccountBTNID"}
                  onClick={this.handleOpen}>
                  {configJSON.delete}
                
                </DeleteTypography>
              </BoxDelete>
            </FormContainer>
            <Box style={webstyle.drivereditbuttonboxcontainer}>
              <Drivereditbuttonbasic>
                <SaveButton type={"submit"} onClick={this.handleUpdate} data-test-id="handleupdateapi">
                  <Button style={webstyle.savetextcolor} >{configJSON.save}</Button>
                </SaveButton>
                {userloggedin == "driver" && <NextButton onClick={() => this.setState({ drivereditstep: 2 })}>
                  <Button style={webstyle.nexttextcolor} >Next</Button>
                </NextButton>}
              </Drivereditbuttonbasic>
            </Box>
          </MainContainer>

          <Modal
            open={this.props.uploadImageState}
            onClose={this.props.uploadImageFunction}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testID={"closeUploadImageModalID"}
          >
            <BoxWheel boxShadow={24}>
              <BoxUploadBox >
                <ButtonUpload onClick={this.handleAddPhotoClick} data-testID={"handleprofileupload"}>
                  <StyledImageUpload src={photoUploadIcon.default} alt="Upload" />
                  <UploadPhotoTypography data-testID={"uploadTextID"}>
                    {configJSON.uploadPhoto}
                  </UploadPhotoTypography>
                </ButtonUpload>
                <ButtonUpload onClick={()=>this.props.removePhoto()}>
                  <StyledImageDelete src={photoDeleteIcon.default} alt="Remove" />
                  <UploadPhotoTypography>
                    {configJSON.removePhoto}
                  </UploadPhotoTypography>
                </ButtonUpload>
              </BoxUploadBox>
            </BoxWheel>
          </Modal>

          <Modal
            open={this.state.openSelectModal}
            onClose={this.handleCloseSelectModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testId={'closeAdditionalRequirementModalID'}
          >
            <BoxWheel boxShadow={24}>
              <SelectDriveType data-testId={"noneSelectId"} activeBTN={this.state.requirement == configJSON.none} onClick={() => this.handleSelectRequirement(configJSON.none)}>
                <SelectBoxModal />
                <SelectNameTypography data-testId={'noneTextID'}>
                  {configJSON.none}
                </SelectNameTypography>
              </SelectDriveType>
              <SelectDriveType activeBTN={this.state.requirement == configJSON.wheelchair} onClick={() => this.handleSelectRequirement(configJSON.wheelchair)}>
                <SelectBoxModal />
                <SelectNameTypography>
                  {configJSON.wheelchair}
                </SelectNameTypography>
              </SelectDriveType>
              <SelectDriveType activeBTN={this.state.requirement == configJSON.gurney} onClick={() => this.handleSelectRequirement(configJSON.gurney)}>
                <SelectBoxModal />
                <SelectNameTypography>
                  {configJSON.gurney}
                </SelectNameTypography>
              </SelectDriveType>
            </BoxWheel>
          </Modal>

          <Modal
            open={this.state.openDeleteModal}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            data-testID={"closeDeleteAccountModalID"}
          >
            <DeleteBox>
              <BoxCenter>
                <WarningImage src={warning.default} alt={configJSON.deletetxt} />
              </BoxCenter>
              <BoxCenter>
                <TypographyDelete data-testID={"cancleTextID"}>
                  {configJSON.deleteAccount}
                </TypographyDelete>
              </BoxCenter>

              <BoxCenter>
                <TypographySure>
                  {configJSON.deleteSure}
                </TypographySure>
              </BoxCenter>

              <BoxDeleteModal>
                <BoxCenter>
                  <CancelButton onClick={this.handleClose}>
                    {configJSON.cancel}
                  </CancelButton>
                  <DeleteBTN onClick={this.handleDelete} data-testID={"deleteaccountid"}>
                    {configJSON.deletetxt}
                  </DeleteBTN>
                </BoxCenter>
              </BoxDeleteModal>
            </DeleteBox>
          </Modal>
          <Snackbar
            open={this.state.istoastopen}
            autoHideDuration={2000}
            onClose={this.handleClosetoast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert severity={this.state.error ? "error" : "success"} onClose={this.handleClosetoast}
              sx={{ backgroundColor: this.state.error ? "red" : "#4caf50", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
              {this.state.error ? this.state.errormessage : this.state.successMessage}
            </MuiAlert>
          </Snackbar>
        </Box> : <UserProfileDriver
            navigation={this.props.navigation}
            uploadImageFunction={this.props.uploadImageFunction}
            imagefile={this.props.imagefile}
            handleFileChange={this.props.handleFileChange}
            id={""}
            removePhoto={this.props.removePhoto}
            editdata={{
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              password: this.state.password,
              email: this.state.email,
              address: this.state.address,
              phonenumber: this.state.phoneNumber
            }} uploadImageState={this.props.uploadImageState} />}
      </>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

}

// Customizable Area Start
const MainContainer = styled(Box)({
  minHeight: "100vh",
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    minHeight: "auto",
  },
});

const Drivereditbuttonbasic = styled(Box)({
  width: "70%",
  display: "flex",
  flexDirection: "column" as "column",
  gap: '10px',
  alignItems: "center",
  "@media (max-width: 960px)": {
    width: "100%",
  },
});

const BoxDelete = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "16px",
})

const PasswordMatcherror = styled(Typography)({
  fontSize: "12px",
  color: "#DC2626",
  display: "flex",
  alignItems: "center",
  marginTop: "-6px"
});
const BoxUploadBox = styled(Box)({
  width: "100%",
  height: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "none",
})

const ButtonUpload = styled(Box)({
  width: "80%",
  display: "flex",
  padding: '15px 20px',
  margin: '5px auto',
  color: "#000000",
  fontSize: "43px",
  borderRadius: "10px",
  fontWeight: 600,
  ":hover": {
    backgroundColor: "#ECFDF5"
  }
});

const StyledImageUpload = styled("img")({
  width: "24px",
  height: "24px"
});

const StyledImageDelete = styled("img")({
  width: "34px",
  height: "34px"
});

const UploadPhotoTypography = styled(Typography)({
  textTransform: 'lowercase',
  fontSize: "20px",
  marginLeft: "10px",
  cursor:"pointer",
  '&::first-letter': {
    textTransform: 'uppercase',
  },
});

const BoxDeleteModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
  "@media (max-width: 960px)": {
    marginTop: "10px",
  }
});

const FormContainer = styled("form")({
  "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
    border: "none"
  },
  width: "95%",
  padding: "10px",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const Input = styled(TextField)({
  backgroundColor: '#ffffff',
  marginBottom: '16px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: "#ffffff",
  },
});

const MyFormLabel = styled(FormLabel)({
  color: '#334155',
  fontWeight: 700,
  fontSize: "14px",
  marginBottom: "5px",
  lineHeight: "22px"
});

const StyledImage = styled('img')({
  width: '24px',
  height: '24px',
  marginRight: '8px',
});

const WarningImage = styled('img')({
  width: '45px',
  height: '40px',
  marginRight: '8px',
});


const CancelButton = styled(Button)({
  width: "200px",
  height: "50px",
  marginRight: '22px',
  backgroundColor: "#E7E5E4",
  color: "#64748B",
  borderRadius: "25px",
  "@media (max-width: 960px)": {
    width: "100px",
    boxSizing: "border-box",
    margin: "0px 5px"
  }
});

const DeleteBTN = styled(Button)({
  width: "200px",
  height: "50px",
  marginRight: '22px',
  backgroundColor: "#DC2626",
  color: "#FFFFFF",
  borderRadius: "25px",
  "@media (max-width: 960px)": {
    width: "100px",
    boxSizing: "border-box",
    margin: "0px 5px"
  }
});

const DeleteTypography = styled(Typography)({
  color: '#1D6FB8',
  fontStyle: 'unset',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: "24px",
  cursor:"pointer"
})

const TypographyDelete = styled(Typography)({
  fontWeight: 600,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: "25px",
  marginTop: "16px",
  "@media (max-width: 960px)": {
    fontSize: "20px",
  }
})

const BoxCenter = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const TypographySure = styled(Typography)({
  fontSize: "25px",
  marginTop: "16px",
  marginBottom: "16px",
  textAlign: "center",
  color: "#0F172A",
  "@media (max-width: 960px)": {
    fontSize: "15px",
  }
})

const SaveButton = styled(Button)({
  background: "#1D6FB8",
  justifyContent: "center",
  width: "50%",
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  height: "56px",
  color: "black",
  "@media (max-width: 600px)": {
    width: "90%",
  },
  '&:hover': {
    backgroundColor: "#1D6FB8",
  },
});

const NextButton = styled(Box)({
  background: "",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  height: "56px",
  color: "black",
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const SelectBoxModal = styled(Box)({
  border: "2px solid #64748B",
  borderRadius: "50%",
  width: "23px",
  height: "23px",
  marginRight: "13px",
  marginLeft: "13px",
})

const SelectNameTypography = styled(Typography)({
  marginTop: '5px',
  cursor:'pointer'
});


const SelectDriveType = styled(Box, { shouldForwardProp: (prop) => prop !== 'activeBTN' })<{
  activeBTN: boolean;
}>(({ activeBTN }) => ({
  display: "flex",
  padding: "20px",
  boxSizing: "border-box",
  margin: "0px",
  backgroundColor: activeBTN ? "#87C122" : "#ffffff",
  alignItems:"center"
}));

const BoxWheel = styled(Box)({
  boxSizing: "border-box",
  overflow: "hidden",
  padding: "0px",
  margin: "0px",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "400px",
  backgroundColor: "#ffffff",
  borderRadius: '24.89px',
});

const DeleteBox = styled(Box)({
  boxSizing: "border-box",
  overflow: "hidden",
  margin: "0px",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "650px",
  backgroundColor: "#ffffff",
  borderRadius: '24.89px',
  padding: "30px 15px",
  "@media (max-width: 600px)": {
    width: "80%",
  },
});

const webstyle = {
  drivereditbuttonboxcontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "15px",
    paddingTop: "15px"
  },

  savetextcolor: {
    color: "#ECFDF5",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    textTransform: "capitalize" as "capitalize"
  },

  nexttextcolor: {
    color: "#1D6FB8",
    textTransform: "capitalize" as "capitalize",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
  },

}
// Customizable Area End