import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  onDetailsClick: () => void;
  onPaymentDetailsClick: () => void;
  setSelectedSection: (section: string) => void;
  selectedSection: string;
   // Customizable Area End
}

export interface S {
  // Customizable Area Start
  activeTab:string;
  openFilterPopup:boolean;
  selectedFilter: any;
  filteredTransactions:any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class WalletScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      activeTab:"1",
      openFilterPopup:false,
      selectedFilter: 'ALL',
      filteredTransactions: [],
    };    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  setactiveTab = (active: string) => {
    this.setState({ activeTab: active })
  }
  handleBackToMenu = () => {
    this.props.setSelectedSection("appointment"); // Update this to your initial section
  };
  openFilterPopup = () => {
    this.setState({ openFilterPopup: true })
  }


  handleClose = () => {
    this.setState({ openFilterPopup: false });
  };


  // Customizable Area End
}