// Customizable Area Start
import React from "react";
import AppointmentRiderContoller, { Props } from "./AppointmentRiderController.web";
import {
    Box,
    styled,
    Button,
    TextField,
    Typography
} from '@mui/material';
import { date, deleteicon, droplocation, edit, editicon, location, time } from "./assets";
import { configJSON } from "./AddAppointmentController";

// Customizable Area End


// Customizable Area Start
const AppointmentInputWrapper = styled(Box)({
    position: "relative",
    height: "56px",
    zIndex: 2,
    marginBottom: "10px",
    width: "745px",
    "@media (max-width: 1060px)": {
        width: "85%",
    },
    "@media (max-width: 600px)": {
        width: "100%",
    },
});

const AppointmentmanageInputGroup = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
});

const APpointmentEditcancelbox = styled(Box)({
    display: "flex",
    borderRadius: "8px",
    position: "absolute" as "absolute",
    height: "96px",
    left: "101%",
    paddingTop: "12px",
    width: "186px",
    flexDirection: "column" as "column",
    top: "0",
    background: "#FFFFFF",
    border: "1px solid #F5F5F5",
    "@media (max-width: 1280px)": {
        top: "40px",
        left: "90%",
        zIndex: "99999999"
    },
    "@media (max-width: 1180px)": {
        top: "40px",
        left: "78%",
        zIndex: "99999999"
    },
    "@media (max-width: 1060px)": {
        top: "40px",
        left: "65%",
        zIndex: "99999999"
    },
    "@media (max-width: 538px)": {
        top: "40px",
        left: "35%",
        zIndex: "99999999"
    },
});

const Appointmenteditbuttobox = styled(Box)({
    width: "70%",
    flexDirection: "column" as "column",
    gap: '10px',
    display: "flex",
    alignItems: "center",
    "@media (max-width: 960px)": {
        width: "100%",
    }
});

const AppointmentHeaderbox = styled(Box)({
    width: "745px",
    height: "46px",
    background: "#FFFFFF",
    borderRadius: "16px",
    border: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    padding: "10px 38px 0px 16px",
    "@media (max-width: 1060px)": {
        width: "85%",
    },
    "@media (max-width: 900px)": {
        padding: "10px 10px 0px 16px",
    },
    "@media (max-width: 600px)": {
        width: "100%",
        padding: "10px 56px 0px 16px",
    },
    "@media (max-width: 400px)": {
        width: "100%",
        padding: "10px 18px 0px 16px",
    },
});

const Appointmentdatacontainer = styled(Box)({
    width: "745px",
    position: "relative" as "relative",
    height: "50px",
    background: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 0px 16px",
    "@media (max-width: 1060px)": {
        width: "85%",
    },
    "@media (max-width: 600px)": {
        width: "100%",
    }
});


const AppointmentSavebutton = styled(Box)({
    background: "#1D6FB8",
    borderRadius: "8px",
    height: "56px",
    color: "black",
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
        width: "90%",
    }
});
const Nexteditbutton = styled(Box)({
    background: "",
    color: "black",
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid  #1D6FB8",
    borderRadius: "8px",
    height: "56px",
    "@media (max-width: 600px)": {
        width: "90%",
    }
});

const Statuscolorbox = styled(Box)({
    height: "26px",
    borderRadius: "40px",
    width: "15%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 1060px)": {
        width: "25%",
    },
    "@media (max-width: 500px)": {
        paddingRight: "60px",
        background: "transparent"
    }
});

const Appointmentheader = styled(Box)({
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 759px)": {
        width: "87%",
    },
    "@media (max-width: 600px)": {
        width: "100%",
    }
});


const Editbox = styled(Box)({
    width: "745px",
    height: "56px",
    background: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 0px 16px",
    "@media (max-width: 1060px)": {
        width: "85%",
    },
    "@media (max-width: 600px)": {
        width: "100%",
    }
});

const Appointmenteditbuttonboxcontainer = styled(Box)({
    width: "745px",
    justifyContent: "center",
    paddingBottom: "15px",
    display: "flex",
    paddingTop: "15px",
    "@media (max-width: 1060px)": {
        width: "85%",
    },
    "@media (max-width: 600px)": {
        width: "100%",
    }
});

const Appointmentnumber = styled(Box)({
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    color: "#000000",
    lineHeight: "18px",
    "@media (max-width: 480px)": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "5ch"
    }
});






// Customizable Area End


export default class AppointmentRider extends AppointmentRiderContoller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start
    statusText = (item: any) => {
        if (item.status == "CONFIRMED") {
            return (
                <Statuscolorbox >
                    <Typography style={webstyle.confirmedText}>{item.status}</Typography>
                </Statuscolorbox>
            )

        } else if (item.status == "DECLINED") {
            return (
                <Statuscolorbox >
                    <Typography style={webstyle.declinedtext}>{item.status}</Typography>
                </Statuscolorbox>
            )
        } else if (item.status == "PENDING") {
            return (
                <Statuscolorbox>
                    <Typography style={webstyle.pendingtext}>{item.status}</Typography>
                </Statuscolorbox>
            )
        }
    }

    // Customizable Area End


    render() {
        // Customizable Area Start
        return (
            <>

                <Box style={webstyle.riderappointmentqrapper}>
                    <Box style={webstyle.appointmentStatsboxcontainer}>
                        <AppointmentHeaderbox >
                            <Appointmentheader>
                                <Typography style={webstyle.headerText}>{configJSON.rideheader}</Typography>
                                <Typography style={webstyle.headerText}>{configJSON.appointment}</Typography>
                                <Typography style={{ ...webstyle.headerText, paddingRight: "20px" }}>{configJSON.status}</Typography>
                            </Appointmentheader>
                        </AppointmentHeaderbox>
                        {this.state.appointmentdata.map((item: any, index: any) => <Appointmentdatacontainer>
                            <Box style={webstyle.appointmentdatabox}>
                                <Appointmentnumber >{item.appoitnmentserial}</Appointmentnumber>
                                <Typography style={webstyle.appointmentidtext}>{item.appointmentid}</Typography>
                                {this.statusText(item)}
                                <img src={editicon.default} onClick={() => this.setState({ openModalIndex: index })} />
                            </Box>
                            {this.state.openModalIndex === index && (
                                <APpointmentEditcancelbox >
                                    <Box style={webstyle.editinsidebox} onClick={() => this.setState({ showeditconent: true })}>
                                        <img src={edit.default} />
                                        <Typography style={webstyle.appoitnmentedittext}>{configJSON.edittext}</Typography>
                                    </Box>
                                    <Box style={webstyle.editcancelbox} onClick={() => this.setState({ openModalIndex: undefined, showeditconent: false })}>
                                        <img src={deleteicon.default} />
                                        <Typography style={webstyle.appoitnmentedittext}>{configJSON.cancelText}</Typography>
                                    </Box>
                                </APpointmentEditcancelbox>
                            )}
                        </Appointmentdatacontainer>)}
                    </Box>

                </Box>
                {this.state.showeditconent && <>
                    <Box style={{ ...webstyle.appointmentStatsboxcontainer, paddingTop: "30px" }}>
                        <Editbox >
                            <Typography style={webstyle.editappointmenttext}>{configJSON.editappointment}</Typography>
                        </Editbox>
                        <AppointmentmanageInputGroup>
                            <AppointmentInputWrapper>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    style={{ borderRadius: "8px" }}
                                    placeholder="Date"
                                    InputLabelProps={{ shrink: true }}
                                    name="Date"
                                    InputProps={{
                                        style: {
                                            borderRadius: '8px',
                                            background: "white",
                                            paddingLeft: "30px"
                                        },
                                        inputProps: {
                                            style: {
                                                color: "black",
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                fontFamily: "Inter",

                                            },
                                        }
                                    }}
                                />
                                <img src={date.default} style={webstyle.imageinput} />
                            </AppointmentInputWrapper>

                        </AppointmentmanageInputGroup>
                        <AppointmentmanageInputGroup>
                            <AppointmentInputWrapper>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    style={{ borderRadius: "8px" }}
                                    placeholder="Time"
                                    InputLabelProps={{ shrink: true }}
                                    name="time"
                                    InputProps={{
                                        style: {
                                            paddingLeft: "30px",
                                            borderRadius: '8px',
                                            background: "white",
                                        },
                                        inputProps: {
                                            style: {
                                                fontWeight: 400,
                                                fontFamily: "Inter",
                                                color: "black",
                                                fontSize: '16px',
                                            },
                                        }
                                    }}
                                />
                                <img src={time.default} style={webstyle.imageinput} />
                            </AppointmentInputWrapper>

                        </AppointmentmanageInputGroup>
                        <AppointmentmanageInputGroup>
                            <AppointmentInputWrapper>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    style={{ borderRadius: "8px" }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Pickup Location"
                                    InputProps={{
                                        style: {
                                            borderRadius: '8px',
                                            paddingLeft: "30px",
                                            background: "white",
                                        },
                                        inputProps: {
                                            style: {
                                                fontWeight: 400,
                                                fontFamily: "Inter",
                                                color: "black",
                                                fontSize: '16px',
                                            },
                                        }
                                    }}
                                />
                                <img src={location.default} style={webstyle.imageinput} />
                            </AppointmentInputWrapper>

                        </AppointmentmanageInputGroup>
                        <AppointmentmanageInputGroup>
                            <AppointmentInputWrapper>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    style={{ borderRadius: "8px" }}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Drop-off Location"
                                    InputProps={{
                                        style: {
                                            paddingLeft: "30px",
                                            borderRadius: '8px',
                                            background: "white",
                                        },
                                        inputProps: {
                                            style: {
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                fontFamily: "Inter",
                                                color: "black",
                                            },
                                        }
                                    }}
                                />
                                <img src={droplocation.default} style={webstyle.droplocationimage} />
                            </AppointmentInputWrapper>

                        </AppointmentmanageInputGroup>

                    </Box>
                    <Appointmenteditbuttonboxcontainer>
                        <Appointmenteditbuttobox >
                            <AppointmentSavebutton >
                                <Button style={webstyle.appointmentsavetextcolor} >{configJSON.savetext}</Button>
                            </AppointmentSavebutton>
                            <Nexteditbutton >
                                <Button style={webstyle.appointmentcanceltextcolor} onClick={() => this.setState({ openModalIndex: undefined, showeditconent: false })}>{configJSON.cancelbutton}</Button>
                            </Nexteditbutton>
                        </Appointmenteditbuttobox>

                    </Appointmenteditbuttonboxcontainer>
                </>}
            </>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webstyle = {
    editinsidebox: {
        cursor: "pointer",
        display: "flex",
        paddingLeft: "12px",
        gap: "8px",
        height: "50%",
        alignItems: "center"
    },

    editcancelbox: {
        cursor: "pointer",
        display: "flex",
        paddingLeft: "12px",
        height: "50%",
        alignItems: "center",
        gap: "15px",
    },

    statuscolorBox: {
        height: "26px",
        borderRadius: "40px",
        width: "15%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    confirmedText: {
        color: "#059669",
        fontWeight: 700,
        lineHeight: "18px",
        fontFamily: "Inter",
        fontSize: "12px",
        background: "#D1FAE5",
        borderRadius: "40px",
        padding: "6px"
    },

    declinedtext: {
        color: "#DC2626",
        fontFamily: "Inter",
        background: "#FEE2E2",
        fontWeight: 700,
        lineHeight: "18px",
        borderRadius: "40px",
        padding: "6px",
        fontSize: "12px",
    },

    pendingtext: {
        color: "#D97706",
        lineHeight: "18px",
        borderRadius: "40px",
        background: "#FEF3C7",
        padding: "6px",
        fontWeight: 700,
        fontFamily: "Inter",
        fontSize: "12px",
    },

    appointmentsavetextcolor: {
        color: "#ECFDF5",
        fontWeight: 400,
        lineHeight: "28px",
        textTransform: "capitalize" as "capitalize",
        fontFamily: "Inter",
        fontSize: "20px",
    },

    appointmentcanceltextcolor: {
        color: "#1D6FB8",
        fontFamily: "Inter",
        fontSize: "20px",
        textTransform: "capitalize" as "capitalize",
        fontWeight: 400,
        lineHeight: "28px",
    },


    appointmentnumber: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "12px",
        color: "#000000",
        lineHeight: "18px",
    },

    appointmentidtext: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "12px",
        color: "#000000",
        paddingRight: "80px",
        lineHeight: "18px",
    },

    appointmentHeaderbox: {
        width: "745px",
        height: "46px",
        background: "#FFFFFF",
        borderRadius: "16px",
        border: "1px solid #E2E8F0",
        display: "flex",
        alignItems: "center",
        padding: "10px 38px 0px 16px"
    },

    appointmentdatacontainer: {
        width: "745px",
        position: "relative" as "relative",
        height: "50px",
        background: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #E2E8F0",
        display: "flex",
        alignItems: "center",
        padding: "0px 10px 0px 16px"
    },

    appointmentdatabox: {
        width: "99%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    appointmentheader: {
        width: "80%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    imageinput: {
        position: "absolute" as "absolute",
        left: "9px",
        top: "15px"
    },

    droplocationimage: {
        position: "absolute" as "absolute",
        left: "9px",
        top: "9px"
    },

    riderappointmentqrapper: {
        display: "flex",
        flexDirection: "column" as 'column',
        gap: "40px",
    },

    appoitnmentedittext: {
        fontFamily: "Inter",
        fontWeight: 400,
        color: "#0F172A",
        lineHeight: "24px",
        fontSize: "16px",
    },

    headerText: {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "14px",
        color: "#64748B",
        lineHeight: "22px",
    },

    appointmentStatsboxcontainer: {
        display: "flex",
        flexDirection: "column" as 'column',
        gap: "10px",
    },

    editappointmenttext: {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "16px",
        color: "#64748B",
        lineHeight: "24px",
    },


}
// Customizable Area End
