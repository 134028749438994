// Customizable Area Start
import React from "react";
import { configJSON, Props } from "./HelpCentreController";
import HelpCentreController from "./HelpCentreController";
import { Box, Typography, Button, styled, TextField, Slider } from "@mui/material"
import { contact, play, question, search, tutorial, tutorialvideo } from "./assets";
import HelpCentreFaq from "./HelpCentreFaq.web";
// Customizable Area End

// Customizable Area Start
const ContactusInputGroup = styled(Box)({
  display: "flex",
  gap: "20px",
  "@media (max-width: 726px)": {
    width: "100%",
  },
});

const InputWrapper = styled(Box)({
  position: "relative",
  marginBottom: "24px",
  zIndex: 2,
  "@media (max-width: 726px)": {
    width: "80%",
  },
});

const ContactusCustomLabel = styled(Typography)({
  position: "absolute",
  top: "-45px",
  backgroundColor: "white",
  padding: "0 4px",
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Inter",
  lineHeight: "28px",
  zIndex: 1,
  marginBottom: "8px",
  color: "#334155",
  background: "transparent",
  whiteSpace: "nowrap"
});

const Helpboxcontainer = styled(Typography)({
  marginTop: "50px",
  paddingBottom: "20px"
});

const Helpcenteroptionboxcontainer = styled(Box)({
  width: "70%",
  display: "flex",
  gap: "24px",
  alignItems: "center",
  "@media (max-width: 1150px)": {
    width: "80%",
  },
  "@media (max-width: 900px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    flexDirection: "column"
  },
});

const ContactelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#CBD5E1",
  gap: "7px",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const FaqhelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  alignItems: "center",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  gap: "7px",
  background: "#F59E0B",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const TutorialshelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  alignItems: "center",
  borderRadius: "16px",
  display: "flex",
  gap: "7px",
  flexDirection: "column" as "column",
  justifyContent: "center",
  background: "#BFDBFE",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const Backbutton = styled(Box)({
  background: "",
  color: "black",
  width: "50%",
  display: "flex",
  height: "56px",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid  #1D6FB8",
  borderRadius: "8px",
  "@media (max-width: 600px)": {
    width: "90%",
  }
});

const Helpcenterbuttobox = styled(Box)({
  width: "70%",
  display: "flex",
  flexDirection: "column" as "column",
  gap: '10px',
  alignItems: "center",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

// Customizable Area End

export default class HelpCentre extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  helpCenterOptions = () => {
    return (
      <Helpboxcontainer >
        <ContactusInputGroup>
          <InputWrapper>
            <ContactusCustomLabel>{configJSON.helpText}</ContactusCustomLabel>
            <TextField
              variant="outlined"
              style={{ borderRadius: "8px" }}
              placeholder="Search help"
              InputLabelProps={{ shrink: true }}
              data-test-id="contact-us-name"
              name="name"
              fullWidth
              InputProps={{
                sx: {
                  borderRadius: '8px',
                  background: "white",
                  width: "539px",
                  height: "41px",
                  paddingLeft: "30px",
                  fontFamily: "Inter",
                  fontWeight: 400,
                  lineHeight: "24px",
                  color: "#94A3B8",
                  "@media (max-width: 726px)": {
                    width: "100%",
                  },
                },
              }}
            />
            <img src={search.default} style={webstyle.searchhelpimage} />
          </InputWrapper>
          <Button style={webstyle.searchbutton}>{configJSON.searchText}</Button>
        </ContactusInputGroup>
        <Helpcenteroptionboxcontainer>
          <ContactelpcenterOptionbox onClick={() => this.handleNavigation("Contactus")} data-test-id="contactredirectid">
            <img src={contact.default} />
            <Typography style={webstyle.optionstext}>{configJSON.contactText}</Typography>
          </ContactelpcenterOptionbox>
          <FaqhelpcenterOptionbox onClick={() => this.handleNavigation("Faq")} data-test-id="getqueansid">
            <img src={question.default} />
            <Typography style={webstyle.optionstext}>{configJSON.faqText}</Typography>
          </FaqhelpcenterOptionbox>
          <TutorialshelpcenterOptionbox onClick={this.openTutorialspage} data-test-id = "opentutorialspage">
            <img src={tutorial.default} />
            <Typography style={webstyle.optionstext}>{configJSON.tutorials}</Typography>
          </TutorialshelpcenterOptionbox>
        </Helpcenteroptionboxcontainer>
      </Helpboxcontainer>
    )
  }

  helpCenterTutorials = () => {
    return (
      <Box style={webstyle.tutorialwrapper}>
        <video
          ref={this.videoRef}
          width="90%"
          height="100%"
          src={tutorialvideo.default}
          onTimeUpdate={this.handleTimeUpdate}
          onClick={this.handlePlayPause}
          data-test-id="videostartmanage"
        />

        <Box style={webstyle.videocontrolbox}>
          {!this.state.isPlaying && (
            <Box
              style={webstyle.playpausebox}
              onClick={this.handlePlayPause}
            >
              <img src={play.default} alt="Play" />
            </Box>
          )}
          <Slider
            max={this.videoRef.current?.duration || 0}
            value={this.state.currentTime}
            onChange={this.handleSeek}
            data-test-id = "handleseektime"
            sx={{
              width: '95%',
              cursor: 'pointer',
              position: "absolute",
              bottom: "10px",
              margin: '0 auto',
              height: "4px",
              color: "#87C122",
              '& .MuiSlider-rail': {
                backgroundColor: '#0F172A',
              },
            }}
          />
        </Box>
        <Box style={webstyle.buttonboxcontainer}>
          <Helpcenterbuttobox >
            <Backbutton onClick={() => this.setState({ showpage: "helpcenter" })}>
              <Button style={webstyle.backtextcolor} >{configJSON.back}</Button>
            </Backbutton>
          </Helpcenterbuttobox>

        </Box>
      </Box>
    );
  }

  helpCenterpage = () => {
    switch (this.state.showpage) {
      case "helpcenter":
        return this.helpCenterOptions()
      case "helpcenterfaq":
        return <HelpCentreFaq navigation={undefined} id={""} helpcenterQA={this.state.helpCentreQA} />
      case "tutorials":
        return this.helpCenterTutorials()
      default:
        break;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.helpCenterpage()}
      </>


      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  helpcenterOptionbox: {
    width: "200px",
    height: "200px",
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },

  optionstext: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    color: "#0F172A",
    cursor: "pointer"
  },

  searchbutton: {
    width: "110px",
    height: "41px",
    background: "#1D6FB8",
    color: "white",
    textTransform: 'capitalize' as 'capitalize',
    borderRadius: "8px"
  },

  searchhelpimage: {
    position: "absolute" as "absolute",
    left: "9px",
    top: "9px",
    width: "24px",
    height: "24px"
  },

  helpcenteroptionboxcontainer: {
    width: "70%",
    display: "flex",
    gap: "24px",
    alignItems: "center"
  },

  buttonboxcontainer: {
    justifyContent: "center",
    paddingBottom: "15px",
    width: "100%",
    display: "flex",
  },

  backtextcolor: {
    color: "#1D6FB8",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    textTransform: "capitalize" as "capitalize",
  },

  tutorialwrapper: {
    textAlign: 'center' as 'center',
    height: "450px",
    background: "white",
    borderRadius: "16px",
    width: "96%",
    position: "relative" as 'relative'
  },

  videocontrolbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
  },

  playpausebox: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    background: "black",
    position: "absolute" as 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: "40%",
    cursor: "pointer",
  }
}
// Customizable Area End