import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { removeStorageData } from "../../../framework/src/Utilities";
import creditCardType from "credit-card-type";
import * as Yup from 'yup'
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  redirectedit?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedSection: string;
  isSidebarOpen: boolean;
  openmodal: boolean;
  passwordCriteria: {
    hasUpperCase: boolean,
    hasLowerCase: boolean,
    hasNumber: boolean,
    isValidLength: boolean
    hasSpecialChar: boolean
  };
  password: string
  passwordMatcherror: boolean
  confirmPassword: string
  usertype: string
  pagetoshow: string
  cardnumber: string
  expirrydate: string
  prevpass: string
  oldpassunmatch: boolean
  cardHolderName: string
  isToastOpen: boolean
  cardList: any
  userdetails: any
  isValid: any
  cardType: any
  cardHolderNameError: boolean
  expirrydateError: boolean
  paypalidError: boolean
  paypalid: string
  succesMessage: string
  prevpassvisible: boolean
  newpassvisibile: boolean
  confirmpassvisibile: boolean
  isInputFocused:boolean
  isCardinputfocused:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePassowrdApiCallId: any
  addCardApiCallId: any
  getCardlistApiCallId: any
  userprofiledetailsApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.changePassowrdApiCallId = ""
    this.addCardApiCallId = ""
    this.getCardlistApiCallId = ""
    this.userprofiledetailsApiCallId = ""
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedSection: 'Settings',
      isSidebarOpen: true,
      openmodal: false,
      passwordCriteria: {
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        isValidLength: false,
        hasSpecialChar: false
      },
      password: "",
      passwordMatcherror: false,
      confirmPassword: "",
      usertype: "",
      pagetoshow: "",
      cardnumber: "",
      expirrydate: "",
      prevpass: "",
      oldpassunmatch: false,
      cardHolderName: "",
      isToastOpen: false,
      cardList: [],
      userdetails: {},
      isValid: null,
      cardType: '',
      cardHolderNameError: false,
      expirrydateError: false,
      paypalidError: false,
      paypalid: "",
      succesMessage: "",
      prevpassvisible: false,
      newpassvisibile: false,
      confirmpassvisibile: false,
      isInputFocused:false,
      isCardinputfocused:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.getCardlistApiCallId) {
        this.setState({ cardList: responseJson })
      }

      if (apiRequestCallId === this.addCardApiCallId) {
        this.setState({ cardHolderName: "", paypalid: "", expirrydate: "", cardnumber: "", pagetoshow: 'carddetails', isValid: null, succesMessage: "Card Added succesfully", isToastOpen: true })
        this.Getcardlist()
      }
      if (apiRequestCallId === this.userprofiledetailsApiCallId) {
        this.setState({ userdetails: responseJson?.data })
      }

      if (responseJson && responseJson.message) {
        if (apiRequestCallId === this.changePassowrdApiCallId) {
          this.setState({ pagetoshow: "", isToastOpen: true, prevpass: "", confirmPassword: "", password: "", succesMessage: "Password Changed Successfully" })
        }

      }
      else if (responseJson && responseJson.error) {
        this.setState({ oldpassunmatch: true })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const userlogged = localStorage.getItem("role_id");
    this.getUserprofiledetails()
    if (userlogged) {
      this.setState({ usertype: userlogged })
    }
    // Customizable Area End
  }
  handleSectionChange = (section: string) => {
    const isMobile = window.innerWidth <= 960;
    this.setState({ selectedSection: section, isSidebarOpen: isMobile ? false : this.state.isSidebarOpen });
  };

  handleLogout = async () => {
    localStorage.removeItem("rideid")
    localStorage.removeItem("pagetoshow")
    localStorage.removeItem("redirectfrom")
    await removeStorageData("role_id");
    await removeStorageData("token");
    await removeStorageData("userId");
    await removeStorageData("role")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  openCardlistpage = () => {
    this.setState({ pagetoshow: "carddetails" })
    this.Getcardlist()
  }

  changePassword = () => {
    const token = localStorage.getItem("token");
    if (token) {
      let data = {
        data: {
          old_password: this.state.prevpass,
          new_password: this.state.password,
          confirm_new_password: this.state.confirmPassword
        },
      };

      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changePassowrdApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_settings/settings/reset_password`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  };

  handletoastclose = () => {
    this.setState({ isToastOpen: false })
  }

  validationSchema = Yup.object().shape({

    paypalid: Yup.string()
      .required('PayPal Id / Apple pay is required'),
    expirrydate: Yup.string()
      .required("Expiry date is required")
      .matches(/^\d{2}\/\d{2}$/, "Expiry date must be in MM/YY format")
      .test("is-valid-month", "Month must be between 01 and 12", (value) => this.validateMonth(value))
      .test("is-future-date", "Expiry date must be in the future", (value) => this.validate(value)),
    cardHolderName: Yup.string()
      .required('Card holder name is required')
      .min(4, 'Card holder name must be at least 4 digits')
      .max(15, 'Card holder name cannot be more than 30 digits')
      .matches(/^[A-Za-z\s]+$/, 'Card holder name must contain only letters'),

  });

  validateMonth(value: any) {
    if (value) {
      const month = parseInt(value.split("/")[0], 10);
      return month >= 1 && month <= 12;
    }
    return true;
  }

  validate(value: any) {
    if (value) {
      const [monthStr, yearStr] = value.split("/");
      const month = parseInt(monthStr, 10);
      const inputYear = 2000 + parseInt(yearStr, 10);

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      return (
        inputYear > currentYear ||
        (inputYear === currentYear && month >= currentMonth)
      );
    }
    return true;
    // }
  }

  handleSubmit = async () => {
    try {
      await this.validationSchema.validate(this.state, { abortEarly: false });
      this.setState({isInputFocused:false,isCardinputfocused:false})
      const clearedErrors = Object.keys(this.state)
        .filter((key) => key.endsWith("Error"))
        .reduce((acc, key) => ({ ...acc, [key]: "" }), {});
      this.setState((prevState) => ({
        ...prevState,
        ...clearedErrors,
      }));
      if (this.state.isValid) {
        this.addCreditcard()
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const seenFields = new Set<string>();
        const newErrors = error.inner.reduce((acc, err) => {
          if (err.path && !seenFields.has(err.path)) {
            seenFields.add(err.path);
            acc[`${err.path}Error`] = err.message;
          }
          return acc;
        }, {} as { [key: string]: string });
        this.setState((prevState) => ({
          ...prevState,
          ...newErrors,
          isValid: false,
        }));
      }
    }
  }

  backtoCarddetails = () =>{
    
    this.setState({ pagetoshow: "carddetails",
      cardHolderName:"",
      cardnumber:"",
      expirrydate:"",
      paypalid:"",
      paypalidError:false,
      isValid:null,
      cardHolderNameError:false,
      expirrydateError:false })
  }

  addCreditcard = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const formData = new FormData();
      formData.append('card_number', this.state.cardnumber);
      formData.append('expiry_date', this.state.expirrydate);
      formData.append("cardholder_name", this.state.cardHolderName)
      formData.append('credit_card_type', this.state.cardType)
      formData.append("paypal_id", this.state.paypalid)
      const header = {
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCardApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_settings/credit_cards`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postmethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleCardNumberChange = (e: any) => {
    const input = e.target.value.replace(/\D/g, '');
    this.setState({ cardnumber: input })
    const detectedTypes = creditCardType(input);
    if (detectedTypes.length > 0) {
      const type = detectedTypes[0];
      this.setState({ cardType: type.niceType })
      const isLengthValid = type.lengths.includes(input.length);
      const isValidLuhn = isLengthValid && this.validateLuhn(input);
      this.setState({ isValid: isValidLuhn })

    } else {
      this.setState({ cardType: "unknown" })
      this.setState({ isValid: false })
    }
  };
  validateLuhn = (number: any) => {
    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number[i], 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };

  Getcardlist = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCardlistApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_settings/credit_cards`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getmethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleToggleSidebar = () => {
    this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
  };
  handleLogoutmodal = () => {
    this.setState({ openmodal: true })
  };

  handleClosemodal = () => {
    this.setState({ openmodal: false })
  };

  redirectUser = (route: string) => {
    localStorage.setItem("redirectfrom", "settings")
    if (this.state.usertype == "driver" || route == "TermsConditions") {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else {
      this.setState({ pagetoshow: "contactus" })
    }
  }


  handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedValue = value.replace(/\D/g, "");

    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: name == "expirrydate" ? formattedValue : value,
      [`${name}Error`]: false,
    }));

    try {
      await this.validationSchema.validateAt(name, { ...this.state, [name]: name == "expirrydate" ? formattedValue : value });
      this.setState((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        this.setState((prevState) => ({
          ...prevState,
          [`${name}Error`]: error.message,
        }));
      }
    }

  };

  handleDriverPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;

    let passwordCriteria = {
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      isValidLength: password.length >= 8,
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };

    this.setState({
      password,
      passwordCriteria,
    });
  };

  handleConfirmpassowrd = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: event.target.value, passwordMatcherror: false })
  };

  getUserprofiledetails = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.userprofiledetailsApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/profile/show`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  };

  HandleSavepassword = () => {
    const { password, confirmPassword, passwordCriteria } = this.state;
    const isPasswordValid = Object.values(passwordCriteria).every(Boolean);
    const doPasswordsMatch = password === confirmPassword;
    if (isPasswordValid && doPasswordsMatch) {
      this.changePassword()
    } else {
      this.setState({ passwordMatcherror: true })
    }
  }

  Handleprevpass = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ prevpass: event.target.value, oldpassunmatch: false })

  }
  // Customizable Area End
}
