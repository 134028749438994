import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    Grid,
    styled,
    TextField,
    CircularProgress
} from '@mui/material';

import TwoFactorAuthenticationController, {
    configJSON,
} from "./TwoFactorAuthenticationController.web";
import { arrow, logo, sendOtp, retry, errorImage, codeExpireImage, allSetImage } from "./assets";
// Customizable Area End

export default class TwoFactorAuthentication extends TwoFactorAuthenticationController {
    // Customizable Area Start
    stepOne = () => {
        return (
            <>
                <LogoImage src={logo} alt="logo" />
                <TextBox >
                    <TypographyAccount variant="h6">
                        {configJSON.protectAccount}
                    </TypographyAccount>
                    <TypographySecure variant="body1">
                        {configJSON.secureAccount}
                    </TypographySecure>
                    <Typographyverify variant="body1">
                        {configJSON.verificationCode}
                    </Typographyverify>
                </TextBox>

                <ButtonVerify
                    data-testId={"stepOneId"}
                    onClick={this.handleStepOne}
                    variant="contained"
                    color="primary"
                >
                    {this.state.loader ? <CircularProgress /> : configJSON.countinueButton}
                </ButtonVerify>
            </>
        );
    }

    stepTwo = () => {
        return (
            <>
                <SecondContainer>
                    <LogoImageTwo src={sendOtp} alt={configJSON.logo} />

                    <TypographyFourDigit data-testId={"pleaseEnterID"}>{configJSON.pleaseEnter}</TypographyFourDigit>

                    <TypographyEnter>{configJSON.enterCode}</TypographyEnter>

                    <Grid container spacing={3} justifyContent="center" >
                        {this.state.otp.map((value, index) => (
                            <Grid item key={index}>
                                <TextFieldOtp
                                    data-testId={"otpTextMapId"}
                                    id={`otp-${index}`}
                                    type="text"
                                    inputProps={{ maxLength: 1 }}
                                    value={value}
                                    onChange={this.handleChange(index)}
                                    onKeyDown={this.handleKeyDown(index)}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Verify
                        data-testId={"verifyBtnId"}
                        onClick={this.handleVerify}
                        variant="contained"
                        color="primary"
                    >
                        {this.state.loader ? <CircularProgress /> : configJSON.Verify}
                    </Verify>

                    <TypographyReceive>
                        {configJSON.didntRec}{' '}
                        <ButtonResend onClick={this.handleStepOne}>{configJSON.resend}</ButtonResend>
                    </TypographyReceive>
                </SecondContainer>

                <TypographyResend>
                    <ImgRetry src={retry} alt={configJSON.retry} />
                    {configJSON.otp} {this.formatTime(this.state.secondsRemaining)}
                </TypographyResend>
            </>
        );
    }

    stepThree = () => {
        return (
            <>
                <LogoImageError src={errorImage} alt="logo" />
                <TextBox>
                    <TypographyAccountError variant="h6" data-testId={"errorTextId"}>
                        {configJSON.incorrectCode}
                    </TypographyAccountError>
                    <TypographySecureincorrect variant="body1">
                        {configJSON.verificationCodeIncorrect}
                    </TypographySecureincorrect>
                    <TypographySecureincorrect variant="body1">
                        {configJSON.pleasTry}
                    </TypographySecureincorrect>
                </TextBox>
                <Grid item xs={12}>
                    <Tryagain
                        onClick={this.handleTryAgain}
                        variant="contained"
                        color="primary"
                    >
                        {this.state.loader ? <CircularProgress /> : configJSON.tryButton}
                    </Tryagain>
                </Grid>
            </>
        );
    }

    stepFour = () => {
        return (
            <>
                <LogoImageFour src={codeExpireImage} alt={configJSON.logo} />
                <TextBox >
                    <TypographyAccountError variant="h6">
                        {configJSON.codeExpired}
                    </TypographyAccountError>
                    <TypographySecureExpire variant="body1">
                        {configJSON.expiredWorry}
                    </TypographySecureExpire>
                </TextBox>
                <Grid item xs={12}>
                    <Tryagain
                        variant="contained"
                        color="primary"
                        onClick={this.handleStepOne}
                    >
                        {this.state.loader ? <CircularProgress /> : configJSON.reSendCodelink}
                    </Tryagain>
                </Grid>
                <TypographyReceiveFour variant="h6">
                    {configJSON.doNotReceiveCode} &ensp;
                    <ResendLink onClick={this.handleStepOne}>{configJSON.resendCode}</ResendLink>
                </TypographyReceiveFour>

            </>
        );
    }

    stepFive = () => {
        return (
            <>
                <LogoImageFive src={allSetImage} alt={configJSON.logo} />
                <TypographyAccountSuccess variant="h6" data-testId={"awesomeTextId"}>
                    {configJSON.awesomeYou}
                </TypographyAccountSuccess>
                <TypographySecureSuccess variant="body1">
                    {configJSON.youraccount}
                    <Span>
                        {configJSON.thankstaking}
                    </Span>
                </TypographySecureSuccess>
                <Tryagain
                    data-testId={"finalStepId"}
                    variant="contained"
                    color="primary"
                    onClick={this.hadleFinalStep}
                >
                    {configJSON.countinueButton}
                </Tryagain>
            </>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Containerone>
                {this.state.currentStep !== 1 && this.state.currentStep !== 5 && (
                    <GridArrow item xs={12} sm="auto">
                        <img
                            src={arrow}
                            data-testId={"backBtnId"}
                            onClick={this.handleGoBack}
                            alt="arrow"
                        />
                    </GridArrow>
                )}

                <OverlayText>
                    <Typographyheader>
                        {configJSON.factorAtuhtnecation}
                    </Typographyheader>
                </OverlayText>

                {this.state.currentStep === 1 && this.stepOne()}
                {this.state.currentStep === 2 && this.stepTwo()}
                {this.state.currentStep === 3 && this.stepThree()}
                {this.state.currentStep === 4 && this.stepFour()}
                {this.state.currentStep === 5 && this.stepFive()}

            </Containerone>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const Containerone = styled(Box)({
    width: "100vw",
    height: "100vh",
    textAlign: "center",
    "@media (max-width: 960px)": {
        height: "auto",
        padding: "12px",
        marginBottom: "16px",
    },
});

const GridArrow = styled(Grid)({
    "& img": {
        height: "50px",
        position: 'absolute',
        top: '10px',
        left: '25px',
        fontSize: '18px',
        "@media (max-width: 600px)": {
            height: "40px",
            top: '8px',
        },
    },
});

const OverlayText = styled(Box)({
    marginTop: '18px',
    "@media (max-width: 960px)": {
        padding: "5px 10px",
        fontSize: "1rem",
    },
});

const Typographyheader = styled(Typography)({
    color:"#1E293B",
    fontSize: "30px",
    fontFamily:"Inter",
    fontWeight:400,
    "@media (max-width: 960px)": {
        fontSize: "18px",
        marginTop: '35px',
    },
});

const LogoImage = styled("img")({
    height: "50%",
    width: "45%",
    marginTop: "5px",
    "@media (max-width: 960px)": {
        height: "180px",
    },
});

const TextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
});

const TypographyAccount = styled(Typography)({
    fontSize: "30px",
    marginTop: '17px',
    fontWeight: 700,
    color: "#87C122",
    fontFamily:"Inter",
    "@media (max-width: 960px)": {
        fontSize: "20px",
    },
});

const TypographySecure = styled(Typography)({
    fontSize: "24px",
    marginTop: "8px",
    fontWeight: 400,
    fontFamily:"Inter",
    color:"#0F172A",
    "@media (max-width: 960px)": {
        fontSize: "14px",
        marginTop: "8px",
    },
});

const TypographySecureincorrect = styled(Typography)({
    fontSize: "30px",
    marginTop: "8px",
    fontWeight: 400,
    fontFamily:"Inter",
    color:"#0F172A",
    "@media (max-width: 960px)": {
        fontSize: "14px",
        marginTop: "8px",
    },
});


const Typographyverify = styled(Typography)({
    width: '36%',
    display: 'flex',
    textAlign: 'center',
    marginTop: "21px",
    fontSize: "18px",
    fontFamily:"Inter",
    color:"#334155",
    fontWeight: 700,
    "@media (max-width: 960px)": {
        width: '100%',
        fontSize: "12px",
        marginTop: "16px",
    },
});

const ButtonContinue = styled(Button)({
    width: "20%",
    margin: "30px 30px",
    textTransform: 'none',
    backgroundColor: "#1D6FB8",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
        backgroundColor: "#1A5E8C",
    },
    "@media (max-width: 960px)": {
        width: "100%",
        marginTop: "16px",
        padding: "12px",
        fontSize: "16px",
    },
});

const SecondContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    gap:'10px'
});

const LogoImageTwo = styled('img')({
    height: "340px",
    width: "22%",
    margin: '20px 20px',
    '@media (max-width: 960px)': {
        height: '180px',
    },
});

const TypographyFourDigit = styled(Typography)({
    width: '30%',
    marginBottom: '10px',
    fontSize: '24px',
    fontFamily:"Inter",
    fontWeight:400,
    color:"#000000",
    '@media (max-width: 960px)': {
        width: '100%'
    },
});

const TypographyReceive = styled(Typography)({
    marginTop: '8px',
    fontWeight: 400,
    fontFamily:"Inter",
    fontSize:"16px",
    color:"#000000",
    '@media (max-width: 600px)': {
        marginTop: "30px",
    },

});

const ImgRetry = styled('img')({
    height: '18px',
    margin: '0 3 3 0',
});

const ButtonResend = styled(Button)({
    color: '#1D6FB8',
    fontWeight: 700,
    textTransform: 'none',
    fontFamily:"Inter",
    fontSize:"16px",
    paddingBottom:"10px"
});

const TypographyEnter = styled(Typography)({
    marginRight: '330px',
    fontSize: '16px',
    marginBottom: '6px',
    fontWeight: 400,
    fontFamily:"Inter",
    color:"#334155",
    '@media (max-width: 500px)': {
     marginRight:"0"
    },
});

const TextFieldOtp = styled(TextField)({
    background:"white",
    width:"95px",
    height:"66px",
    '& input': {
        textAlign: 'center',
        fontSize: '24px',
    },
});
const ButtonVerify = styled(Button)({
    marginTop: '18px',
    width: '250px',
    height: '60px',
    color: '#ffffff',
    backgroundColor: '#1D6FB8',
    cursor: "pointer",
    textTransform: 'none',
    borderRadius: '10px',
    fontSize: "24px",
    fontWeight: 700,
    fontFamily:"Inter",
    "&:hover": {
        backgroundColor: "#1A5E8C",
    },
    '@media (max-width: 600px)': {
        marginTop: "50px",
    },
});

const Verify = styled(Button)({
    marginTop: '18px',
    width: '250px',
    height: '60px',
    color: '#ffffff',
    backgroundColor: '#1D6FB8',
    cursor: "pointer",
    textTransform: 'none',
    borderRadius: '10px',
    fontSize: "20px",
    fontWeight: 400,
    fontFamily:"Inter",
    "&:hover": {
        backgroundColor: "#1A5E8C",
    },
    '@media (max-width: 600px)': {
        marginTop: "50px",
    },
});


const Tryagain = styled(Button)({
    marginTop: '18px',
    width: '250px',
    fontSize: "18px",
    fontWeight: 700,
    fontFamily:"Inter",
    height: '60px',
    color: '#ffffff',
    backgroundColor: '#1D6FB8',
    cursor: "pointer",
    textTransform: 'none',
    borderRadius: '10px',
    "&:hover": {
        backgroundColor: "#1A5E8C",
    },
    '@media (max-width: 600px)': {
        marginTop: "50px",
    },
});



const TypographyResend = styled(Typography)({
    color: '#1D6FB8',
    fontSize: '12px',
    display: 'flex',
    fontFamily:"Inter",
    alignItems: 'center',
    justifyContent: 'center',
});

const LogoImageError = styled("img")({
    height: "55%",
    width: "32%",
    marginTop: "5px",
    "@media (max-width: 960px)": {
        height: '180px',
        marginTop: "15px"
    },
});

const TypographyAccountError = styled(Typography)({
    fontSize: "36px",
    marginTop: '22px',
    fontWeight: 700,
    color: "#DC2626",
    fontFamily:"Inter",
    "@media (max-width: 960px)": {
        fontSize: "23px",
    },
});

const ResendLink = styled(Typography)({
    cursor: 'pointer',
    fontSize: "16px",
    fontWeight: 700,
    fontFamily:"Inter",
    display: 'inline',
    color: '#1D6FB8',
    "@media (max-width: 960px)": {
        fontSize: "11px",
    },
});

const TypographyAccountExpire = styled(Typography)({
    fontSize: "22px",
    marginTop: '17px',
    fontWeight: 700,
    color: "#DC2626",
    "@media (max-width: 960px)": {
        marginTop: '10px',
        fontSize: "23px"
    },
});

const LogoImageFour = styled("img")({
    height: "50%",
    width: "40%",
    marginTop: "5px",
    "@media (max-width: 960px)": {
        marginTop: "15px",
        height: '140px'
    },
});
const TypographySecureExpire = styled(Typography)({
    fontSize: "30px",
    marginTop: "15px",
    fontWeight: 400,
    fontFamily:"Inter",
    width: '25%',
    "@media (max-width: 960px)": {
        width: '72%',
        fontSize: "13px",
        marginTop: "12px",
    },
});

const TypographyReceiveFour = styled(Typography)({
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily:"Inter",
    "@media (max-width: 960px)": {
        fontSize: "13px",
        marginTop: "10px"
    },
});

const LogoImageFive = styled('img')({
    width: "42%",
    height: "55%",
    marginTop: '35px',
    '@media (max-width: 600px)': {
        marginTop: '25px',
        height: '150px'
    },
});

const ButtonContinueSuccess = styled(Button)({
    width: "13%",
    marginTop: "3rem",
    textTransform: 'none',
    backgroundColor: "#1D6FB8",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    "&:hover": {
        backgroundColor: "#1A5E8C",
    },

    "@media (max-width: 960px)": {
        marginTop: "20px",
        width: "55%",
        fontSize: "14px",
    },
});

const TypographyAccountSuccess = styled(Typography)({
    fontSize: "36px",
    marginTop: '17px',
    fontWeight: 700,
    fontFamily:"Inter",
    color: "#87C122",
    "@media (max-width: 960px)": {
        fontSize: "21px",
        marginTop: '18px',
    },
});

const TypographySecureSuccess = styled(Typography)({
    fontSize: "30px",
    marginTop: "8px",
    fontWeight: 400,
    fontFamily:"Inter",
    "@media (max-width: 960px)": {
        fontSize: "17px",
        margin: '8 0 12 0',
    },
});

const Span = styled('div')({
    fontSize: '24px',
    fontWeight: 400,
    fontFamily:"Inter",
    color:"#1E293B",
    "@media (max-width: 960px)": {
        fontSize: '13px',
    }
});

// Customizable Area End
