export const insertPhoto = require("../assets/insert_photo.png");
export const Add = require("../assets/Add.png");
export const attachFile = require("../assets/attachFile.png");
export const mic = require("../assets/mic.png");
export const send = require("../assets/send.png");
export const smileface = require("../assets/smileface.png");
export const User1 = require("../assets/User1.png");
export const User2 = require("../assets/User2.png");
export const back = require("../assets/back.png");

