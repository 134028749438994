import React from 'react';
// Customizable Area Start
import { Box, Button, Typography, styled, Dialog, DialogActions, DialogContent, } from "@mui/material";
import { avatard, star, taskcard } from "./assets";
// Customizable Area End

export default class DriverIncomingJobRequest extends React.Component {

  // Customizable Area Start
  // Customizable Area End

  state = {
    open: true,
  };


  render() {
    // Customizable Area Start
    return (
      <>
        <Dialog
          open={true}
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius: '9.1px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              minWidth: '447px',
              minHeight: '247px',
              margin: 'auto',
              borderLeft: 'solid 4px #87C122',
            },
          }}
        >
          <DialogContent>
            <CardContainer>
              <Header>
                <Avatar>
                  <img src={avatard.default} alt="" />
                </Avatar>
                <Box>
                  <Name>Jane Deo</Name>
                  <Box display="flex" alignItems="center">
                    <Rating><img src={star.default} alt="" /></Rating>
                    <RatingNumber>4.8</RatingNumber>
                    <Price>$25</Price>
                  </Box>
                </Box>
              </Header>
              <Details>
                <Box display='flex'> <Box> <img src={taskcard.default} alt="" style={{ margin: '0', width: '30px', height: '80px' }} /></Box>
                  <Box> <LocationWrapper>
                    <Box>
                      <Location><strong>Pick Up From:</strong></Location>
                      <Address>Maple Street, Springfield</Address>
                    </Box>
                    <DateTime>
                      <Typography>August 4</Typography>
                      <Typography>9:40 Pm</Typography>
                    </DateTime>
                  </LocationWrapper>

                    <LocationWrapper>
                      <Box>
                        <Location><strong>Drop Off At:</strong></Location>
                        <Address>Avenue, Lincoln Park, Chicago</Address>
                      </Box>
                      <DateTime>
                        <Typography>August 4</Typography>
                        <Typography>10:30 Pm</Typography>
                      </DateTime>
                    </LocationWrapper>
                  </Box>
                </Box>
              </Details>
            </CardContainer>
          </DialogContent>
          <StyledDialogActions>
            <StyledButton variant="outlined" color="error" >
              Reject
            </StyledButton>
            <StyledButton variant="contained" color="primary" >
              Accept
            </StyledButton>
          </StyledDialogActions>

        </Dialog>
      </>
      // Customizable Area End

    );
  }
}

// Customizable Area Start
const CardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  borderBottom: '1px solid #E2E8F0'
}));

const Avatar = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: '#ccc',
  marginRight: '12px',
}));

const Name = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '18px',
  marginBottom: '8px',
}));

const Rating = styled(Typography)(({ theme }) => ({
  marginRight: '4px',
}));

const RatingNumber = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#000', // Black color for rating number
}));

const Price = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  marginLeft: 'auto',
  color: '#000',
}));

const Details = styled(Box)(({ theme }) => ({
  margin: '16px 0',
  borderBottom: '1px solid #E2E8F0',
}));

const LocationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start', // Aligns content at the top
  marginBottom: '8px',

}));
const Location = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 'bold',
  marginBottom: '4px', // Adds spacing between location title and address
}));

const Address = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: '#94A3B8',
}));

const DateTime = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  fontSize: '8px',
  color: '#94A3B8',
  lineHeight: '1',
  marginLeft: '20px'
}));

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'flex-start',
  padding: '16px',
  gap: '8px'
});

const StyledButton = styled(Button)({
  height: '46px',
  width: '155px',
  borderRadius: '9px'

});
// Customizable Area End
