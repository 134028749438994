// Customizable Area Start
import React from "react";
import ReviewsController, { configJSON, Props } from "./ReviewsController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Box,
  Button,
  Typography,
  styled,
  TextField,
  IconButton
} from "@mui/material";
import { reviewdriver } from "./assets";
import ReviewsRider from "./ReviewsRider.web";
// Customizable Area End
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Customizable Area Start
const Reviewdriverbox = styled(Box)({
  flexDirection: "column" as "column",
  justifyContent: "center",
  width: "655px",
  display: "flex",
  gap: "20px",
  "@media (max-width: 680px)": {
    width: "90%",
  },
})

const InputWrapper = styled(Box)({
  position: "relative",
  marginBottom: "24px",
  width: "100%",
  zIndex: 2,
});

const DriverReviewCustomLabel = styled(Typography)({
  position: "absolute",
  top: "-30px",
  backgroundColor: "white",
  padding: "0 4px",
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "Inter",
  lineHeight: "22px",
  zIndex: 1,
  marginBottom: "8px",
  color: "#334155",
  background: "transparent"
});

const RateYourRiderText = styled(Box)({
  fontWeight: 400,
  lineHeight: "40px",
  color: "#1E293B",
  fontFamily: "Inter",
  fontSize: "30px",
  whiteSpace: "nowrap",
  "@media (max-width: 500px)": {
    fontSize: "25px",
  },
  "@media (max-width: 430px)": {
    fontSize: "20px",
  },
  "@media (max-width: 450px)": {
    fontSize: "18px",
  },
  "@media (max-width: 350px)": {
    fontSize: "16px",
  },
})


const Driverreviewimage = styled(Box)({
  width: "75%",
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 518px)": {
    width: "100%",
  },
})

const DriverReviewInputGroup = styled(Box)({
  display: "flex",
  gap: "20px",
  justifyContent: "space-between",
});

const Needhelptext = styled(Box)({
  fontFamily: "Inter",
  lineHeight: "40px",
  color: "#1D6FB8",
  fontSize: "30px",
  fontWeight: 400,
  "@media (max-width: 500px)": {
    fontSize: "27px",
  },
  "@media (max-width: 430px)": {
    fontSize: "22px",
  },
  "@media (max-width: 450px)": {
    fontSize: "25px",
  },
  "@media (max-width: 380px)": {
    fontSize: "20px",
  },
  "@media (max-width: 350px)": {
    fontSize: "16px",
  },
});

const Reachouttext = styled(Box)({
  fontSize: "30px",
  fontWeight: 400,
  marginTop: "-22px",
  fontFamily: "Inter",
  lineHeight: "40px",
  color: "#1D6FB8",
  "@media (max-width: 500px)": {
    fontSize: "27px",
  },
  "@media (max-width: 430px)": {
    fontSize: "22px",
  },
  "@media (max-width: 380px)": {
    fontSize: "20px",
  },
  "@media (max-width: 450px)": {
    fontSize: "25px",
  },
  "@media (max-width: 350px)": {
    fontSize: "16px",
  },
});


// Customizable Area End

export default class Reviews extends ReviewsController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    // Customizable Area Start
    const userloggedin = localStorage.getItem("role_id");
    // console.log(userloggedin,)
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {userloggedin == "rider" ? <ReviewsRider navigation={this.props.navigation} id={""} /> : <Box>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box style={webstyle.reviewdrivercontainer}>
            <Box style={webstyle.reviewdrivertextbox}>
              <RateYourRiderText>{configJSON.headertext}</RateYourRiderText>
            </Box>
            <Reviewdriverbox >
              <Box style={webstyle.reviewimagebox}>
                <Driverreviewimage>
                  <img src={reviewdriver.default} style={{ width: "100%" }} />
                </Driverreviewimage>
                <Box display="flex">
                  {[1, 2, 3, 4, 5].map((starValue) => (
                    <IconButton
                      key={starValue}
                      onClick={() => this.setState({ rating: starValue })}
                    >
                      {starValue <= (this.state.rating) ? (
                        <StarIcon style={webstyle.selectedstarrating} />
                      ) : (
                        <StarBorderIcon style={webstyle.nonselectedstarrating} />
                      )}
                    </IconButton>
                  ))}
                </Box>
                <Needhelptext >{configJSON.improveriding}</Needhelptext>
                <Reachouttext >{configJSON.experiencetext}</Reachouttext>
              </Box>
              <Box>
              </Box>

              <DriverReviewInputGroup>
                <InputWrapper>
                  <DriverReviewCustomLabel>{configJSON.commentstext}</DriverReviewCustomLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Share a comment to help us improve your experiences."
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    name="Message"
                    onChange={(e) => this.setState({ description: e.target.value })}
                    multiline
                    minRows={5}
                    InputProps={{
                      style: {
                        borderRadius: '8px',
                        background: "white",
                      },
                      inputProps: {
                        style: {
                          color: "#0F172A",
                          fontSize: '14px',
                          fontWeight: 400,
                        },
                      }
                    }}
                    sx={{
                      "& .MuiInputBase-input::placeholder": {
                        color: "#94A3B8", fontFamily: "Inter", fontSize: "16px", fontWeight: 400
                      },
                    }}
                  />
                </InputWrapper>
              </DriverReviewInputGroup>
              <Box style={webstyle.DriverReviewbuttonboxcontainer}>
                <Box style={webstyle.driverreviewbuttonbox} >
                  <Box style={webstyle.submitbuttonbox} onClick={this.handleSubmit} data-test-id = "submitreviewdriver">
                    <Button style={webstyle.submittextcolor} >{configJSON.submittext}</Button>
                  </Box>
                  <Box style={webstyle.cancelbuttonbox} onClick={this.cancelButton} data-test-id="canceldriverreview">
                    <Button style={webstyle.canceltextcolor}>{configJSON.canceltext}</Button>
                  </Box>
                </Box>
              </Box>
            </Reviewdriverbox>
          </Box>
        </Box>}
        <Snackbar
          open={this.state.isToastOpen}
          autoHideDuration={2000}
          onClose={this.handletoastclose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert severity={this.state.error ? "error" : "success"} onClose={this.handletoastclose}
            sx={{ backgroundColor: this.state.error ? "red" : "#4caf50", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            {this.state.error ? this.state.errorMessage : this.state.succesMessage}
          </MuiAlert>
        </Snackbar>
      </>

      // Customizable Area End

    );
  }
}

// Customizable Area Start

const webstyle = {

  reviewdrivercontainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    flexDirection: "column" as "column",
    justifyContent: "center",
  },

  DriverReviewbuttonboxcontainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },

  selectedstarrating: {
    color: '#87C122',
    width: "45.64px",
    height: "45.64px"
  },
  nonselectedstarrating: {
    color: '#0F172A',
    width: "45.64px",
    height: "45.64px"
  },


  driverreviewbuttonbox: {
    width: "70%",
    display: "flex",
    flexDirection: "column" as "column",
    gap: '10px'
  },

  reviewimagebox: {
    display: "flex",
    marginTop: "10px",
    gap: "20px",
    flexDirection: "column" as "column",
    alignItems: 'center'
  },

  reviewdrivertextbox: {
    textAlign: "center" as "center",
    borderRadius: "5px",
    height: "40px",
  },

  submitbuttonbox: {
    background: "#1D6FB8",
    justifyContent: "center",
    color: "black",
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    height: "56px",
  },

  cancelbuttonbox: {
    background: "",
    color: "black",
    width: "100%", justifyContent: "center",
    alignItems: "center",
    border: "1px solid  #1D6FB8",
    borderRadius: "8px",
    height: "56px",
    display: "flex",
  },

  submittextcolor: {
    color: "#ECFDF5",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    textTransform: "capitalize" as "capitalize"
  },


  canceltextcolor: {
    color: "#1D6FB8",
    textTransform: "capitalize" as "capitalize",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
  },

}
// Customizable Area End