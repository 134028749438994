import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  activeTab: string;
  filterDialogOpen: boolean;
  selectedFilters: any;
  filteredRides: any;
  rides: any;
  anchorEl: any;
  scheduledridedata: any;
  token:string;
  historydata:any;
  openEditDialog:boolean;
  rideId:any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TripHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  scheduledRideApiCallId = ""
  triphistoryApiCallid = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      activeTab: "1",
      filterDialogOpen: false,
      selectedFilters: ["All"],
      filteredRides: [

      ],
      rides: [
        { date: "Oct 5,", time: "06:30 AM", from: "742 Evergreen Terrace", to: "1600 Amphitheatre Parkway", price: "$25", status: "In Progress" },
        { date: "Oct 5,", time: "07:30 AM", from: "123 Main St", to: "456 Elm St", price: "$30", status: "Cancelled" },
        { date: "Oct 6,", time: "08:00 AM", from: "789 Oak Ave", to: "321 Maple Rd", price: "$40", status: "Completed" },
        { date: "Oct 7,", time: "09:15 AM", from: "10 Pine Blvd", to: "20 Birch Ct", price: "$20", status: "Scheduled" },
      ],
      anchorEl: null,
      scheduledridedata: [],
      historydata:[],
      token:"",
      openEditDialog:false,
      rideId:null,
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        apiRequestCallId === this.scheduledRideApiCallId
      ) {
        if (responseJson && responseJson.data) {
          const statuses = ["In Progress","Scheduled",];
          const updatedData = responseJson.data.map((item:any, index:number) => ({
            ...item, 
            attributes: {
              ...item.attributes,
              status: statuses[index % statuses.length]
            },
          }));
          this.setState({ scheduledridedata: updatedData })
          console.log("kavita",this.state.scheduledridedata)
        }

      }
      if (
        apiRequestCallId === this.  triphistoryApiCallid
      ) {
        if (responseJson && responseJson.data) {
          const statuses = ["In Progress", "Completed", "Scheduled","Cancelled" ];
          const updatedData = responseJson.data.map((item:any, index:number) => ({
            ...item, 
            attributes: {
              ...item.attributes,
              status: statuses[index % statuses.length]
            },
          }));
          this.setState({ historydata: updatedData,filteredRides:updatedData })
         
        }

      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const token = localStorage.getItem("token")
    if (token) {
      this.getSchedulredRideApi(token)
      this.setState({token})
    }
  }


  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  }

  formatTime = (timeString: string): string => {
    try {
      const [, timePart] = timeString.split('T');
      let [hours, minutes] = timePart.split(':');
      let numericHours = parseInt(hours, 10);
      let numericMinutes = parseInt(minutes, 10);

      const period = numericHours >= 12 ? 'PM' : 'AM';
      const adjustedHours = numericHours % 12 || 12;
      const adjustedMinutes = numericMinutes.toString().padStart(2, '0');

      return `${adjustedHours}:${adjustedMinutes} ${period}`;
    } catch (error) {
      return 'Invalid time';
    }
  };

  setactiveTab = (active: string) => {
    this.setState({ activeTab: active })
  }
  navigateToScheduleRide = () => {
    localStorage.removeItem("pagetoshow")
    localStorage.removeItem("rideid")
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectTemplates");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  
  navigateOrderSummary = (rideid:string) =>{
    localStorage.setItem("pagetoshow","ordersummary")
    localStorage.setItem("rideid",rideid)
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectTemplates");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }

  getStatusStyles = (status: any) => {
    switch (status) {
      case "In Progress":
        return {
          backgroundColor: "#FEF3C7",
          color: "#D97706"
        };
      case "Scheduled":
        return {
          backgroundColor: "#DBEAFE",
          color: "#2563EB"
        };
      case "Completed":
        return {
          backgroundColor: "#D1F7C4",  // Light green
          color: "#006400"  // Dark green
        };
      case "Cancelled":
        return {
          backgroundColor: "pink",  // Light green
          color: "red"  // Dark green
        };
      default:
        return {
          backgroundColor: "#FFFFFF",  // Default to white if status is unknown
          color: "#000000"  // Default to black text
        };
    }
  };
  toggleFilterDialog = () => {
    this.setState((prevState) => ({
      filterDialogOpen: !prevState.filterDialogOpen,
    }));
  };

  navigateToRating = (status: string,id:any) => {
    if (status == "Completed") {
      const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
      navigate.addData(getName(MessageEnum.NavigationTargetMessage), "Reviews");
      navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigate);
    }
    if (status == "In Progress") {
     this.setState({openEditDialog:true})
     this.setState({rideId:id})
    }
    if (status == "Scheduled") {
      this.setState({openEditDialog:true})
      this.setState({rideId:id})

     }
  }


  handleFilterChange = (filter: string) => {
    this.setState(
      (prevState) => {
        const selectedFilters =
          filter === "All"
            ? ["All"]
            : prevState.selectedFilters.includes(filter)
              ? prevState.selectedFilters.filter((f: any) => f !== filter)
              : [...prevState.selectedFilters.filter((f: any) => f !== "All"), filter];

        return {
          selectedFilters: selectedFilters.length ? selectedFilters : ["All"],
        };
      },
      () => this.applyFilters()
    );
  };

  applyFilters = () => {
    const { selectedFilters } = this.state;
    const filteredRides =
      selectedFilters.includes("All") || selectedFilters.length === 0
        ? this.state.historydata
        : this.state.historydata.filter((ride: any) => selectedFilters.includes(ride.attributes.status));
      
    this.setState({ filteredRides });
  };
  toggleFilterPopover = (event: any) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget, // Toggle popover visibility
    });
  };

  getSchedulredRideApi = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scheduledRideApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getscheduledrideendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTripHistory = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.triphistoryApiCallid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.triphistoryapiendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  onClose = () => {
    this.setState({ openEditDialog: false });
    this.setState({ rideId: null });
  }
  onEdit = () => {
    
    const msg: Message = new Message(
      getName(MessageEnum.SignUpDriverNextDataPassMessage)
    );
    msg.addData(getName(MessageEnum.SignUpDriverNextData), {
     data:this.state.rideId
    });
    this.send(msg);

    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectTemplates");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  onCancel = () => {
    this.setState({ openEditDialog: false });
    this.setState({ rideId: null });
  }
  // Customizable Area End
}