import React from "react";

// Customizable Area Start

import PaymentMethodController, {
  Props
} from "./PaymentMethodController.web";
import {
  Box,
  styled,
  Button,
Typography,IconButton,List,ListItemIcon,ListItem,  Snackbar,
} from '@mui/material';
import { coin, plusicon, expiryicon,accountholderpng } from "./assets";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config");
import MuiAlert from '@mui/material/Alert';
import "../../email-account-registration/src/EmailAccountRegistratin.css"
// Customizable Area End

export default class PaymentMethod extends PaymentMethodController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <AddCardDetailsWrapper>
       <Box style={webstyle.addpaymentfirstbox}>
          <Box style={{
            paddingLeft: "16px",
          }}>
            <Typography style={webstyle.addpaymenttext}>{configJSON.addmethod}</Typography>
          </Box>
        </Box>
        <AddcardWrapperdetails>
          <Addcardalldetailsbox>
            <Box style={webstyle.cardnumberbox}>
              <Box style={webstyle.paypalbox}>
                {!this.state.isInputFocused && <img src={plusicon.default} data-test-id="ispaypalfocused" onClick={() => this.setState({ isInputFocused: true })}/>}
                <PaymentInput
                  type="text"
                  placeholder="PayPal Id / Apple pay"
                  style={webstyle.paypalidtext}
                  value={this.state.paypalid}
                  name="paypalid"
                  data-test-id="paypalidcheck"
                  onChange={this.handleChange}
                  onFocus={() => this.setState({ isInputFocused: true })}
                  onBlur={() => this.setState({ isInputFocused: false })}
                />
                <div style={{ position: "absolute", top: "60px", left: '2px' }}>
                  {this.state.paypalidError && <Iderror>{this.state.paypalidError}</Iderror>}
                </div>
              </Box>
            </Box>
            <Box style={webstyle.cardnumberbox}>
              <Box style={webstyle.cardnumberinnexbox}>
              {!this.state.isCardinputfocused && <img src={plusicon.default} data-test-id="iscardfocused" onClick={() => this.setState({ isCardinputfocused: true })} /> }
                <PaymentInput
                  type="text"
                  placeholder="Card number"
                  style={webstyle.paypalidtext}
                  value={this.state.cardnumber}
                  data-test-id='cardcheckid'
                  onFocus={() => this.setState({ isCardinputfocused: true })}
                  onBlur={() => this.setState({ isCardinputfocused: false })}
                  onChange={this.handleCardNumberChange}
                />
                <div style={{ position: "absolute", top: "92px", left: '2px' }}>
                  {this.state.isValid === null ? null : this.state.isValid ? (
                    <p style={{ color: 'green', }}>Valid Card Number</p>
                  ) : (
                    <p style={{ color: 'red' }}>Invalid Card Number</p>
                  )}
                </div>
              </Box>

            </Box>
            <Expirycardnamebox >
              <Paymentinnerchildbox >
                <img src={expiryicon.default} />
                <PaymentInput
                  type="text"
                  placeholder="Expiry date"
                  style={webstyle.paypalidtext}
                  value={this.state.expirrydate}
                  name="expirrydate"
                  onChange={this.handleChange}
                  data-test-id="handleexpirydateid"
                />
                <div>
                  {this.state.expirrydateError && <p style={{ color: 'red', position: "absolute", left: "2%", top: "95px" }}>{this.state.expirrydateError}</p>}
                </div>
              </Paymentinnerchildbox>
              <Paymentinnerchildbox >
                <img src={accountholderpng.default} />
                <PaymentInput
                  type="text"
                  placeholder="Card holder name"
                  style={webstyle.paypalidtext}
                  value={this.state.cardHolderName}
                  name="cardHolderName"
                  onChange={this.handleChange}
                />
                <div>
                  {this.state.cardHolderNameError && <p style={{ color: 'red', position: "absolute", left: "2%", top: "95px" }}>{this.state.cardHolderNameError}</p>}
                </div>
              </Paymentinnerchildbox>
            </Expirycardnamebox>
          </Addcardalldetailsbox>
          <Box style={webstyle.coinimage}>
            <img src={coin.default} />
          </Box>
        </AddcardWrapperdetails>

        <Box style={{ ...webstyle.drivereditbuttonboxcontainer, width: "85%" }}>
          <Passowrdbuttobox >
            <Saveeditbutton onClick={this.handleSubmit} data-test-id="addnewcardid">
              <Button style={webstyle.savetextcolor} >{configJSON.save}</Button>
            </Saveeditbutton>
            <Cardetailsprev onClick={this.goBack} data-test-id="Back">
              <Button style={webstyle.nexttextcolor} >{configJSON.back}</Button>
            </Cardetailsprev>
          </Passowrdbuttobox>
        </Box>
      <Snackbar
          open={this.state.isToastOpenCard}
          autoHideDuration={2000}
          onClose={this.handleToastCloseCard}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastCloseCard} severity="success"
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            Payment Method updated sucessfully
          </MuiAlert>
        </Snackbar>
      </AddCardDetailsWrapper>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  
  const PaymentInput = styled("input")({
    padding: "0px",
    "::placeholder": {
      color: "#0F172A",
      opacity: 0.5,
    },
  });
  const AddcardWrapperdetails = styled(Typography)({
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 960px)": {
      flexDirection: "column"
    }
  });
 
  const Passowrdbuttobox = styled(Box)({
    width: "70%",
    display: "flex",
    gap: '10px',
    flexDirection: "column" as "column",
    alignItems: "center",
    "@media (max-width: 960px)": {
      width: "100%",
    }
  });
  
  const Saveeditbutton = styled(Box)({
    background: "#1D6FB8",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    height: "56px",
    color: "black",
    width: "50%",
    display: "flex",
    "@media (max-width: 600px)": {
      width: "90%",
    }
  });
  
  const Cardetailsprev = styled(Box)({
    color: "black",
    width: "50%",
    display: "flex",
    border: "1px solid  #1D6FB8",
    borderRadius: "8px",
    height: "56px",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      width: "90%",
    }
  });
  
  const Iderror = styled("p")({
    color: 'red',
    whiteSpace: "nowrap",
    "@media (max-width: 414px)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "200px"
    },
  })

  const Expirycardnamebox = styled(Typography)({
    height: "87px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      fontSize: "22px",
      flexDirection: "column" as "column",
      height: "auto",
      gap: "40px"
    },
  });
  
  const Paymentinnerchildbox = styled(Typography)({
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    background: "white",
    borderRadius: "16px",
    height: "100%",
    position: "relative" as "relative",
    "@media (max-width: 600px)": {
      width: "100%",
      height: "87px"
    },
  });
  
  const Addcardalldetailsbox = styled(Typography)({
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    width: "60%",
    "@media (max-width: 960px)": {
      width: "auto"
    }
  });
  const AddCardDetailsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '35px',
    width: '100vw',
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Adjust width for small screens
      padding: '20px', // Optional: reduce padding on smaller screens
    },
  }));
  const webstyle = {
    addcarddetailswrapper: {
      display: "flex",
      flexDirection: "column" as 'column',
      gap: "20px",
      padding: '35px',
      width: '100vw',
      // boxSizing: 'border-box',
      // position: 'relative',
      // overflow: 'hidden',
    },
  
    addpaymentfirstbox: {
      width: "100%",
      background: "white",
      borderRadius: "16px",
      height: "78px",
      display: "flex",
      alignItems: "center",
    },
  
    coinimage: {
      display: "flex",
      justifyContent: "center"
    },
  
    onecarddetail: {
      width: "66.67px",
      height: "48px",
      borderRadius: "10px",
      border: "1px solid #6C7278",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  
    riderContactusMainBox: {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "25px"
    },
  
    prefilledcarddetails: {
      paddingLeft: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px"
    },
  
    paypalbox: {
      paddingLeft: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      position: "relative" as "relative"
    },
  
    cardnumberbox: {
      background: "white",
      borderRadius: "16px",
      height: "87px",
      display: "flex",
      alignItems: "center",
    },
  
    paymentinnerchildbox: {
      paddingLeft: "16px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      background: "white",
      borderRadius: "16px",
      height: "100%",
      position: "relative" as "relative"
    },
  
    cardnumberinnexbox: {
      paddingLeft: "16px",
      display: "flex",
      borderRadius: "16px",
      height: "100%",
      position: "relative" as "relative",
      alignItems: "center",
      gap: "10px",
      background: "white",
    },
  
    dropdetails: {
      backgroundColor: '#ECFDF5',
      padding: "0px"
    },
  
    addpaymenttext: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      color: "#0F172A"
    },
  
    prefilledcardbox: {
      width: "95%",
      background: "white",
      borderRadius: "16px",
      height: "78px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer"
    },
  
    expirycardnamebox: {
      height: "87px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      justifyContent: "space-between"
    },
  
    contacttext: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#0F172A",
    },
  
    paypalidtext: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#334155",
      flex: 1,
      height: "100%",
      borderRadius: "8px",
      border: "none",
      outline: "none",
      width: "20%"
    },
  
    emailtext: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#334155",
    },
  
    sureitytext: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "25.56px",
      lineHeight: "38.33px",
      color: "#0F172A"
    },
  
    cancelButtonbox: {
      width: "45%",
      height: "72px",
      background: "#E7E5E4",
      borderRadius: "25.56px",
      display: "flex",
      justifyContent: "center",
      alignItems: 'center'
    },
  
    logoutbuttonbox: {
      width: "45%",
      background: "#DC2626",
      height: "72px",
      borderRadius: "25.56px",
      display: "flex",
      justifyContent: "center",
      alignItems: 'center'
    },
  
    buttonbox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    },
  
    canceltext: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "25.56px",
      lineHeight: "38.33px",
      color: "#64748B",
      cursor: "pointer"
    },
  
    drivereditbuttonboxcontainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      paddingBottom: "15px",
      paddingTop: "55px"
    },
  
    savetextcolor: {
      color: "#ECFDF5",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
      cursor: "pointer",
      textTransform: "capitalize" as "capitalize"
    },
  
    endingvisatext: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "19.36px",
    },
  
    expirestext: {
      color: "#334155",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "19.36px",
    },
  
    nexttextcolor: {
      color: "#1D6FB8",
      textTransform: "capitalize" as "capitalize",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
      cursor: "pointer"
    },
  
    password: {
      position: "absolute" as 'absolute',
      right: "19",
      top: "40px",
      height: "30px",
      width: "30px"
    },
  
    confirmpassimg: {
      position: "absolute" as 'absolute',
      right: "19",
      top: "54px",
      height: "30px",
      width: "30px"
    }
  }
  // Customizable Area End
