export const imgBell = require("../assets/bell.png");
export const rightSideImage = require("../assets/rightSideImage.png");
export const back_Icon = require("../assets/back_Icon.png");
export const notification = require("../assets/notification.png");
export const star = require("../assets/star.png");
export const swap = require("../assets/swap.png");
export const update = require("../assets/update.png");
export const congrats = require("../assets/congrats.png");
export const location = require("../assets/location.png");
export const amico = require("../assets/amico.png");
export const mainLogo = require("../assets/mainLogo.png");
export const starYellow = require("../assets/starYellow.png");

