Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "helpcentre";
exports.labelBodyText = "helpcentre Body";

exports.helpcentreAPIEndPoint = "/bx_block_help_centre/question_answer";
exports.httpGetType = "GET";
exports.helpcentreSearch = "bx_block_help_centre/faq_search?query";

exports.btnExampleTitle = "CLICK ME";
exports.anyquestionText = "Any Question?"
exports.askAnythingtext = "You can ask anything you want to know about E-Ryde"
exports.searchText = "Search"
exports.helpText = "How can we help you? "
exports.contactText = "Contact E-Ryde"
exports.faqText = "FAQ"
exports.tutorials = "Tutorials"
exports.back = 'Back'
// Customizable Area End
