// @ts-nocheck 
import React from "react";

// Customizable Area Start
// @ts-nocheck 
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Typography,
  Button,
  Box,
  styled, Table, TableBody, TableRow, TableCell, IconButton, Dialog,
  DialogContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import TripHistoryController, {
  Props
} from "./TripHistoryController.web";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TypeNull,deleteImg,edit } from './assets';
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
const rides = [
  {
    date: "Oct 5,",
    time: "06:30 AM",
    from: "742 Evergreen Terrace, Springfield, IL 62704 dfgfdfg dfghnbvcdcfvbvcdsxdfvbvcsdfvvcxszxd",
    to: " 1600 Amphitheatre Parkway, Mountain View, CA 94043sdfghgfdxsdcfvgbhnjmhbvcxxdcfvbn cfghndxf fghhg dcfgsxfvd dfghfdfgs dfgfdsdfgfs",
    price: "$25",
    status: "In Progress"
  },
  {
    date: "Oct 5,",
    time: "06:30 AM",
    from: "742 Evergreen Terrace, Springfield, IL 62704",
    to: " 1600 Amphitheatre Parkway, Mountain View, CA 94043",
    price: "$25",
    status: "Scheduled"
  },
];

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: "0 18px", 
  width: "100%",
});


const RowContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "20px",
  borderLeft: "4px solid #87C122", 
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  width: "100%"
});
const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: "30px",
  whiteSpace: "nowrap", 

});
const OrderSummaryText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color:'#0F172A',
  fontWeight: 600,
  marginBottom: '5px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

const TripDetailsText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  marginBottom: '10px',
  color:'#475569',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom:'20px',
  backgroundColor: '#1D6FB8',
  fontSize:'16px',
  width:'30%',
  fontWeight:700,
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  color: 'white',
  textTransform: 'none',
  borderRadius:'10px',
  '&:hover': {
    backgroundColor: '#303f9f',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', 
    width:'80%',
  },
}));
const StyledImage = styled('img')(({ theme }) => ({
  width: '30%',
  height: 'auto',
  objectFit: 'cover',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '70%', // Adjust for smaller screens
  },
  [theme.breakpoints.down('xs')]: {
    width: '70%', // Further adjust for extra small screens
  },
}));
const StyledContainer = styled('div')(({ theme }) => ({
  overflowX: 'hidden',
  width: '95%',
  margin: '0 auto', // Center the container horizontally
  [theme.breakpoints.down('sm')]: {
    width: '100%', // Full width on smaller screens
  },
}));
const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "16px", // Apply border radius to the dialog
  },
});
const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "15vw",
  maxWidth: "500px",
  minWidth: "250px",
  textAlign: "center",
  boxSizing: "border-box",
  padding: "0", 
});

const StyledButton2 = styled(Button)({
  display: "flex",
  alignItems: "center",
  width: "100%", // Full width for better click area
  justifyContent: "flex-start", // Align icon and text to the left
  padding: "20px",
  fontSize: "18px",
  fontWeight: 400,
  paddingLeft:'10%',
  textTransform: "none",
  color:'#0F172A'
});
// Customizable Area End

export default class TripHistory extends TripHistoryController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
  buttonTabs = () => {
    const { filteredRides } = this.state;
    const { filterDialogOpen, selectedFilters, anchorEl } = this.state;
    const filterOptions = ["All", "Cancelled", "In Progress", "Completed"];
    return (
      <>
        <Tabbox >
          <Buttonbox >
            <Singlebuttonbox >
              <Button style={this.state.activeTab == '1' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("1")} data-test-id="Button1">
                {configJSON.Scheduled}  </Button>
            </Singlebuttonbox>
            <Singlebuttonbox>
              <Button
                style={this.state.activeTab == '2' ? webstyle.activeButoon : webstyle.notactiveButton}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between', // Ensures the content is spaced out
                  alignItems: 'center' // Vertically centers the content
                }}
              >
                <span
                  data-test-id="Button2"
                  onClick={() => {
                    this.setactiveTab("2")
                    this.getTripHistory(this.state.token)
                  }}
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    flexGrow: 1, // This ensures that the text takes the remaining space
                  }}
                >
                  {configJSON.History}
                </span>
                <IconButton
                  onClick={this.toggleFilterDialog}
                  style={{
                    // position: 'relative',
                    // right: '0', // Position the icon to the far right
                    // top: '50%',
                    // transform: 'translateY(-50%)', // Vertically center the icon
                  }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </Button>
            </Singlebuttonbox>

          </Buttonbox>
        </Tabbox>
      </>

    )
  }
  screentab = () => {
    switch (this.state.activeTab) {
      case '1':
        return this.renderScheduledTab();

      case '2':
        return this.renderHistoryTab();

      default:
        return this.renderScheduledTab();
    }
  }
  renderHistoryTab = () => {
    const { filteredRides } = this.state;
    const { filterDialogOpen, selectedFilters, anchorEl } = this.state;
    const filterOptions = ["All", "Cancelled", "In Progress", "Completed"];
    return (
      <Box sx={{ overflowX: "auto", width: "100%" }}>

        <Dialog open={filterDialogOpen} onClose={this.toggleFilterDialog} PaperProps={{
          sx: {
            width: '20%',
            padding: '10px',
            borderRadius: '30px',
            scrollbarWidth: 'none', msOverflowStyle: 'none',
            '@media (max-width: 600px)': {
              padding: '15px',
              width: '90%',
              borderRadius: '10px',
            }
          }
        }} >
          <DialogContent>
            {filterOptions.map((filter,index) => (
              <FormControlLabel
                key={filter}
                control={
                  <Checkbox
                    checked={selectedFilters.includes(filter)}
                    onChange={() => this.handleFilterChange(filter)}
                    style={{
                      color: selectedFilters.includes(filter) ? "#87C122" : "",
                      borderRadius: "10px", // Green color when checked
                    }}

                    data-test-id={`dilogcontent-${index}`}
                  />
                }
                label={filter}
                style={{ display: "block", marginBottom: "10px" }} // Ensures the checkboxes are stacked vertically
              />
            ))}
          </DialogContent>
        </Dialog>

        <Box sx={{ overflowX: "auto", width: "100%" }}>
          {filteredRides.length > 0 ? (
            // Main Table for displaying filtered rides
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%", // Adjust the height as per your needs
              }}
            >
              <Box sx={{ overflowX: "auto", width: "100%" }}>
                <StyledTable sx={{ width: "100%", minWidth: 600 }}>
                  <TableBody>
                    {filteredRides.map((ride: any, index: any) => (
                      <TableRow key={index} sx={{ borderBottom: "none", padding: 0, margin: 0 }}>
                        <StyledTableCell colSpan={4} sx={{ padding: 0 }}>
                          <RowContainer
                            sx={{
                              display: "flex",
                              padding: "15px",
                              width: "100%",
                              alignItems: "flex-start",
                            }}
                          >
                            {/* Ride Date and Time */}
                            <Box sx={{ flex: "1 1 100px" }}>
                              <Typography sx={webstyle.typographyStyle}>
                                {this.formatDate(ride.attributes.date)} {this.formatTime(ride.attributes.time)}
                              </Typography>
                            </Box>

                            {/* From Address */}
                            <Box sx={{ flex: "1 1 150px", paddingRight: '15px', paddingLeft: '15px' }}>
                              <Typography sx={webstyle.typographyStyle}>From:</Typography>
                              <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                                {ride.attributes.start}
                              </Typography>
                            </Box>

                            {/* To Address */}
                            <Box sx={{ flex: "1 1 150px", paddingRight: '15px' }}>
                              <Typography variant="body1" fontWeight="bold" sx={webstyle.typographyStyle}>
                                To:
                              </Typography>
                              <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                                {ride.attributes.destination}
                              </Typography>
                            </Box>

                            {/* Price */}
                            <Box sx={{ flex: "1 1 80px" }}>
                              <Typography variant="body1" fontWeight="bold" sx={webstyle.typographyStyle}>
                                Price:
                              </Typography>
                              <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                                ${ride.attributes.price}
                              </Typography>
                            </Box>

                            {/* More Options and Status */}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent:
                                  ride.attributes.status === "in progress" || ride.attributes.status === "scheduled" ? "space-between" : "center",
                                height: "100%",
                                alignItems: "center",
                              }}
                            >
                              <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <IconButton onClick={() => this.navigateToRating(ride.attributes.status,ride.id)} data-test-id = "movetoreviewid">
                                  <MoreVertIcon />
                                </IconButton>
                              </Box>
                              <Typography
                                variant="caption"
                                sx={{
                                  padding: "2px 2px",
                                  marginTop:
                                    ride.attributes.status === "in progress" || ride.attributes.status === "scheduled" ? "auto" : 1,
                                  backgroundColor: this.getStatusStyles(ride.attributes.status).backgroundColor,
                                  color: this.getStatusStyles(ride.attributes.status).color,
                                  borderRadius: "8px",
                                  fontSize: "0.75rem",
                                }}
                              >
                                {ride.attributes.status}
                              </Typography>
                            </Box>
                          </RowContainer>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </Box>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <OrderSummaryText>{configJSON.ScheduleRide}</OrderSummaryText>
                <TripDetailsText>{configJSON.prioritize}</TripDetailsText>
              </div>
              <StyledButton variant="contained" onClick={this.navigateToScheduleRide} >{configJSON.Schedule}</StyledButton>
            </Box>
          ) : (
            // Fallback UI when filteredRides is null or empty
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%", // Adjust the height as per your needs
              }}
            >
              <StyledImage
                src={TypeNull.default} // Provide the image URL
                alt="No Rides"
              />

              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <OrderSummaryText>{configJSON.ScheduleRide}</OrderSummaryText>
                <TripDetailsText>{configJSON.prioritize}</TripDetailsText>
              </div>
              <StyledButton variant="contained" onClick={this.navigateToScheduleRide}>{configJSON.Schedule}</StyledButton>

            </Box>
          )}
        </Box>
      </Box>
    );
  };
  renderScheduledTab = () => {

    return (
      <Box sx={{ overflowX: "auto", width: "100%" }}>
        <StyledTable sx={{ width: "100%", minWidth: 600 }}>
          <TableBody>
            {this.state.scheduledridedata.length > 0 ? <>
              {this.state.scheduledridedata.map((ride: any, index: number) => (
                <TableRow key={index} sx={{
                  borderBottom: "none", padding: 0, margin: 0,
                }}>
                  <StyledTableCell colSpan={4} sx={{ padding: 0 }}>
                    <RowContainer
                    data-test-id = {`ordersumarynavigate-${index}`}
                      onClick={() => this.navigateOrderSummary(ride.id)}
                      sx={{
                        display: "flex",
                        padding: "15px",
                        width: "100%",
                        alignItems: "flex-start",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                        }, // Align other content at the top
                      }}
                    >
                      <Box sx={{ flex: "1 1 100px" }}>
                        <Typography sx={webstyle.typographyStyle}>
                          {this.formatDate(ride.attributes.date)} {this.formatTime(ride.attributes.time)}
                        </Typography>

                      </Box>

                      <Box sx={{ flex: "1 1 150px", paddingLeft: '15px', paddingRight: '15px', }}>
                        <Typography sx={webstyle.typographyStyle}>
                          {configJSON.From}
                        </Typography>
                        <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>{ride.attributes.start}</Typography>
                      </Box>

                      {/* To Address */}
                      <Box sx={{ flex: "1 1 150px", paddingRight: '15px', }}>
                        <Typography variant="body1" fontWeight="bold" sx={webstyle.typographyStyle}>
                          {configJSON.To}
                        </Typography>
                        <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>{ride.attributes.destination}</Typography>
                      </Box>

                      {/* Price */}
                      <Box sx={{ flex: "1 1 80px" }}>
                        <Typography variant="body1" fontWeight="bold" sx={webstyle.typographyStyle}>
                          {configJSON.Price}
                        </Typography>
                        <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>${ride.attributes.price}</Typography>
                      </Box>

                      {/* Three Dots Icon and Status */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: ride.attributes.status === "In Progress" || ride.attributes.status === "Scheduled" ? "space-between" : "center",
                          height: "100%", // Make full height to align top and bottom
                          alignItems: "center",
                        }}
                      >
                       
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          
                        {console.log(`navigationid-${index}`,"4775line")}
                          <IconButton data-test-id = {`navigationid-${index}`} onClick={(event) => {
                            event?.stopPropagation(); 
                            this.navigateToRating(ride.attributes.status,ride.id);
                          }}>
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                        <Typography
                          variant="caption"
                          sx={{
                            padding: "2px 2px",
                            marginTop: ride.attributes.status === "In Progress" || ride.attributes.status === "Scheduled" ? "auto" : 1,
                            backgroundColor: this.getStatusStyles(ride.attributes.status).backgroundColor,
                            color: this.getStatusStyles(ride.attributes.status).color,
                            borderRadius: "8px",
                            fontSize: "0.75rem",
                          }}
                        >
                          {ride.attributes.status}
                        </Typography>
                      </Box>
                    </RowContainer>
                  </StyledTableCell>
                </TableRow>
              ))}</> : <h1>No data found</h1>}
          </TableBody>
        </StyledTable>
      </Box>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (

      <StyledContainer>
        <Box style={{}}>
          {this.buttonTabs()}
          {this.screentab()}
        </Box>
        <StyledDialog open={this.state.openEditDialog} onClose={this.onClose}>
          <StyledDialogContent>
            <StyledButton2 onClick={() => this.onEdit()} startIcon={<img src={edit.default} alt="Edit" style={{ width: "20px", height: "20px", marginRight: "8px" }}  data-test-id="edit-button"/>}>
              Edit
            </StyledButton2>
            <StyledButton2 onClick={() => this.onCancel()} startIcon={<img src={deleteImg.default} alt="delete" style={{ width: "18px", height: "22px", marginRight: "8px" }} data-testid="cancel-button"/>}>
              Cancel
            </StyledButton2>
          </StyledDialogContent>
        </StyledDialog>
      </StyledContainer>

    );
    // Customizable Area End
  }


}


// Customizable Area Start
const Buttonbox = styled(Box)({
  width: "100%",
  height: "72px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  "@media (max-width: 800px)": {
    width: "80%",
  },
  "@media (max-width: 700px)": {
    width: "100%",
  },
  "@media (max-width: 575px)": {
    width: "100%",
    flexDirection: "column",
    height: "150px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
});

const Tabbox = styled(Box)({
  width: "100%",
  display: "flex",
  height: "100px",
  "@media (max-width: 575px)": {
    height: "auto",
    width: "100%"
  },
});


const Singlebuttonbox = styled(Box)({
  width: "45%",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
  "@media (max-width: 430px)": {
    width: "100%",
  },
  "@media (max-width: 380px)": {
    width: "100%",
  },
});




const webstyle = {
  typography: { color: '#1D6FB8', textTransform: 'none', fontSize: '16px', fontWeight: 700 },
  IconButton: { padding: '0 !important', marginRight: '8px', display: 'flex', alignItems: 'center', paddingBottom: '3px' },
  BoxStyle: { display: 'flex', alignItems: 'center', marginBottom: '10px' },
  typographyStyle: {
    whiteSpace: "normal", wordBreak: "break-word", fontSize: '16px', fontWeight: 700, color: '#0F172A'
  },
  secondaryText: { whiteSpace: "normal", wordBreak: "break-word", fontSize: '16px', fontWeight: 400, color: '#0F172A' },
  buttonBase: {
    position: 'relative', // Allows absolute positioning of the icon
    width: '100%',
    padding: '10px 40px', // Add padding to accommodate the icon
    textAlign: 'center', // Center text within the button
    display: 'flex', // Use flex for alignment
    justifyContent: 'center', // Center content horizontally
    alignItems: 'center', // Center content vertically
  },
  activeButton: {
    backgroundColor: '#4caf50', // Example active color
    color: '#fff',
  },
  notactiveButton: {
    // backgroundColor: '#e0e0e0', // Example inactive color
    color: '#000',
    width: "100%",
    textTransform: "capitalize" as "capitalize",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "nowrap"
  },
  buttonText: {

  },
  filterIcon: {
    position: 'absolute',
    right: '10px', // Position icon at the right
    top: '50%',
    transform: 'translateY(-50%)', // Center icon vertically
  },
  activeButoon: {
    width: "100%",
    background: "#87C122",
    borderRadius: "50px",
    color: "#0F172A",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  // notactiveButton: {
  //   color: "#475569",
  //   height: "40px",
  //   fontFamily: "Inter",
  //   fontWeight: 400,
  //   fontSize: "16px",
  //   lineHeight: "24px",
  //   textTransform: "capitalize" as "capitalize",
  //   whiteSpace: "nowrap"
  // },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End
