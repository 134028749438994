Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url AcName
  exports.AcName = "Account holder name";
  exports.RNumber = "Routing number";
  exports.ANumber = "Account number";
  exports.DebitText = "Direct Debit authorization";
  exports.longText = "By adding your bank account to your E-Ride account and clicking below, you authorize E-Ride to debit your bank as described in these terms.";
  exports.bankText = "We'll send your USD payouts to this bank account.";
  exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchAPiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.getARide = 'Get a ride';
exports.myTrips = 'My trips';
exports.contact = 'Contact';
exports.personalInfo = 'Personal Information';
exports.changePassword = 'Change Password';
exports.homeAddress = 'Home Address';
exports.notifications = 'Notifications';
exports.privacyAndPolicy = 'Privacy and Security';
exports.contactUs = 'Contact Us';
exports.logOut = 'Log Out';
exports.editProfile = 'Edit Profile';
exports.tripHistory = 'Trip History';
exports.favLocation = 'Favorite Locations';
exports.notifications = 'Notifications';
exports.helpCenter = 'Help Center';
exports.settings = 'Settings';
exports.color1 = '87C122';
exports.color2 = '#000000';
exports.testsection1 = 'Test Section 1 (Edit Profile)';
exports.testsection2 = 'Test Section 2 (Trip History)';
exports.testsection3 = 'Test Section 3 (Favorite Locations)';
exports.testsection4 = 'Test Section 4 (Notifications)';
exports.testsection5 = 'Test Section 5 (Help Center)';
exports.hasUpperCase = 'At least one capital letter';
exports.hasLowerCase = 'At least one lowercase letter';
exports.hasNumber = 'At least one number';
exports.minimumChar = 'Minimum character length is 8 characters';
exports.comfirmpass = "Confirm Password";
exports.unmatchpass = " Password does not match";
exports.save = 'Save';
exports.cancel = "Cancel";
exports.newpass = 'New Password';
exports.prevpass = 'Previous Password';
exports.sureity = " Are you sure that you want to Log Out?";
exports.address = "Address"
exports.contactname = 'Contact name'
exports.Phonenumber = 'Phone number'
exports.back = 'Back'
exports.personalinfo ="Personal Information"
exports.homeAddress = "Home address"
exports.Cardetails = 'Card Details'
exports.creditnumber = 'Credit card number'
exports.expiry = "Expiry date"
exports.cardname = 'Card holder name'
exports.addmethod = "Add Payment Method"
exports.visaend = "Visa ending in 12345"
exports.expiredate= 'Exp date:'
exports.prevpasserror = 'Old Password does not match'
exports.postmethod = "POST"
exports.getmethod = "GET"
exports.httpGetMethod = "GET";
exports.hasSpecialChar = 'At least one special character';
  // Customizable Area End
  