
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
type Text = {
    question: string;
    answer: boolean | null
};
interface S {
    // Customizable Area Start
    riderreviewform: boolean;
    rating: number
    form: Text[]
    isToastOpen: boolean
    ridersuccesMessage: string
    rdiererrorMessage: string
    rdiererror: boolean
    comment: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ReviewsRiderController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            riderreviewform: false,
            rating: 0,
            isToastOpen: false,
            ridersuccesMessage: "",
            rdiererrorMessage: "",
            rdiererror: false,
            comment: "",
            form: [{ question: "How was your driver?", answer: null },
            { question: "How was the car?", answer: null },
            { question: "How was the route?", answer: null },
            { question: "How was the payment?", answer: null }]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            runEngine.debugLog("API Message Recived", message);


            if (
                apiRequestCallId === this.addreviewDriverApicallid
            ) {
                this.setState({ rdiererror: false, ridersuccesMessage: "Rating Added successfully", isToastOpen: true })
                setTimeout(() => {
                    this.cancelReview()
                }, 1000);
            }
        }


        // Customizable Area End
    }

    // Customizable Area Start
    addreviewDriverApicallid: any
    riderSubmit = () => {
        if (!this.state.rating) {
            this.setState({ isToastOpen: true, rdiererror: true, rdiererrorMessage: "Rating is required" })
        } else if (this.state.riderreviewform) {
            if (!this.state.comment) {
                this.setState({ isToastOpen: true, rdiererror: true, rdiererrorMessage: "Description is required" })
            } else {
                this.addReviewRider()
            }
        } else {
            this.setState({ riderreviewform: true })
        }
    }

    handleClose = () => {
        this.setState({ isToastOpen: false })
    }

    handleAnswerChange = (index: number, answer: boolean) => {
        this.setState((prevState) => {
            const updatedFeedbacks = [...prevState.form];
            updatedFeedbacks[index].answer = answer;
            return { form: updatedFeedbacks };
        });
    };

    addReviewRider = () => {
        const token = localStorage.getItem("token")
        let data = {
            data: {
                type: "review",
                attributes: {
                    rating: this.state.rating,
                    description: this.state.comment,
                    account_id: 3,
                    anonymous: true,
                    review_feedbacks_attributes: this.state.form
                },
            },
        };
       
        const header = {
            "Content-Type": configJSON.ReviewsApiContentType,
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addreviewDriverApicallid = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getReviewsAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    cancelReview = () => {
        localStorage.setItem("redirectfrom", "Trip History")
        const navigatetodashboard: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigatetodashboard.addData(getName(MessageEnum.NavigationTargetMessage), 'SideMenuBasic');
        navigatetodashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigatetodashboard)
    }
    // Customizable Area End
}
