import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { Message } from "../../../framework/src/Message";
import { EmojiClickData } from 'emoji-picker-react';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number; url: string }[] | null;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IChatData | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  messages:any;
  showEmojiPicker:boolean;
  attachedFile:any;
  isRecording:boolean;
  isPlaying: boolean;
  progress: number;
  duration: number;
  currentTime:  number;
  currentAudio: any;
  currentAudioUrl:any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatRiderDriverController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private mediaRecorder: MediaRecorder | null = null;
  audioChunks:any = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      messages:[{ user: "User2",msgType: "text", message: "Hi,I have booked a ride for tomorrow", }],
  showEmojiPicker:false,
  attachedFile:null,
  isRecording:false,
  isPlaying: false,
  progress: 0,
  duration: 0,
  currentTime: 0,
  currentAudio: null,
  currentAudioUrl:null,      
  // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.mediaRecorder = null;
    this.audioChunks = [];
    // Customizable Area End
  }

  // Customizable Area Start

  handleFileChange = (event:any) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ attachedFile: file });
      // Optional: Add the file to the messages array
      this.addFileToMessages(file);
    }
  };

  addFileToMessages = (file:any) => {
    const newMessage = {
      user: "User1",
      msgType:"file",
      message: `File: ${file.name}`,
      file: file,
    };
    this.setState((prevState) => ({
      messages: [...prevState.messages, newMessage],
      attachedFile: null, // Clear after adding to messages
    }));
  };
  handleChange = (e:any) => {
    this.setState({ message: e.target.value });
  };
  sendMessage = () => {
    const { message, messages } = this.state;

    if (message.trim() !== '') {
      const chatMessage = {
        user: 'User1', 
        msgType:'text',
        message,
      };

      this.setState({
        messages: [...messages, chatMessage],
        message: '', 
      });
    }
  };
  goBack=()=>{
    this.props.navigation.goBack();
  }
  onEmojiClick = (emojiData: EmojiClickData) => {
    this.setState(prevState => ({
      message: prevState.message + emojiData.emoji, // Access emoji directly from emojiData
    }));
  };
  toggleEmojiPicker = () => {
    this.setState(prevState => ({
      showEmojiPicker: !prevState.showEmojiPicker,
    }));
  };
  
  renderMessageWithStyledEmoji = (message:any) => {
    // Regex pattern to match emojis (basic emoji detection)
    const emojiPattern = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/gu;
  
    // Split message by emoji to apply different styles
    const parts = message.split(emojiPattern);
  
    return parts.map((part:any, index:any) => {
      if (emojiPattern.test(part)) {
        // Apply larger style for emoji
        return (
          <span key={index} style={{ fontSize: '1.1em' }}>
            {part}
          </span>
        );
      } else {
        // Apply normal style for text
        return (
          <span key={index} style={{ fontSize: '1em' }}>
            {part}
          </span>
        );
      }
    });
  };
 
  toggleRecording = async () => {
    const { isRecording } = this.state;

    if (isRecording) {
      this.setState({ isRecording: false });
      if (this.mediaRecorder) this.mediaRecorder.stop();
    } else {
      this.setState({ isRecording: true });
      this.audioChunks = [];
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (event:any) => this.audioChunks.push(event.data);
        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
          const audioUrl = URL.createObjectURL(audioBlob);
          this.setState((prevState) => ({
            messages: [
              ...prevState.messages,
              { user: "User1", msgType: "audio", url: audioUrl },
            ],
          }));
        };
        this.mediaRecorder.start();
      } catch (error) {
        this.setState({ isRecording: false });
      }
    }
  };

  togglePlayPause = (url:any) => {
    const { isPlaying, currentAudioUrl } = this.state;
    if (isPlaying && currentAudioUrl === url) {
      this.audioChunks.current.pause();
      this.setState({ isPlaying: false });
    } else {
      if (this.audioChunks.current) {
        this.audioChunks.current.src = url;
        this.audioChunks.current.play();
        this.setState({
          isPlaying: true,
          currentAudioUrl: url,
          progress: 0,
          currentTime: 0,
        });
      }
    }
  };

  handleTimeUpdate = () => {
    const audio = this.audioChunks.current;
    if (audio) {
      this.setState({
        currentTime: audio.currentTime,
        duration: audio.duration,
        progress: (audio.currentTime / audio.duration) * 100,
      });
    }
  };

  formatTime = (time:any) => {
    if (isNaN(time) || time === Infinity) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  onEnd = () => {
    this.setState({ isPlaying: false })
  }
  getMessageAlignment = (user:string) => {
    return user === "User1" ? "flex-end" : "flex-start";
  };
  // Customizable Area End
}
