import React from "react";

// Customizable Area Start

import DriverFailureScreenController, {
  Props
} from "./DriverFailureScreenController.web";
import { driverIssues, rightLogo,retry} from "./assets";
const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class DriverFailure extends DriverFailureScreenController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div className="driver-app-container-failure">
      <div className="driver-top-section-failure">
        <img
          src={driverIssues.default}
          alt="Main Image"
          className="driver-main-image-failure"
        />
        <img
          src={rightLogo.default}
          alt="Logo"
          className="driver-logo-image-failure"
        />
      </div>

      <div className="driver-bottom-section-failure" style={{backgroundColor:'#FEE2E2'}}>
        <h1 className="driver-main-title1-failure">{configJSON.BigTitle1Failure}</h1>
        <h1 className="driver-main-title2-failure">{configJSON.BigTitle2Failure}</h1>
        <h2 className="driver-sub-title1-failure">{configJSON.SmallTitle1Failure}</h2>
        <h2 className="driver-sub-title2-failure">{configJSON.SmallTitle2Failure}</h2>
        <button className="driver-action-button-failure" data-testid="navigateToLandingPage" onClick={this.navigateToLandingPage}><img src={retry.default} alt="retry"className="retry"/>{configJSON.retry} </button>
      </div>
    </div>
    );
    // Customizable Area End
  }


}


  // Customizable Area Start
  const webStyle = {
 
   
  };
  // Customizable Area End
