import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import * as Yup from 'yup';
import creditCardType from "credit-card-type";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  onSave: () => void; 
   // Customizable Area End
}

export interface S {
  // Customizable Area Start
  selectedSection: string;
  isSidebarOpen: boolean;
  openmodal: boolean;
  passwordCriteria: {
    hasUpperCase: boolean,
    hasLowerCase: boolean,
    hasNumber: boolean,
    isValidLength: boolean
    hasSpecialChar: boolean
  };
  password: string
  passwordMatcherror: boolean
  confirmPassword: string
  usertype: string
  pagetoshow: string
  cardnumber: string
  expirrydate: string
  prevpass: string
  oldpassunmatch: boolean
  cardHolderName: string
  isToastOpenCard: boolean
  cardList: any
  userdetails: any
  isValid: any
  cardType: any
  cardHolderNameError: boolean
  expirrydateError: boolean
  paypalidError: boolean
  paypalid: string
  succesMessage: string
  prevpassvisible: boolean
  newpassvisibile: boolean
  confirmpassvisibile: boolean
  isInputFocused:boolean
  isCardinputfocused:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PaymentMethodController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addCardApiCallId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      selectedSection: 'Settings',
      isSidebarOpen: true,
      openmodal: false,
      passwordCriteria: {
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        isValidLength: false,
        hasSpecialChar: false
      },
      password: "",
      passwordMatcherror: false,
      confirmPassword: "",
      usertype: "",
      pagetoshow: "",
      cardnumber: "",
      expirrydate: "",
      prevpass: "",
      oldpassunmatch: false,
      cardHolderName: "",
      isToastOpenCard: false,
      cardList: [],
      userdetails: {},
      isValid: null,
      cardType: '',
      cardHolderNameError: false,
      expirrydateError: false,
      paypalidError: false,
      paypalid: "",
      succesMessage: "",
      prevpassvisible: false,
      newpassvisibile: false,
      confirmpassvisibile: false,
      isInputFocused:false,
      isCardinputfocused:false
    };    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.addCardApiCallId) {
        this.setState({isToastOpenCard:true})
      }
     
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = async (e: any) => {
    const { name, value } = e.target;
    let formattedValue = value.replace(/\D/g, "");

    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: name == "expirrydate" ? formattedValue : value,
      [`${name}Error`]: false,
    }));

    try {
      await this.validationSchema.validateAt(name, { ...this.state, [name]: name == "expirrydate" ? formattedValue : value });
      this.setState((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        this.setState((prevState) => ({
          ...prevState,
          [`${name}Error`]: error.message,
        }));
      }
    }

  };
  validateLuhn = (number: any) => {
    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number[i], 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };
  handleCardNumberChange = (e: any) => {
    const input = e.target.value.replace(/\D/g, '');
    this.setState({ cardnumber: input })
    const detectedTypes = creditCardType(input);
    if (detectedTypes.length > 0) {
      const type = detectedTypes[0];
      this.setState({ cardType: type.niceType })
      const isLengthValid = type.lengths.includes(input.length);
      const isValidLuhn = isLengthValid && this.validateLuhn(input);
      this.setState({ isValid: isValidLuhn })

    } else {
      this.setState({ cardType: "unknown" })
      this.setState({ isValid: false })
    }
  };
  handleSubmit = async () => {
    try {
      await this.validationSchema.validate(this.state, { abortEarly: false });
      const clearedErrors = Object.keys(this.state)
        .filter((key) => key.endsWith("Error"))
        .reduce((acc, key) => ({ ...acc, [key]: "" }), {});
      this.setState((prevState) => ({
        ...prevState,
        ...clearedErrors,
      }));
      if (this.state.isValid) {
        this.addCreditcard()
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const seenFields = new Set<string>();
        const newErrors = error.inner.reduce((acc, err) => {
          if (err.path && !seenFields.has(err.path)) {
            seenFields.add(err.path);
            acc[`${err.path}Error`] = err.message;
          }
          return acc;
        }, {} as { [key: string]: string });
        this.setState((prevState) => ({
          ...prevState,
          ...newErrors,
          isValid: false,
        }));
      }
    }
  }
  addCreditcard = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const formData = new FormData();
      formData.append('card_number', this.state.cardnumber);
      formData.append('expiry_date', this.state.expirrydate);
      formData.append("cardholder_name", this.state.cardHolderName)
      formData.append('credit_card_type', this.state.cardType)
      formData.append("paypal_id", this.state.paypalid)
      const header = {
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCardApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_settings/credit_cards`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postmethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  validationSchema = Yup.object().shape({

    paypalid: Yup.string()
      .required('Paypal Id / Apple pay is required'),
    expirrydate: Yup.string()
      .required("Expiry date is required")
      .matches(/^\d{2}\/\d{2}$/, "Expiry date must be in MM/YY format")
      .test("is-valid-month", "Month must be between 01 and 12", (value:any) => this.validateMonth(value))
      .test("is-future-date", "Expiry date must be in the future", (value:any) => this.validate(value)),
    cardHolderName: Yup.string()
      .required('Card holder name is required')
      .min(4, 'Card holder name must be at least 4 digits')
      .max(15, 'Card holder name cannot be more than 30 digits')
      .matches(/^[A-Za-z\s]+$/, 'Card holder name must contain only letters'),

  });
  validateMonth(value: any) {
    if (value) {
      const month = parseInt(value.split("/")[0], 10);
      return month >= 1 && month <= 12;
    }
    return true;
  }
  validate(value: any) {
    if (value) {
      const [monthStr, yearStr] = value.split("/");
      const month = parseInt(monthStr, 10);
      const inputYear = 2000 + parseInt(yearStr, 10);

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      return (
        inputYear > currentYear ||
        (inputYear === currentYear && month >= currentMonth)
      );
    }
    return true;
    // }
  }
  handleToastCloseCard = () => {
    this.setState({ isToastOpenCard: false })
    this.props.onSave()
  }
  goBack=()=>{
    this.props.onSave()
  }
  // Customizable Area End
}