//Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    TextField,
    Button,
    FormLabel,
    FormControl,
} from '@mui/material';
import FavLocationController, { configJSON } from "./FavLocationController.web";
import { favAddress } from "./assets";
//Customizable Area End

export default class FavLocationWeb extends FavLocationController {
    render() {
        // Customizable Area Start
        return (
            <>
                {this.state.showAddressForm ? (
                    <FavSaveContainer>
                        <TypographyAddLabel>{configJSON.fav}</TypographyAddLabel>

                        <FormControl fullWidth margin={configJSON.normal}>
                            <MyFormLabel>{configJSON.address}</MyFormLabel>
                            <Input
                                fullWidth
                                variant={configJSON.outlined}
                                placeholder={configJSON.address}
                                name={configJSON.addressSmall}
                                value={this.state.address}
                                onChange={this.handleChangeInput}
                            />
                        </FormControl>

                        <FormControl fullWidth margin={configJSON.normal}>
                            <MyFormLabel>{configJSON.coNumber}</MyFormLabel>
                            <Input
                                fullWidth
                                variant={configJSON.outlined}
                                placeholder={configJSON.Optional}
                                name={configJSON.contactName}
                                value={this.state.contactName}
                                onChange={this.handleChangeInput}
                            />
                        </FormControl>

                        <FormControl fullWidth margin={configJSON.normal}>
                            <MyFormLabel>{configJSON.poNumber}</MyFormLabel>
                            <Input
                                variant={configJSON.outlined}
                                fullWidth
                                placeholder={configJSON.Optional}
                                name={configJSON.phoneNumber}
                                value={this.state.phoneNumber}
                                onChange={this.handleChangeInput}
                            />
                        </FormControl>

                        <BoxSave>
                            <AddressButton variant={configJSON.contained}>
                                {configJSON.save}
                            </AddressButton>
                        </BoxSave>
                    </FavSaveContainer>
                ) : (
                    <FavContainer>
                        <FavAddress src={favAddress.default} alt={configJSON.favAddress}/>
                        <TypographyYouHaveadd>
                            {configJSON.youHaveNoAdd}
                        </TypographyYouHaveadd>
                        <TypographyAddFav>{configJSON.addFavAddress}</TypographyAddFav>
                        <BoxSave>
                            <AddressButton
                                onClick={this.handleAddAddress}
                                variant={configJSON.contained}
                            >
                                {configJSON.addAddress}
                            </AddressButton>
                        </BoxSave>
                    </FavContainer>
                )}
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const FavSaveContainer = styled(Box)({
    width: "95%",
    '@media (max-width: 960px)': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
});

const TypographyAddLabel = styled(Typography)({
    marginTop: '9px',
    width: '100%',
    fontSize: '24px',
    fontWeight: '700',
    color: '#334155',
});

const BoxSave = styled(Box)({
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textTransform: 'none',
    '@media (max-width: 960px)': {
        width: '80%'
    },
});

const AddressButton = styled(Button)({
    width: '440px',
    padding: '10px 20px',
    borderRadius: '12px',
    backgroundColor: '#1D6FB8',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#003f91',
    },
});

const FavContainer = styled(Box)({
    width: "95%",
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    '@media (max-width: 960px)': {
        width: '100%',
    },
});

const FavAddress = styled('img')({
    height: '265px',
    '@media (max-width: 960px)': {
        height: '215px',
    },
});

const TypographyYouHaveadd = styled(Typography)({
    width: '100%',
    fontWeight: '700',
    marginTop: '18px',
});

const TypographyAddFav = styled(Typography)({
    width: '31%',
    color: '#94A3B8',
    marginTop: '8px',
    '@media (max-width: 960px)': {
        width: '90%',
    },
});

const MyFormLabel = styled(FormLabel)({
    color: '#334155',
    fontWeight: 600,
    letterSpacing: '0.5px',
    fontSize: "14px",
    marginBottom: "5px"
});

const Input = styled(TextField)({
    backgroundColor: '#ffffff',
    marginBottom: '16px',
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        backgroundColor: "#ffffff",
    },
});
// Customizable Area End