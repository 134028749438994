import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
export interface ApiCallFunction {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
   // Customizable Area End
}

export interface S {
  // Customizable Area Start
  showPassword: any;

  open: any;
  field: any;
  initialValues1: any;
  token:any;
  optionsList1:any;
  ModalOpen:boolean;
  step:number;
  initialValues2: any;
  optionsList2:any;
  initialValues3: any;
  driverOpenErrorModal:boolean;
  driverOpenSucessModal:boolean;
  fileInsuranceNoDoc:string;
  mobNoError:boolean;
  Files:any;
  insuranceFile: any;
  dmvDocument: any;
  licensePicture: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SignUpDriverNextController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendDataApiCallId: string = "";
  mobileNumberApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      showPassword: false,
      open: false,
      field: "",
      initialValues1: {
      mobNo:"",
      DLicense:'',
      InsuranceNo:'',
      InsuranceNoDoc:[],
      DMVDoc:[],
      DLicensePic:[],
      Addreq:'',
      InsuranceFileName:null,
      DMVFileName:null,
      DLicenseFileName:null,
      },
     
      optionsList1:['None', 'Wheelchair', 'Gurney'],
      ModalOpen:false,
      token:'',
      step:1,
      initialValues2: {
        vehType:'',
        vehMake:'',
        vehModel:'',
        vehYear:'',
        vehSeats:'',
        vehLicPlate:'',
      },
     
      optionsList2:['Sedan','Station', 'wagon','Hatchback','SUV','Minivan','other'],
      initialValues3: {
        AcchName:'',
        RNum:'',
        AccNum:'',
        ConAccNum:'',
      },
     
      driverOpenErrorModal:false,
      driverOpenSucessModal:false,
      fileInsuranceNoDoc:'',
      mobNoError:false,
      Files:null,
      insuranceFile: null,
      dmvDocument: null,
      licensePicture: null,
    };    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const SignUpDriverNextData = message.getData(
        getName(MessageEnum.SignUpDriverNextData) 
      );

      this.setState({
        driverOpenSucessModal: SignUpDriverNextData.driverOpenSucessModal,
        initialValues3: {
          AcchName: SignUpDriverNextData.initialValues3.AcchName,
          RNum: SignUpDriverNextData.initialValues3.RNum,
          AccNum: SignUpDriverNextData.initialValues3.AccNum,
          ConAccNum: SignUpDriverNextData.initialValues3.ConAccNum,
        },
        step: SignUpDriverNextData.step
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
     

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.sendDataApiCallId) {

          if (responseJson.meta.message==='Your Sign Process is Successfully Done') {

            this.OpenSucessModal()
                    }
        
      }}
      if (apiRequestCallId === this.mobileNumberApiCallId) {
        if (responseJson.errors?.[0]==='Mobile number has already been taken') {
        this.setState({mobNoError:true})
        }
        if (responseJson.hasOwnProperty('mobile_number')&& responseJson.mobile_number !== null) {
          this.setState({mobNoError:false})
          this.nextStep();
        }
      }
    }

    
   
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount(); 
  }
  handleErrorClosee = () => {
    this.setState({ driverOpenErrorModal: false });
  };
  handleSucessClosee = () => {
    this.navigateToSignIn()
    this.setState({ driverOpenSucessModal: false });
  };
  navigateToSignIn=()=>{
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  handleDialogClose=()=>{
this.setState({ModalOpen:false})
  }
handleDialogOpen=()=>{
    this.setState({ModalOpen:true})
      }
 Submit1=(values:any)=>{
  const { step } = this.state;
  // Perform validation based on the current step
  if (step === 1) {
    this.setState({initialValues1:values})
    this.checkMobileNumber();
  } else if (step === 2) {
    this.setState({initialValues2:values})
    this.nextStep();
  } else if (step === 3) {
    this.setState({initialValues3:values})    
    this.sendDatatoBackend()
  }      }
  nextStep = () => {
    this.setState((prevState) => ({ step: prevState.step + 1 }));
  };
  checkMobileNumber =async () => {
    const id = this.getParsedId();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          "mobile_number": this.state.initialValues1.mobNo
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.mobileNumberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.mobileNumberApiEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  };
  handleBack = () => {
    const { step } = this.state;
  
    if (step === 2) {
      this.setState({ step: 1 });
    } else if (step === 3) {
      this.setState({ step: 2 });
    }
  }
  getParsedId=()=> {
    let id:any = localStorage.getItem('userId');
    
    if (id && id.startsWith('"') && id.endsWith('"')) {
      id = parseInt(id.replace(/"/g, ""), 10);
    }
    
    return id;
  }
 

  sendDatatoBackend = async () => {
   
    const id = this.getParsedId();
    const header = {

     };
    
    const formdata = new FormData();
    formdata.append("data[attributes][driving_licence_number]",this.state.initialValues1.DLicense);
formdata.append("data[attributes][auto_insurance_policy_number]", this.state.initialValues1.InsuranceNo);
formdata.append("data[attributes][vehicle_make]", this.state.initialValues2.vehMake);
formdata.append("data[attributes][vehicle_type]", this.state.initialValues2.vehType);
formdata.append("data[attributes][vehicle_model]", this.state.initialValues2.vehModel);
formdata.append("data[attributes][vehicle_year]", this.state.initialValues2.vehYear);
formdata.append("data[attributes][number_of_seats]", this.state.initialValues2.vehSeats);
formdata.append("data[attributes][vehicle_licence_plate]", this.state.initialValues2.vehLicPlate);
formdata.append("data[attributes][account_holder_name]", this.state.initialValues3.AcchName);
formdata.append("data[attributes][routing_number]", this.state.initialValues3.RNum);
formdata.append("data[attributes][account_number]", this.state.initialValues3.AccNum);
formdata.append("data[attributes][mobile_number]",this.state.initialValues1.mobNo);
formdata.append("data[attributes][confirm_account_number]",this.state.initialValues3.ConAccNum);
formdata.append("data[attributes][additional_requirements]",this.state.initialValues1.Addreq);
this.state.initialValues1.InsuranceNoDoc?.forEach((file: any, index: any) => {
  formdata.append(`data[attributes][auto_insurance_policy_document_uploads][]`, file);
});

this.state.initialValues1.DMVDoc?.forEach((file: any, index: any) => {
  formdata.append(`data[attributes][dmv_document_uploads][]`, file);
});

this.state.initialValues1.DLicensePic?.forEach((file: any, index: any) => {
  formdata.append(`data[attributes][driving_licence_picture_uploads][]`, file);
});

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendDataApiEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  OpenSucessModal=()=>{

    const msg: Message = new Message(
      getName(MessageEnum.SignUpDriverNextDataPassMessage)
    );
    msg.addData(getName(MessageEnum.SignUpDriverNextData), {
      step: 3,
      initialValues3: {
        AccNum: "1234567890",
        AcchName: "rahul",
        ConAccNum: "1234567890",
        RNum: "12133"
      },
    });
    this.send(msg);

    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "TwoFactorAuthentication");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
 navigateTONext2=()=>{
  const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpDriverStep3");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  // Customizable Area End
}